import { useEffect, useState } from 'react'
import AxiosInstance from '../../axios/axiosInstance'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import foryou from '../../assets/img/foryou.png'
import attribute from '../../assets/img/attribute.png'
import attributeby from '../../assets/img/attributeby.png'
import commentImg from '../../assets/img/comment.png'
import status from '../../assets/img/status.png'
import taskImg from '../../assets/img/task.png'
import candidatImg from '../../assets/img/candidat.png'
import clientImg from '../../assets/img/client.png'
import avatar from '../../assets/img/avatar.jpg'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

import {
  DatePicker,
  Drawer,
  Input,
  Modal,
  Select,
  TimePicker,
  message,
} from 'antd'
import axiosInstance from '../../axios/axiosInstance'
import dayjs from 'dayjs'
import locale from 'antd/es/date-picker/locale/fr_FR'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import CandidatAttestation from '../Candidat/CandidatAttestation'
import { AddOutlined } from '@mui/icons-material'

const Task = () => {
  const [tasks, setTasks] = useState([])
  const [originalTasks, setOriginalTasks] = useState([])
  const [isOpenTaskModal, setIsOpenTaskModal] = useState(false)
  const [filter, setFilter] = useState('Toutes')
  const [isOpenCreateTask, setIsOpenCreateTask] = useState(false)
  const [isOpenUpdateTask, setIsOpenUpdateTask] = useState(false)
  const [selectedTask, setSelectedTask] = useState(null)
  const [doneTask, setDoneTask] = useState(false)
  const [doneReason, setDoneReason] = useState('')
  const [currentDate, setCurrenDate] = useState(new Date())
  const [isFilter, setIsFilter] = useState(false)
  const [newTaskDate, setNewTaskDate] = useState(null)
  const [reportTask, setReportTask] = useState(false)
  const [userId, setUserId] = useState('')
  const [cancelTask, setCancelTask] = useState(false)
  const [taker, setTaker] = useState('')
  const [client, setClient] = useState(null)
  const [clients, setClients] = useState([])
  const [candidat, setCandidat] = useState('')
  const [takers, setTakers] = useState([])
  const [cancelReason, setCancelReason] = useState('')
  const [isSelectDate, setIsSelectDate] = useState(false)

  const [taskType, setTaskType] = useState('Taches')
  const [date, setDate] = useState(null)
  const [time, setTime] = useState(null)
  const [comment, setComment] = useState('')
  const [place, setPlace] = useState('')
  const [timer, setTimer] = useState(null)
  const [selectedCandidate, setSelectedCandidate] = useState(null)
  const [selectedCandidates, setSelectedCandidates] = useState([])
  const [selectedClients, setSelectedClients] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)

  useEffect(() => {
    const date = new Date().toISOString()
    loadTasks(date)
    //loadClients()
    getAgencyUsers()
    const userId = localStorage.getItem('userId')
    setUserId(userId)
  }, [])

  const openUpdateTask = () => {
    setComment(selectedTask.comment)
    setTaskType(selectedTask.type)
    setDate(selectedTask.date)
    setTime(selectedTask.hour)
    setPlace('')
    setClient(selectedTask.client)
    setSelectedCandidate(selectedTask.candidate)
    setIsOpenUpdateTask(true)
  }

  const updateTask = () => {
    if (!time || !comment.length || !date || !taker.length) {
      message.error('Veuillez remplir tout les champs !')
      return
    }
    const data = {}
    data.type = taskType
    data.date = date
    data.comment = comment
    data.taskManager = taker
    data.hour =
      time.getHours().toString().padStart(2, '0') +
      ':' +
      time.getMinutes().toString().padStart(2, '0')
    if (client) data.client = client
    if (selectedCandidate) data.candidate = selectedCandidate._id
    axiosInstance
      .put(`task/${selectedTask._id}`, data)
      .then((res) => {
        const newTasks = [res.data, ...tasks]
        setTasks(newTasks)
        message.success('Tâche modifiée ! ')
        resetTaskFields()
        setIsOpenCreateTask(false)
      })
      .catch((e) => message.error("Une erreur s'est produite ! "))
  }

  const getAgencyUsers = () => {
    axiosInstance('user/active').then((res) => {
      const usersArray = res.data.map((user) => {
        return {
          value: user._id,
          label: `${user.firstname} ${user.lastname}`,
          key: user._id,
        }
      })
      setTakers(usersArray)
    })
  }

  const doneTaskFunc = () => {
    axiosInstance
      .put(`task/${selectedTask._id}`, { status: 'Effectuée', doneReason })
      .then((res) => {
        const taskIndex = tasks.findIndex((task) => task._id === res.data._id)
        const newTasks = [...tasks]
        newTasks[taskIndex] = res.data
        setTasks(newTasks)
        setSelectedTask(null)
      })
  }

  const cancelTaskFunc = () => {
    axiosInstance
      .put(`task/${selectedTask._id}`, { status: 'annulée', cancelReason })
      .then((res) => {
        const taskIndex = tasks.findIndex((task) => task._id === res.data._id)
        const newTasks = [...tasks]
        newTasks[taskIndex] = res.data
        setTasks(newTasks)
        setSelectedTask(null)
        setNewTaskDate(null)
        setCancelTask(false)
      })
  }

  const reportTaskDate = () => {
    axiosInstance
      .put(`task/${selectedTask._id}`, { date: newTaskDate })
      .then((res) => {
        const taskIndex = originalTasks.findIndex(
          (task) => task._id === res.data._id,
        )
        let newTasks = [...tasks]
        newTasks[taskIndex] = res.data
        newTasks = newTasks.filter(
          (task) => formatJsDate(task.date) === formatJsDate(currentDate),
        )
        setTasks(newTasks)
        setSelectedTask(null)
        setNewTaskDate(null)
        setReportTask(false)
      })
  }

  const formatJsDate = (dated) => {
    const date = new Date(dated)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  const addDate = () => {
    const newDate = new Date(currentDate)
    newDate.setDate(newDate.getDate() + 1)
    setCurrenDate(newDate)
    loadTasks(newDate.toISOString())
  }

  const newTasks = (value) => {
    value.setHours(10)
    loadTasks(value.toISOString())
    setIsSelectDate(false)
    setCurrenDate(value)
  }

  const removeDate = () => {
    const newDate = new Date(currentDate)
    newDate.setDate(newDate.getDate() - 1)
    setCurrenDate(newDate)
    loadTasks(newDate.toISOString())
  }

  const resetTaskFields = () => {
    setComment('')
    setTaskType('Taches')
    setDate(null)
    setTime(null)
    setPlace('')
    setClient(null)
    setSelectedCandidate(null)
    setSelectedCandidates([])
    setSelectedClients([])
  }

  const filterTasks = (status) => {
    setFilter(status)
    if (status === 'Toutes') {
      setTasks(originalTasks)
    } else {
      const newtasks = originalTasks.filter((task) => task.status === status)
      setTasks(newtasks)
    }
  }

  const loadClients = () => {
    axiosInstance
      .get('client')
      .then((res) => {
        const clients = res.data.map((client) => {
          return {
            value: client._id,
            label: client.companyName,
            key: client._id,
          }
        })
        setClients(clients)
      })
      .catch((e) => console.log(e))
  }

  const searchTask = (query) => {
    if (query.length === 0) {
      setTasks(originalTasks)
    }

    console.log(currentDate)

    axiosInstance
      .post('task/find-by-text', {
        query,
        date: currentDate,
      })
      .then((res) => {
        console.log(res)
        setTasks(res.data)
      })
      .catch((e) => console.log(e))
  }

  const searchClient = (word) => {
    if (word.length === 0) {
      setSelectedClients([])
    }
    if (word.length < 3) {
      if (timer) clearTimeout(timer)
    }
    if (word.length >= 3) {
      if (timer) clearTimeout(timer)

      const timer2 = setTimeout(() => {
        axiosInstance
          .post('client/find-by-text', { query: word })
          .then((res) => {
            setSelectedClients(res.data)
          })
          .catch((e) => console.log(e))
      }, 700)
      setTimer(timer2)
    }
  }

  const search = (word) => {
    if (word.length === 0) {
      setSelectedCandidates([])
    }
    if (word.length < 3) {
      if (timer) clearTimeout(timer)
    }
    if (word.length >= 3) {
      if (timer) clearTimeout(timer)

      const timer2 = setTimeout(() => {
        axiosInstance
          .post('tempworker-candidate/find-by-text', { query: word })
          .then((res) => {
            setSelectedCandidates(res.data)
          })
          .catch((e) => console.log(e))
      }, 700)
      setTimer(timer2)
    }
  }

  const createTask = () => {
    if (!time || !comment.length || !date || !taker.length) {
      message.error('Veuillez remplir tout les champs !')
      return
    }
    const data = {}
    data.taskCreator = userId
    data.type = taskType
    data.date = date
    data.comment = comment
    data.taskManager = taker
    data.hour =
      time.getHours().toString().padStart(2, '0') +
      ':' +
      time.getMinutes().toString().padStart(2, '0')
    if (selectedClient) data.client = selectedClient._id
    if (selectedCandidate) data.candidate = selectedCandidate._id
    axiosInstance
      .post('task', data)
      .then((res) => {
        const newTasks = [res.data, ...tasks]
        setTasks(newTasks)
        message.success('Tâche créée ! ')
        resetTaskFields()
        setIsOpenCreateTask(false)
      })
      .catch((e) => message.error("Une erreur s'est produite ! "))
  }

  const loadTasks = (date) => {
    setFilter('Toutes')
    AxiosInstance.get(`/task/find-by-date?date=${date}`)
      .then((res) => {
        setOriginalTasks(res.data)
        setTasks(res.data)
      })
      .catch((e) => console.log(e))
  }

  return (
    <div className="font-Inter">
      {selectedTask && (
        <Modal
          width={1000}
          open={selectedTask}
          footer={null}
          onCancel={() => setSelectedTask(null)}
        >
          <div>
            <div className="mt-10">
              <p className="">Type : {selectedTask.type}</p>
              <p className="">Date : {formatJsDate(selectedTask.date)}</p>
              <p className="">Heure : {selectedTask.hour}</p>
              <p className="">Commentaire : {selectedTask.comment}</p>
              <p className="">
                Candidat : {selectedTask.candidate?.firstname}{' '}
                {selectedTask.candidate?.lastname}
              </p>
              <p className="">Client : {selectedTask.client?.companyName}</p>
              {selectedTask.doneReason && (
                <p className="">Action menée : {selectedTask.doneReason}</p>
              )}
              {selectedTask.cancelReason && (
                <p className="">
                  Raison d'annulation : {selectedTask.cancelReason}
                </p>
              )}
            </div>

            <div className="flex items-center space-x-2 mt-6 justify-center">
              {/*  <button
                onClick={openUpdateTask}
                className="w-1/4 p-2 text-white rounded-md bg-yellow-500"
              >
                Modifier
              </button> */}
              <button
                onClick={() => {
                  setDoneTask(true)
                  setReportTask(false)
                  setCancelTask(false)
                }}
                className="w-1/4 p-2 text-white rounded-md bg-green-500"
              >
                Effectuer
              </button>
              <button
                onClick={() => {
                  setReportTask(true)
                  setDoneTask(false)
                  setCancelTask(false)
                }}
                className="w-1/4 p-2 text-white rounded-md bg-orange-500"
              >
                Reporter
              </button>
              <button
                onClick={() => {
                  setReportTask(false)
                  setDoneTask(false)
                  setCancelTask(true)
                }}
                className="w-1/4 p-2 text-white rounded-md bg-red-500"
              >
                Annuler
              </button>
            </div>
            {doneTask && (
              <div className="flex flex-col items-center justify-center py-6 space-y-2">
                <Input
                  value={doneReason}
                  onChange={(e) => setDoneReason(e.target.value)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="action menée"
                />
                <button
                  onClick={doneTaskFunc}
                  className="p-2 text-white rounded-md bg-green-500"
                >
                  Effectué la tache
                </button>
              </div>
            )}
            {reportTask && (
              <div className="flex flex-col space-y-2  items-center justify-center py-6">
                <DatePicker
                  onChange={(date, dateString) => setNewTaskDate(date.$d)}
                  value={newTaskDate ? dayjs(newTaskDate) : null}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date du report"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <button
                  className="p-2 text-white rounded-md bg-green-500"
                  onClick={reportTaskDate}
                >
                  Reporter la tache
                </button>
              </div>
            )}
            {cancelTask && (
              <div className="flex flex-col items-center justify-center py-6 space-y-2">
                <Input
                  value={cancelReason}
                  onChange={(e) => setCancelReason(e.target.value)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="motif de l'annulation"
                />
                <button
                  onClick={cancelTaskFunc}
                  className="p-2 text-white rounded-md bg-green-500"
                >
                  Annuler la tache
                </button>
              </div>
            )}
          </div>
        </Modal>
      )}

      {isFilter && (
        <Drawer>
          <div>taches</div>
        </Drawer>
      )}
      {isOpenCreateTask && (
        <Modal
          footer={null}
          width={900}
          open={isOpenCreateTask}
          onCancel={() => {
            setIsOpenCreateTask(false)
            resetTaskFields()
          }}
        >
          <div className="flex flex-col  justify-center space-y-2 mt-10">
            <Select
              className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              optionFilterProp="children"
              placeholder="Taches"
              value={taskType}
              onChange={(e) => {
                setTaskType(e)
                setComment('')
                setDate(null)
                setTime(null)
                setPlace('')
              }}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  label: 'Taches',
                  value: 'Taches',
                  key: 'Taches',
                },
                {
                  label: 'Appel',
                  value: 'Appel',
                  key: 'Appel',
                },
                {
                  label: 'Deplacement',
                  value: 'Deplacement',
                  key: 'Deplacement',
                },
                {
                  label: 'Autre',
                  value: 'Autre',
                  key: 'Autre',
                },
              ]}
            />
            {taskType === 'Taches' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  onChange={(date, dateString) => setDate(date.$d)}
                  value={date ? dayjs(date) : null}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date de la tache"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <TimePicker
                  format={'HH:mm'}
                  value={time ? dayjs(time) : null}
                  onChange={(time, timeString) => setTime(time.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Heure de la tache"
                />
                <input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Commentaire"
                />
              </div>
            )}

            {taskType === 'Appel' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  value={date ? dayjs(date) : null}
                  onChange={(date, dateString) => setDate(date.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date de l'appel"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <TimePicker
                  format={'HH:mm'}
                  value={time ? dayjs(time) : null}
                  onChange={(time, timeString) => setTime(time.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Heure de l'appel"
                />
                <input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Commentaire"
                />
              </div>
            )}

            {taskType === 'Deplacement' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  value={date ? dayjs(date) : null}
                  onChange={(date, dateString) => setDate(date.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date du deplacement"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <TimePicker
                  format={'HH:mm'}
                  value={time ? dayjs(time) : null}
                  onChange={(time, timeString) => setTime(time.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Heure du deplacement"
                />
                <input
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                  className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Lieu du deplacement"
                />
                <input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Commentaire"
                />
              </div>
            )}

            {taskType === 'Autre' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  value={date ? dayjs(date) : null}
                  onChange={(date, dateString) => setDate(date.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <TimePicker
                  format={'HH:mm'}
                  value={time ? dayjs(time) : null}
                  onChange={(time, timeString) => setTime(time.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Heure"
                />

                <input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Commentaire"
                />
              </div>
            )}

            {/*   <Select
              className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
              placeholder="Client"
              showSearch="true"
              optionFilterProp="children"
              onChange={(e) => setClient(e)}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={clients}
            />  */}

            <Input
              className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
              placeholder="Client"
              onChange={(e) => searchClient(e.target.value)}
            />
            {selectedClients.length > 0 && (
              <div className="border flex flex-col space-y-2 border-gray-300 rounded-md mt-4 p-2">
                {selectedClients.map((client) => {
                  return (
                    <p
                      onClick={() => setSelectedClient(client)}
                      className={`border-b flex items-center space-x-1  border-b-gray-300 pb-2 ${
                        selectedClient?._id === client._id ? 'bg-green-400' : ''
                      }`}
                    >
                      <span>{client.companyName}</span>
                    </p>
                  )
                })}
              </div>
            )}

            <Input
              className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
              placeholder="Candidat"
              onChange={(e) => search(e.target.value)}
            />
            {selectedCandidates.length > 0 && (
              <div className="border flex flex-col space-y-2 border-gray-300 rounded-md mt-4 p-2">
                {selectedCandidates.map((candidate) => {
                  return (
                    <p
                      onClick={() => setSelectedCandidate(candidate)}
                      className={`border-b flex items-center space-x-1  border-b-gray-300 pb-2 ${
                        selectedCandidate?._id === candidate._id
                          ? 'bg-green-400'
                          : ''
                      }`}
                    >
                      <span>{candidate.firstname} -</span>
                      <span>{candidate.lastname}-</span>
                      <span>{candidate.email} -</span>
                      <span>{candidate.phone}</span>
                    </p>
                  )
                })}
              </div>
            )}

            <Select
              className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
              placeholder="Attribuée à"
              optionFilterProp="children"
              onChange={(e) => setTaker(e)}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={takers}
            />
            <button
              onClick={createTask}
              className="rounded-md p-2 bg-mountain-500 w-2/4 mx-auto text-white mt-10 uppercase font-semibold"
            >
              Créér la tache
            </button>
          </div>
        </Modal>
      )}

      {isOpenUpdateTask && (
        <Modal
          footer={null}
          width={900}
          open={isOpenUpdateTask}
          onCancel={() => {
            setIsOpenUpdateTask(false)
            resetTaskFields()
          }}
        >
          <div className="flex flex-col justify-center space-y-2 mt-10">
            <Select
              className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              optionFilterProp="children"
              placeholder="Taches"
              value={taskType}
              onChange={(e) => {
                setTaskType(e)
                setComment('')
                setDate(null)
                setTime(null)
                setPlace('')
              }}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  label: 'Taches',
                  value: 'Taches',
                  key: 'Taches',
                },
                {
                  label: 'Appel',
                  value: 'Appel',
                  key: 'Appel',
                },
                {
                  label: 'Deplacement',
                  value: 'Deplacement',
                  key: 'Deplacement',
                },
                {
                  label: 'Autre',
                  value: 'Autre',
                  key: 'Autre',
                },
              ]}
            />
            {taskType === 'Taches' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  onChange={(date, dateString) => setDate(date.$d)}
                  value={date ? dayjs(date) : null}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date de la tache"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <TimePicker
                  format={'HH:mm'}
                  value={time ? dayjs(time) : null}
                  onChange={(time, timeString) => setTime(time.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Heure de la tache"
                />
                <input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Commentaire"
                />
              </div>
            )}

            {taskType === 'Appel' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  value={date ? dayjs(date) : null}
                  onChange={(date, dateString) => setDate(date.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date de l'appel"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <TimePicker
                  format={'HH:mm'}
                  value={time ? dayjs(time) : null}
                  onChange={(time, timeString) => setTime(time.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Heure de l'appel"
                />
                <input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Commentaire"
                />
              </div>
            )}

            {taskType === 'Deplacement' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  value={date ? dayjs(date) : null}
                  onChange={(date, dateString) => setDate(date.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date du deplacement"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <TimePicker
                  format={'HH:mm'}
                  value={time ? dayjs(time) : null}
                  onChange={(time, timeString) => setTime(time.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Heure du deplacement"
                />
                <input
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                  className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Lieu du deplacement"
                />
                <input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Commentaire"
                />
              </div>
            )}

            {taskType === 'Autre' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  value={date ? dayjs(date) : null}
                  onChange={(date, dateString) => setDate(date.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <TimePicker
                  format={'HH:mm'}
                  value={time ? dayjs(time) : null}
                  onChange={(time, timeString) => setTime(time.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Heure"
                />

                <input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Commentaire"
                />
              </div>
            )}

            <Select
              className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
              placeholder="Client"
              optionFilterProp="children"
              onChange={(e) => setClient(e)}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={clients}
            />

            <Input
              className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
              placeholder="Candidat"
              onChange={(e) => search(e.target.value)}
            />
            {selectedCandidates.length > 0 && (
              <div className="border flex flex-col space-y-2 border-gray-300 rounded-md mt-4 p-2">
                {selectedCandidates.map((candidate) => {
                  return (
                    <p
                      onClick={() => setSelectedCandidate(candidate)}
                      className={`border-b flex items-center space-x-1  border-b-gray-300 pb-2 ${
                        selectedCandidate?._id === candidate._id
                          ? 'bg-green-400'
                          : ''
                      }`}
                    >
                      <span>{candidate.firstname} -</span>
                      <span>{candidate.lastname}-</span>
                      <span>{candidate.email} -</span>
                      <span>{candidate.phone}</span>
                    </p>
                  )
                  return
                })}
              </div>
            )}

            <Select
              className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
              placeholder="Attribuée à"
              optionFilterProp="children"
              onChange={(e) => setTaker(e)}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={takers}
            />
            <button
              onClick={updateTask}
              className="rounded-md p-2 bg-mountain-500 w-2/4 mx-auto text-white mt-10 uppercase font-semibold"
            >
              Modifier la tache
            </button>
          </div>
        </Modal>
      )}

      {isOpenTaskModal && (
        <Modal
          open={isOpenTaskModal}
          footer={null}
          onCancel={() => setIsOpenTaskModal(false)}
        >
          <div></div>
        </Modal>
      )}

      <div className="mb-10 mt-10 font-Inter">
        <p className="text-xl text-gray-400">
          Accueil / Gestion / Taches du jour
        </p>
        <p className="text-2xl font-semibold uppercase">tâches du jour</p>
      </div>

      <div className="flex hover:cursor-pointer items-center w-fit  border rounded-lg border-gray-300 bg-[#F7F9FC] ">
        <p
          onClick={() => {
            filterTasks('Toutes')
          }}
          className={`${
            filter === 'Toutes' && 'bg-purple-400'
          }  border-r p-2 border-r-gray-300`}
        >
          Toutes
        </p>
        <p
          onClick={() => {
            filterTasks('A faire')
          }}
          className={`${
            filter === 'A faire' && 'bg-purple-400'
          }  border-r p-2 border-r-gray-300`}
        >
          A faire
        </p>
        <p
          onClick={() => {
            filterTasks('Effectuée')
          }}
          className={`${
            filter === 'Effectuée' && 'bg-purple-400'
          }  border-r p-2 border-r-gray-300`}
        >
          Faites
        </p>
        <p
          onClick={() => {
            filterTasks('annulée')
          }}
          className={`${
            filter === 'annulée' && 'bg-purple-400'
          }  border-r p-2 border-r-gray-300`}
        >
          Annulées
        </p>
      </div>

      {/* <div className="flex   justify-between items-center mb-6">
        <div className="mt-10 flex items-start space-x-4">
          <div className=" flex space-x-2 items-center">
            <LeftOutlined onClick={() => removeDate()} />
            <p className="text-xl font-semibold">{formatJsDate(currentDate)}</p>
            <RightOutlined onClick={() => addDate()} />
          </div>
        </div>
        
      </div> */}

      <div className="flex py-6 justify-between items-start mb-6">
        {!isSelectDate && (
          <p
            onClick={() => setIsSelectDate(true)}
            className="text-xl font-semibold"
          >
            {formatJsDate(currentDate)}
          </p>
        )}
        {isSelectDate && <Calendar onChange={newTasks} />}
        <div className="flex items-center space-x-1">
          <div>
            <input
              onChange={(e) => searchTask(e.target.value)}
              type="text"
              placeholder="Recherche"
              className="rounded-md border border-gray-300"
            />
          </div>

          <div
            onClick={() => setIsOpenCreateTask(true)}
            className="border hover:cursor-pointer border-blue-400 rounded-md flex items-center"
          >
            <AddOutlined className="hover:cursor-pointer text-blue-400" />
            <p className="text-blue-400 p-2">Ajouter une tache</p>
          </div>
        </div>
      </div>

      <div className="border border-gray-300 rounded-md">
        <div className="flex items-center space-x-4 px-4 text-md py-3">
          <p className="w-[12%]  font-semibold">Attribuée à</p>
          <p className="w-[12%]  font-semibold">Par</p>
          <p className="w-[12%]  font-semibold">type</p>
          <p className="w-[12%]  font-semibold">Client</p>
          <p className="w-[12%]  font-semibold">Candidat</p>
          <p className="w-[12%]  font-semibold">Commentaire</p>
          <p className="w-[12%]  font-semibold">Action menée</p>
          <p className="w-[12%]  font-semibold">statut</p>
        </div>

        <div className="flex flex-col ">
          {tasks.map((task, idx) => {
            return (
              <div
                className="flex space-x-4  items-center drop-shadow-2xl text-xs   bg-white border  border-gray-200 rounded-md p-4 hover:cursor-pointer"
                key={idx}
                onClick={() => {
                  setSelectedTask(task)
                }}
              >
                {task.taskManager?._id === userId && (
                  <p className="w-[12%]  flex items-start  space-x-2">
                    {' '}
                    <img className="w-10 ml-10" src={foryou} />
                  </p>
                )}
                {task.taskManager?._id !== userId && (
                  <p className="w-[12%]  flex items-start space-x-2 text-sm capitalize">
                    <span>
                      <img src={avatar} className="w-10 rounded-full" />
                      {/* {task.taskManager.image &&<img src={'data:image/png;base64, '+task.taskManager.image}  className='w-10 rounded-full' />} */}
                    </span>
                    <span>
                      {task.taskManager?.firstname} {task.taskManager?.lastname}
                    </span>
                  </p>
                )}
                <p className="w-[12%]  flex items-start space-x-2 text-sm capitalize">
                  <span>
                    {!task.taskCreator.image && (
                      <img src={avatar} className="w-10 rounded-full" />
                    )}
                    {task.taskCreator.image && (
                      <img
                        src={'data:image/png;base64,' + task.taskCreator.image}
                        className="w-10 rounded-full"
                      />
                    )}
                  </span>
                  <span>
                    {task.taskCreator?.firstname} {task.taskCreator?.lastname}
                  </span>
                </p>
                {task.type === 'Appel' && (
                  <p className="w-[12%] text-blue-500  text-sm capitalize">
                    <span>{task.type}</span>
                  </p>
                )}
                {task.type === 'Taches' && (
                  <p className="w-[12%] text-yellow-500   text-sm capitalize">
                    <span>{task.type}</span>
                  </p>
                )}
                {task.type === 'Autre' && (
                  <p className="w-[12%] text-yellow-500   text-sm capitalize">
                    <span>{task.type}</span>
                  </p>
                )}
                {task.type === 'Deplacement' && (
                  <p className="w-[12%] text-yellow-500  text-sm capitalize">
                    <span>{task.type}</span>
                  </p>
                )}
                {task.type !== 'Deplacement' &&
                  task.type !== 'Autre' &&
                  task.type !== 'Appel' &&
                  task.type !== 'Taches' && (
                    <p className="w-[12%] text-yellow-500  text-sm capitalize"></p>
                  )}
                <p className="w-[12%] flex flex-col text-sm capitalize">
                  <span>{task.client?.companyName}</span>
                  <span>{task.client?.contactPhone}</span>
                </p>
                <p className="w-[12%] flex flex-col   text-sm capitalize">
                  <span>
                    {task.candidate?.firstname} {task.candidate?.lastname}
                  </span>
                  <span>{task.candidate?.phone}</span>
                </p>
                <p className="w-[12%]   text-sm ">
                  {' '}
                  {task.comment && (
                    <span>{`${task.comment?.substring(0, 10)}...`}</span>
                  )}
                </p>
                {task.status !== 'annulée' && (
                  <p className="w-[12%]   text-sm capitalize">
                    {task.doneReason && (
                      <span>{`${task.doneReason?.substring(0, 10)}...`}</span>
                    )}
                  </p>
                )}
                {task.status === 'annulée' && (
                  <p className="w-[12%]  text-sm capitalize">
                    {task.cancelReason && (
                      <span>{`${task.cancelReason?.substring(0, 10)}...`}</span>
                    )}
                  </p>
                )}
                {task.status === 'A faire' && (
                  <p className="w-[12%]    text-sm capitalize">
                    {' '}
                    <span className="bg-yellow-200 text-yellow-700 rounded-md text-sm w-fit p-2 ">
                      {task.status}
                    </span>
                  </p>
                )}

                {task.status === 'Effectuée' && (
                  <p className="w-[12%]   text-sm capitalize">
                    {' '}
                    <span className="bg-green-200 text-green-700 rounded-md text-sm w-fit p-2 ">
                      {task.status}
                    </span>
                  </p>
                )}

                {task.status === 'annulée' && (
                  <p className="w-[12%]   text-sm capitalize">
                    {' '}
                    <span className="bg-red-200 text-red-700 rounded-md text-sm w-fit p-2 ">
                      {task.status}
                    </span>
                  </p>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Task

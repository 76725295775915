import { Checkbox, Modal } from 'antd'
import { useEffect, useState } from 'react'

const CreateContractPrime = ({
  primes,
  globalPrimes,
  contract,
  setContract,
}) => {

    console.log(contract);
  const [primeGlobal, setPrimeGlobal] = useState([])
  const [primeGlobalOriginal, setPrimeGlobalOriginal] = useState([])
  const [usedPrimes, setUsedPrimes] = useState([...primes,...contract.clientQualification.TabPrimes])
  const [selectedPrimes, setSelectedPrimes] = useState(primes)
  const [selectedPrime, setSelectedPrime] = useState(null)
  const [addGlobalPrime, setAddGlobalPrime] = useState(false)

  const selectPrimes = (e, prime) => {
    let newSelectedPrimes
    if (e.target.checked) {
      newSelectedPrimes = [...selectedPrimes, prime]
    } else {
      newSelectedPrimes = selectedPrimes.filter(
        (obj) => obj.IdPrime !== prime.IdPrime,
      )
    }
    setSelectedPrimes(arrayToSet(newSelectedPrimes))
    setContract({ ...contract, TabPrimes: arrayToSet(newSelectedPrimes) })
    console.log(arrayToSet(newSelectedPrimes))
  }

  const setTauxPayeImposable = (value) => {
    const newSelectedPrime = { ...selectedPrime }
    newSelectedPrime.TauxPayeImposable = value
    newSelectedPrime.TauxPayeNonImposable = 0
    setSelectedPrime(newSelectedPrime)
  }

  const setTauxPayeNonImposable = (value) => {
    const newSelectedPrime = { ...selectedPrime }
    newSelectedPrime.TauxPayeNonImposable = value
    newSelectedPrime.TauxPayeImposable = 0
    setSelectedPrime(newSelectedPrime)
  }

  const setTauxFacture = (value) => {
    const newSelectedPrime = { ...selectedPrime }
    newSelectedPrime.TauxFacture = value
    setSelectedPrime(newSelectedPrime)
  }

  const arrayToSet = (array) => {
    let uniqueArray = array.reduce((acc, current) => {
      if (!acc.find((obj) => obj.IdPrime === current.IdPrime)) {
        acc.push(current)
      }
      return acc
    }, [])
    return uniqueArray
  }

  const filterGlobalPrimes = (value) => {
    if (value.length === 0) {
      setPrimeGlobal(primeGlobalOriginal)
      return
    }
    const newGlobalPrimes = primeGlobal.filter((pr) =>
      pr.Designation.toLowerCase().includes(value.toLowerCase()),
    )
    setPrimeGlobal(newGlobalPrimes)
  }

  const validatePrime = () => {
    const primeIdx = selectedPrimes.findIndex(
      (pr) => pr.IdPrime === selectedPrime.IdPrime,
    )
    const newSelectedPrimes = [...selectedPrimes]
    if (primeIdx >= 0) {
      newSelectedPrimes[primeIdx] = selectedPrime
    } else newSelectedPrimes.push(selectedPrime)
    setSelectedPrimes(newSelectedPrimes)
    setSelectedPrime(null)
    console.log(newSelectedPrimes)
    setContract({ ...contract, TabPrimes: arrayToSet(newSelectedPrimes) })
  }

  useEffect(() => {
    if (!contract.TabPrimes) {
      setContract({ ...contract, isEditTauxHoraire: false, TabPrimes: [...primes,...contract.clientQualification.TabPrimes] })
    }

    const primeGlobales = globalPrimes.filter(
      (prime2) => !primes.some((prime1) => prime1.IdPrime === prime2.IdPrime),
    )

    setPrimeGlobal(primeGlobales)
    setPrimeGlobalOriginal(primeGlobales)
  }, [])

  return (
    <div className="flex flex-col h-full justify-between">
      {selectedPrime && (
        <Modal
          className="mt-10"
          open={selectedPrime}
          width={800}
          footer={null}
          onCancel={() => setSelectedPrime(null)}
        >
          <div className="flex flex-col w-full items-center justify-start mt-8">
            {selectedPrime.TxtPrime && (
              <p className="text-2xl font-semibold">{selectedPrime.TxtPrime}</p>
            )}
            {selectedPrime.Designation && (
              <p className="text-2xl font-semibold">
                {selectedPrime.Designation}
              </p>
            )}
            <div className="flex w-full mt-6 items-center justify-between">
              <div className="w-1/2 ">
                <p className="text-center">Payé à l'interimaire</p>
                <div className="flex items-center space-x-1 justify-center mt-6">
                  <input
                    disabled={selectedPrime.IdRubriqueImposable===0}
                    onChange={(e) => setTauxPayeImposable(e.target.value)}
                    value={
                      selectedPrime.TauxPayeImposable != '0'
                        ? selectedPrime.TauxPayeImposable
                        : ''
                    }
                    className="border border-gray-300 rounded-md p-2 "
                    placeholder="Taux imposable"
                  />
                  <input
                  disabled={selectedPrime.IdRubriqueNonImposable===0}
                    onChange={(e) => setTauxPayeNonImposable(e.target.value)}
                    value={
                      selectedPrime.TauxPayeNonImposable != '0'
                        ? selectedPrime.TauxPayeNonImposable
                        : ''
                    }
                    className="border border-gray-300 rounded-md p-2 "
                    placeholder="Taux non imposable"
                  />
                </div>
              </div>
              <div className="w-1/2">
                <p className="text-center">Facturé au client</p>
                <div className="flex items-center space-x-1 justify-center mt-6">
                  <input
                    onChange={(e) => setTauxFacture(e.target.value)}
                    value={
                      selectedPrime.TauxFacture != '0'
                        ? selectedPrime.TauxFacture
                        : ''
                    }
                    className="border border-gray-300 rounded-md p-2 "
                    placeholder="Taux facturé"
                  />
                </div>
              </div>
            </div>
            {!isNaN(selectedPrime.TauxPayeImposable
  ? selectedPrime.TauxFacture / selectedPrime.TauxPayeImposable
  : selectedPrime.TauxFacture / selectedPrime.TauxPayeNonImposable) && (
  <p className='mt-10'>
    Coefficient : {selectedPrime.TauxPayeImposable
      ? (selectedPrime.TauxFacture / selectedPrime.TauxPayeImposable).toFixed(2)
      : (selectedPrime.TauxFacture / selectedPrime.TauxPayeNonImposable).toFixed(2)}
  </p>
)}
            <div className="mt-10">
              <p>
                <button
                  onClick={validatePrime}
                  className="rounded-md w-96 bg-purple-500 text-white p-2"
                >
                  Valider
                </button>
              </p>
            </div>
          </div>
        </Modal>
      )}
      <p className="font-semibold text-center text-3xl">Primes</p>
      <div className="h-80 mt-6 overflow-scroll">
        {usedPrimes?.map((pr) => {
          return (
            <div
              key={pr.IdPrime}
              className="flex items-center space-x-2 text-lg"
            >
              <Checkbox
                onChange={(e) => selectPrimes(e, pr)}
                checked={selectedPrimes.some((p) => p.IdPrime === pr.IdPrime)}
              />
              <p
                onClick={() => {
                  setSelectedPrime(
                    selectedPrimes.find((p) => p.IdPrime === pr.IdPrime),
                  )
                }}
                className="flex items-center space-x-2"
              >
                <span>{pr.TxtPrime}</span>
                {pr.TauxPayeNonImposable !== 0 && (
                  <span>{pr.TauxPayeNonImposable}&euro;</span>
                )}
                {pr.TauxPayeImposable !== 0 && (
                  <span>{pr.TauxPayeImposable}&euro;</span>
                )}
              </p>
            </div>
          )
        })}
        {addGlobalPrime && (
          <div>
            <input
              placeholder="Rechercher une prime"
              className="outline-none rounded-md p-2 border border-gray-300 w-96"
              onChange={(e) => filterGlobalPrimes(e.target.value)}
            />
            {primeGlobal?.map((pr) => {
              return (
                <div
                  key={pr.IdPrime}
                  className="flex items-center space-x-2 text-lg"
                >
                  <Checkbox
                    onChange={(e) => selectPrimes(e, pr)}
                    checked={selectedPrimes.some(
                      (p) => p.IdPrime === pr.IdPrime,
                    )}
                  />
                  <p
                    onClick={() => {
                      setSelectedPrime(
                        selectedPrimes.find((p) => p.IdPrime === pr.IdPrime),
                      )
                    }}
                    className="flex items-center space-x-2"
                  >
                    <span>{pr.Designation}</span>
                  </p>
                </div>
              )
            })}
          </div>
        )}
      </div>

      <div>
        <p className="flex items-center justify-center">
          <button
            onClick={() => setAddGlobalPrime(!addGlobalPrime)}
            className="bg-purple-500 text-white rounded-md p-2"
          >
            Ajouter une prime
          </button>
        </p>
      </div>
    </div>
  )
}

export default CreateContractPrime

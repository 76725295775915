import AxiosInstance from '../../axios/axiosInstance'
import { useEffect, useState, useRef } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import {
  AddCircleOutlineOutlined,
  CalendarMonth,
  RefreshOutlined,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../axios/axiosInstance'
import ApartmentIcon from '@mui/icons-material/Apartment'
import PaymentsIcon from '@mui/icons-material/Payments'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import * as XLSX from 'xlsx'
import xlsIcon from '../../assets/img/xls.png'
import { saveAs } from 'file-saver'
import { Icon } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import AttachEmailIcon from '@mui/icons-material/AttachEmail'
import logo from '../../assets/img/logoloader.png'

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { DatePicker, Input, Modal, Pagination, Select, message } from 'antd'
import FilterBill from './FilterBill'

const columns = [
  { id: 'companyName', label: 'Societé', minWidth: 80 },
  { id: 'siren', label: 'Siret', minWidth: 80 },
  { id: 'lastname', label: 'Nom', minWidth: 80 },
  { id: 'firstname', label: 'Prénom', minWidth: 80 },
  { id: 'email', label: 'Email', minWidth: 80 },
  { id: 'phone', label: 'Téléphone', minWidth: 80 },
]

function formatNumber(number) {
  const parts = number.split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join(',')
}

export default function ClientBill({ state }) {
  const [bills, setBills] = useState([])
  const [originalBills, setOriginalBills] = useState([])
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [isUploadFile, setIsUploadFile] = useState(false)
  const [documentCount, setDocumentCount] = useState(0)
  const [selectedFile, setSelectedFile] = useState(null)
  const [filter, setFilter] = useState(
    state === 'impayée' ? 'nonSoldees' : 'Toutes',
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingParsing, setIsloadingParsing] = useState(false)
  const [isRelance, setIsRelance] = useState(false)
  const [selectedBill, setSelectedBill] = useState(null)
  const [typeRelance, setTypeRelance] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [searchEntry, setSearchEntry] = useState('')
  const [showContacts, setShowContacts] = useState(false)
  const [isNonSoldee, setIsNonSoldee] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)

  const navigate = useNavigate()
  const inputFileRef = useRef()

  const showContact = (client) => {
    setSelectedClient(client)
    setShowContacts(true)
  }

  const handleClick = () => {
    inputFileRef.current.click()
  }

  const selectResearch = (research) => {
    if (research.length === 0) {
      setBills(originalBills)
    }
    setSearchEntry(research)
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file.type === 'application/pdf') {
      setSelectedFile(event.target.files[0])
    } else {
      message.error('Veuillez ajouter un fichier pdf !')
    }
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const formatJsHour = (date) => {
    const newDate = new Date(date)
    const hours = String(newDate.getHours()).padStart(2, '0')
    const minutes = String(newDate.getMinutes()).padStart(2, '0') // Months are 0-based, so we add 1

    return `${hours}:${minutes}`
  }

  const formatDate = (inputDate) => {
    const year = inputDate.substring(0, 4)
    const month = inputDate.substring(4, 6)
    const day = inputDate.substring(6, 8)

    return `${day}/${month}/${year}`
  }

  useEffect(() => {
    if (state === 'impayée') {
      filterFromDashboard()
      return
    }

    axiosInstance
      .get('facture/count')
      .then((res) => setDocumentCount(res.data))
      .catch((e) => {})

    loadBills()
  }, [])

  const handleExportClick = () => {
    exportToExcel(bills)
  }

  const rechargerFactures = () => {
    axiosInstance
      .get('facture/reload')
      .then((res) => {
        console.log(res.data)
        message.success('Factures mises à jour !')
      })
      .catch((e) => {
        message.error("Une erreur s'est produite !")
      })
  }
  const exportToExcel = (data) => {
    const newData = data.map((bill) => {
      const { IdFacture, RaisonSociale, TotalTTC, DateEcheance } = bill
      return {
        'N° facture': IdFacture,
        Client: RaisonSociale,
        Montant: TotalTTC,
        Date: formatDate(DateEcheance),
      }
    })

    const workbook = XLSX.utils.book_new()
    const sheet = XLSX.utils.json_to_sheet(newData)

    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet 1')

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    })

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    saveAs(blob, 'facture.xlsx')
  }

  const handleDownloadPdf = (facture) => {
    axiosInstance
      .get(`facture/${facture._id}`)
      .then((res) => {
        console.log(res.data)
        const binaryData = Uint8Array.from(window.atob(res.data.data), (char) =>
          char.charCodeAt(0),
        )
        const pdfBlob = new Blob([binaryData], { type: 'application/pdf' })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        window.open(pdfUrl, '_blank')
      })
      .catch((e) => console.log(e))
    // Generate a temporary anchor element and set the PDF file as its href
  }

  const onChangePage = (page, pageSize) => {
    //setIsLoading(true)
    axiosInstance
      .get(`facture/paginate/${page - 1}`)
      .then((res) => {
        setBills(res.data)
        setCurrentPage(page)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  /*   const getSoldee =  () => {
    alert("ok")
    axiosInstance
    .get(`facture/soldee`)
    .then((res) => {
      setBills(res.data)
      setFilter("Soldees")
    })
    .catch((e) => {
      console.log(e)
    })
  }
 */

  const filterFromDashboard = () => {
    axiosInstance
      .post('facture/filter', {
        FactureRegle: 'NON',
        DateEcheance: new Date(),
      })
      .then((res) => {
        res.data.sort((a, b) => {
          const dateA = a.DateEcheance // Assuming 'dateField' is the name of the date field
          const dateB = b.DateEcheance // Assuming 'dateField' is the name of the date field

          // Compare the dates as strings
          if (dateA < dateB) {
            return 1 // 'a' comes before 'b'
          }
          if (dateA > dateB) {
            return -1 // 'b' comes before 'a'
          }
          return 0 // Dates are equal
        })
        setBills(res.data)
        setFilter('nonSoldees')
        setIsNonSoldee(true)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getNonSoldee = () => {
    axiosInstance
      .get(`facture/non-soldee`)
      .then((res) => {
        setBills(res.data)
        setFilter('nonSoldees')
        setIsNonSoldee(true)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const search = () => {
    axiosInstance
      .post('facture/find-by-text', { query: searchEntry })
      .then((res) => {
        const loadedBills = res.data
        /*  loadedBills.sort((a, b) => {
          // Compare client names
          const clientComparison = a.client.companyName.localeCompare(
            b.client.companyName,
          )

          // If client names are equal, compare candidate names
          if (clientComparison === 0) {
            return a.candidate.lastname.localeCompare(b.candidate.lastname)
          }

          return clientComparison
        }) */
        setBills(loadedBills)
      })
  }

  const parseFile = () => {
    setIsloadingParsing(true)
    if (!selectedFile) {
      message.error('Veuillez remplir tous les champs!')
      return
    }

    const formData = new FormData()
    formData.append('file', selectedFile)

    AxiosInstance.post('facture/parsing', formData)
      .then((res) => {
        console.log(res.data.length)
        const data = res.data + ''
        message.success('Fichier correctement splité !')
        setIsUploadFile(false)
        setIsloadingParsing(false)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
          return
        }
        setIsloadingParsing(false)
      })
  }

  const relance = (e, facture) => {
    e.stopPropagation()
    if (!facture.client) {
      message.error("le client n'a pas de numero pld (en attente)")
      return
    }

    setIsRelance(true)
    setSelectedBill(facture)
    setContactEmail('')
  }

  const sendRelance = () => {
    if (typeRelance.length === 0) {
      message.error('Selectionnez un type de relance')
      return
    }

    if (contactEmail.length === 0) {
      message.error('Selectionnez un contact')
      return
    }

    const relance = [
      ...selectedBill.relance,
      { message: typeRelance, date: Date.now(), contactEmail },
    ]
    AxiosInstance.post(`facture/relance/${selectedBill._id}`, {
      relance,
      typeRelance,
      contactEmail,
    })
      .then((res) => {
        const newBills = [...bills]
        newBills[bills.findIndex((bill) => bill._id === res.data._id)] =
          res.data
        setBills(newBills)
        setIsRelance(false)
        setTypeRelance('')
        message.success('Relance envoyée !')
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  const desoldFacture = (bill) => {
    axiosInstance
      .get(`facture/${bill._id}/desold`)
      .then((res) => {
        const newBills = [...bills]
        newBills[bills.findIndex((bill) => bill._id === res.data._id)] =
          res.data
        setBills(newBills)
        message.success('facture desoldée!')
      })
      .catch((e) => console.log(e))
  }

  const soldFacture = (bill) => {
    axiosInstance
      .get(`facture/${bill._id}/sold`)
      .then((res) => {
        const newBills = [...bills]
        newBills[bills.findIndex((bill) => bill._id === res.data._id)] =
          res.data
        setBills(newBills)
        message.success('facture soldée!')
      })
      .catch((e) => console.log(e))
  }

  const loadBills = () => {
    AxiosInstance.get('facture')
      .then((data) => {
        console.log(data.data)
        setBills(data.data)
        setOriginalBills(data.data)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  const onChangeTypeRelance = (e) => {
    setTypeRelance(e)
  }

  return (
    <>
      {showContacts && (
        <Modal
          width={600}
          open={showContacts}
          onCancel={() => {
            setShowContacts(false)
            setSelectedClient(null)
          }}
        >
          <div className="flex flex-col space-y-3">
            {selectedClient.contacts.map((contact) => {
              return (
                <p>
                  {contact.firstname} {contact.lastname} - {contact.phone}
                </p>
              )
            })}
          </div>
        </Modal>
      )}
      {isRelance && (
        <Modal
          width={1000}
          footer={null}
          open={isRelance}
          onCancel={() => {
            setIsRelance(false)
            setSelectedBill(null)
          }}
        >
          <div className="flex flex-col my-10 space-y-6 items-center justify-center ">
            <Select
              className="w-96"
              showSearch
              onChange={(e) => onChangeTypeRelance(e)}
              optionFilterProp="children"
              placeholder="Selectionner type de relance"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: 'Echéance prochaine',
                  label: 'Echéance prochaine (NIV 1)',
                  key: 'Echéance prochaine',
                },
                {
                  value: 'Facture impayée',
                  label: 'Facture impayée (NIV 2)',
                  key: 'Facture impayée',
                },
                {
                  value: 'Mise en demeure',
                  label: 'Mise en demeure (NIV 3)',
                  key: 'Mise en demeure',
                },
              ]}
            />

            <Select
              className="w-96"
              showSearch
              onChange={(e) => setContactEmail(e)}
              value={contactEmail.length > 0 ? contactEmail : null}
              optionFilterProp="children"
              placeholder="Selectionner interlocuteur"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={selectedBill.client.contacts?.map((contact, idx) => {
                return {
                  value: contact.email,
                  label: contact.email,
                  key: idx,
                }
              })}
            />
            {typeRelance === 'Facture impayée' && (
              <div className="w-3/5 flex justify-center">
                <p>
                  Cher Client,<br></br>
                  <br></br>
                  Nous vous informons que la facture numéro{' '}
                  <span className="font-semibold">
                    {selectedBill.IdFacture}
                  </span>
                  ,d'un montant de{' '}
                  <span className="font-semibold">
                    {selectedBill.TotalTTC}&euro;
                  </span>
                  ,<br></br>
                  émise le{' '}
                  <span className="font-semibold">
                    {formatJsDate(selectedBill.createdAt)}
                  </span>
                  , demeure actuellement impayée.<br></br>
                  <br></br>
                  Nous sollicitons votre prompte attention pour régler cette
                  facture dans les meilleurs délais.<br></br>
                  <br></br>
                  Veuillez trouver ci-dessous nos coordonnées bancaires pour
                  faciliter le règlement : [Coordonnées bancaires].<br></br>
                  <br></br>
                  Nous restons à votre disposition pour toute clarification ou
                  information supplémentaire.<br></br>
                  <br></br>
                  Cordialement,
                </p>
              </div>
            )}

            {typeRelance === 'Echéance prochaine' && (
              <div className="w-3/5 flex justify-center">
                <p>
                  Cher client,<br></br>
                  <br></br>
                  Nous tenons à vous rappeler que la date d'échéance de votre
                  facture n°
                  <span className="font-semibold">
                    {selectedBill.IdFacture}
                  </span>{' '}
                  approche à grands pas{' '}
                  <span className="font-semibold">
                    ({formatDate(selectedBill.DateEcheance)})
                  </span>
                  . <br></br>
                  <br></br>
                  Pour faciliter le processus de paiement et éviter tout retard,
                  nous souhaitons vous informer de l'importance de régler vos
                  factures à temps.<br></br>
                  <br></br>
                  Si vous avez des questions concernant le montant ou les
                  modalités de paiement, n'hésitez pas à contacter notre équipe.
                  <br></br>
                  <br></br>
                  Nous vous remercions par avance de votre diligence dans le
                  règlement de vos factures et de votre confiance en notre
                  entreprise.<br></br>
                  <br></br>
                  Cordialement,
                </p>
              </div>
            )}

            {typeRelance === 'Mise en demeure' && (
              <div className="w-3/5 flex justify-center">
                <p>
                  Cher client,<br></br>
                  <br></br>
                  Je prends l'initiative de vous écrire en tant que représentant
                  de {selectedBill.agency.name} concernant la facture impayée à
                  votre nom, émises conformément à nos termes contractuels.
                  <br></br>
                  <br></br>
                  Malgré tous nos appels antérieurs et nos efforts pour résoudre
                  cette situation à l'amiable, nous constatons que le règlement
                  de la facture suivante demeure en souffrance :<br></br>
                  <br></br>- facture n°
                  <span className="font-semibold">
                    {selectedBill.IdFacture}
                  </span>{' '}
                  :{' '}
                  <span className="font-semibold">{selectedBill.TotalTTC}</span>
                  € TTC<br></br>
                  <br></br>
                  Conformément aux termes de l’article L. 441-6 du Code de
                  commerce, le montant des factures est augmenté de pénalités de
                  retard au taux d'intérêt légal.<br></br>
                  <br></br>
                  Le montant est également augmenté de la clause pénale, d’un
                  montant forfaitaire de 40€.<br></br>
                  <br></br>
                  Nous vous mettons en demeure de régler la somme de{' '}
                  <span className="font-semibold">{selectedBill.TotalTTC}</span>
                  €, toutes taxes comprises (TTC), augmenté des pénalités de
                  retard précitées, sous{' '}
                  <span className="font-semibold">48h</span> à compter de la
                  réception du présent e-mail.<br></br>
                  <br></br>
                  Pour régulariser votre situation et payer votre dette sans
                  frais supplémentaires, il convient d’effectuer un virement sur
                  le compte bancaire de notre société, ou de nous adresser un
                  chèque.<br></br>
                  <br></br>
                  Voici nos coordonnées bancaires : IBAN :{' '}
                  {selectedBill.agency.iban} BIC : {selectedBill.agency.BIC}
                  <br></br>
                  <br></br>A défaut, nous serons dans l’obligation de saisir les
                  juridictions compétentes d’une demande de condamnation à votre
                  encontre, laquelle sera augmentée des frais notamment de
                  justice et de procédure exposés inutilement pour se défendre.
                  Une déclaration pour impayé sera aussi faite auprès de notre
                  assurance crédit.<br></br>
                  <br></br>
                  Vous devez considérer le présent comme une mise en demeure de
                  nature à faire courir tout droit et intérêt que la loi et les
                  tribunaux attachent aux mises en demeure.<br></br>
                  <br></br>
                  Dans l’attente de votre règlement, recevez, Cher client,
                  l’expression de nos sentiments distingués.<br></br>
                  <br></br>
                  Cordialement,
                </p>
              </div>
            )}

            {/* <button
              onClick={sendRelance}
              className="bg-mountain-500 w-96 font-semibold p-2 rounded-md text-white"
            >
              Modifier le message
            </button> */}

            <button
              onClick={sendRelance}
              className="bg-mountain-500 w-96 font-semibold p-2 rounded-md text-white"
            >
              Envoyer la relance
            </button>
          </div>
        </Modal>
      )}

      {isUploadFile && (
        <Modal
          width={1000}
          className="h-[400px]"
          footer={null}
          open={isUploadFile}
          onCancel={() => {
            setIsUploadFile(false)
            setSelectedFile(null)
          }}
        >
          <div className="flex w-3/5 flex-col justify-center text-center  mt-10 h-full mx-auto">
            <div
              onClick={handleClick}
              className="border border-[#00000040] py-3 text-[#00000040] rounded-md"
            >
              <CloudUploadIcon />
              <p className="mt-2">Ajouter fichier facture</p>
              <p className="text-black">{selectedFile?.name}</p>
              <input
                type="file"
                onChange={(e) => handleFileChange(e)}
                className="hidden"
                ref={inputFileRef}
              />
            </div>

            <div className="flex mb-2 items-center justify-center w-full">
              <button
                onClick={parseFile}
                className="rounded-full bg-mountain-500 hover:bg-mountain-600 py-4 px-10 mt-9 text-base uppercase font-semibold leading-none text-white   focus:ring-6 focus:ring-offset-2 focus:outline-none"
              >
                {isLoadingParsing ? (
                  <span>
                    <Icon
                      icon={'material-symbols:edit-square-outline'}
                      width={25}
                    />
                    ...chargement
                  </span>
                ) : (
                  <span>Spliter factures</span>
                )}
              </button>
            </div>
          </div>
        </Modal>
      )}

      <div className="flex hover:cursor-pointer items-center w-fit  border rounded-lg border-gray-300 bg-[#F7F9FC] ">
        <p
          onClick={() => {
            loadBills()
            setFilter('Toutes')
            setIsNonSoldee(false)
          }}
          className={`${
            filter === 'Toutes' && 'bg-purple-400'
          }  border-r p-2 border-r-gray-300`}
        >
          Toutes
        </p>

        {/*  <p
          onClick={() => {
            getSoldee()
          }} 
          className={`${
            filter === 'Soldees' && 'bg-purple-400'
          }  border-r p-2 border-r-gray-300`}
        >
          Soldées
        </p> */}
        <p
          onClick={() => {
            getNonSoldee()
          }}
          className={`${
            filter === 'nonSoldees' && 'bg-purple-400'
          }  border-r p-2 border-r-gray-300`}
        >
          Non soldées
        </p>
      </div>

      <div className="flex justify-end ml-20 mb-10 mt-10">
        <div className="flex items-center space-x-2">
          {isOpenFilter && (
            <FilterBill
              open={isOpenFilter}
              setIsOpen={setIsOpenFilter}
              bills={originalBills}
              setBills={setBills}
            />
          )}
          <Input
            className="w-80  rounded-md border border-gray-200 focus:outline-none"
            placeholder="Recherche"
            style={{ outline: 'none' }}
            onChange={(e) => selectResearch(e.target.value)}
          />
          <button
            onClick={search}
            className="bg-purple-500 text-white p-2 rounded-md"
          >
            rechercher
          </button>

          <AddCircleOutlineOutlined
            onClick={() => setIsUploadFile(true)}
            className="text-gray-500 text-3xl  hover:cursor-pointer"
          />
          <RefreshOutlined
            onClick={() => rechargerFactures(true)}
            className="text-gray-500 text-3xl  hover:cursor-pointer"
          />
          {/* <img src={logo} className="w-10 h-10 animate-spin" /> */}
          <img
            src={xlsIcon}
            onClick={handleExportClick}
            className="w-8 h-8 mx-2 hover:cursor-pointer"
          />
          <FilterAltIcon
            onClick={() => setIsOpenFilter(true)}
            className="text-gray-500 text-3xl"
          />
        </div>
      </div>
      {/*   <div className="flex items-center justify-between  mb-6  text-md">
        <p className="w-[14%] text-center">NOM CLIENT</p>
        <p className="w-[14%] text-center">NUMERO DE FACTURE</p>
        <p className="w-[14%] text-center">ECHEANCE</p>
        <p className="w-[14%] text-center">MONTANT TTC</p>
        <p className="w-[14%] text-center">STATUT</p>
        <p className="w-[14%] text-center">RELANCE</p>
        <p className="w-[14%] text-center">SOLDE</p>
      </div> */}

      <div className="flex flex-col space-y-2">
        <div className="flex items-center w-full">
          <p className="w-[14%] text-center font-semibold">CLIENT</p>
          <p className="w-[14%] text-center font-semibold">N° FACTURE</p>
          <p className="w-[14%] text-center font-semibold">DATE D'ECHEANCE</p>
          <p className="w-[14%] text-center font-semibold">MONTANT TTC</p>
          <p className="w-[14%] text-center font-semibold">STATUT</p>
          <p className="w-[14%] text-center font-semibold">RELANCE</p>
          <p className="w-[14%] text-center font-semibold">ACTION</p>
        </div>
        {bills.map((bill, idx) => {
          return (
            <div
              key={idx}
              className="flex items-center drop-shadow-2xl text-sm justify-between p-1 bg-white border border-gray-200 rounded-md  hover:scale-105 hover:cursor-pointer transition-all duration-300"
            >
              <div
                onClick={() => showContact(bill.client)}
                className="w-[14%] flex items-center  space-x-2"
              >
                <ApartmentIcon className="text-3xl text-gray-300" />

                <p className="text-xs">{bill.RaisonSociale}</p>
              </div>

              <div
                onClick={() => handleDownloadPdf(bill)}
                className="w-[14%] flex items-center justify-center space-x-2"
              >
                <DescriptionOutlinedIcon
                  className={`text-3xl text-gray-300 ${
                    bill.addedData ? 'text-green-500' : ''
                  }`}
                />
                <p className=" text-center">{bill.IdFacture}</p>
              </div>

              <div
                onClick={() => handleDownloadPdf(bill)}
                className="w-[14%] flex items-center space-x-2 justify-center"
              >
                <div>
                  <p>
                    <CalendarMonth className="text-3xl text-gray-300" />
                  </p>
                </div>
                <div>
                  <p className=" text-right">{formatDate(bill.DateEcheance)}</p>
                </div>
              </div>

              <div
                onClick={() => handleDownloadPdf(bill)}
                className="w-[14%] flex justify-center items-center space-x-1 "
              >
                <PaymentsIcon className="text-3xl text-gray-300" />
                <p className="font-bold ">
                  {formatNumber(bill.TotalTTC)}&euro;
                </p>
              </div>

              <div
                onClick={() => handleDownloadPdf(bill)}
                className="w-[14%] flex justify-center items-center "
              >
                {bill.FactureRegle === 'OUI' && (
                  <p className="text-green-500 border border-green-500 px-2 py-1 rounded-md font-semibold">
                    Soldée
                  </p>
                )}

                {bill.FactureRegle !== 'OUI' && (
                  <p className="text-orange-400 border border-orange-400 px-2 py-1 rounded-md font-semibold">
                    Non soldée
                  </p>
                )}
              </div>

              <div className="w-[14%] flex flex-col space-y-2 justify-center items-center ">
                <div>
                  <AttachEmailIcon
                    onClick={(e) => relance(e, bill)}
                    className={`text-3xl text-gray-300  ${
                      bill.FactureRegle === 'NON' ? 'text-green-500' : ''
                    }`}
                  />
                </div>
                <div>
                  {bill.relance.map((rel) => {
                    return (
                      <div>
                        <p className="text-xs italic">Relance {rel.message}</p>
                        <p className="text-xs italic font-semibold">
                          le {formatJsDate(rel.date)} à {formatJsHour(rel.date)}{' '}
                        </p>
                        <p className="text-xs italic">à {rel.contactEmail}</p>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="w-[14%] flex justify-center items-center ">
                {bill.FactureRegle === 'NON' && (
                  <button
                    onClick={() => soldFacture(bill)}
                    className="p-2 border border-green-500 rounded-md text-green-500"
                  >
                    Solder la facture
                  </button>
                )}
                {bill.FactureRegle === 'OUI' && (
                  <button
                    onClick={() => desoldFacture(bill)}
                    className="p-2 border border-orange-400 rounded-md text-orange-400"
                  >
                    Desolder la facture
                  </button>
                )}
              </div>
            </div>
          )
        })}
      </div>

      <div>
        {/*  <div className="border border-gray-300 rounded-md">
          <div className="flex items-center justify-around  text-md py-3">
            <p className="w-[10%] text-center font-medium">Recruteur</p>
            <p className="w-[10%] text-center font-medium">Client</p>
            <p className="w-[10%] text-center font-medium">Vacataire</p>
            <p className="w-[10%] text-center font-medium">Type</p>
            <p className="w-[10%] text-center font-medium">Poste</p>
            <p className="w-[10%] text-center font-medium">Date</p>
            <p className="w-[10%] text-center font-medium">Facturation</p>
            <p className="w-[10%] text-center font-medium">Commentaire</p>
            <p className="w-[10%] text-center font-medium">Statut</p>
            <p className="w-[10%] text-center font-medium">Action</p>
          </div>
        </div> */}
      </div>

      <div className="relative mx-auto max-h-[70vh] w-11/12 overflow-x-auto shadow-md sm:rounded-lg"></div>
      {!isSearch && !isNonSoldee && (
        <div className="text-right">
          <Pagination
            className="mt-6 w-full"
            current={currentPage}
            onChange={onChangePage}
            total={documentCount}
            pageSize={48}
          />
        </div>
      )}
    </>
  )
}

import { DatePicker, Modal, Select, message, Input, Checkbox } from 'antd'
import { useState, useEffect } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import { useNavigate } from 'react-router-dom'
import { DeleteForeverOutlined } from '@mui/icons-material'
import qualifications from '../../qualifications/qualifications'
import dayjs from 'dayjs'
import { DesktopTimePicker, TimePicker } from '@mui/x-date-pickers'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { saveAs } from 'file-saver'

const CreateFacture = ({ client, isCreateFacture, setIsCreateFacture }) => {
  const [step, setStep] = useState('1')
  const [facture, setFacture] = useState({ client: client._id, dates: [] })

  const [facturationDescription, setFactureDescription] = useState('')
  const [facturationQuantity, setFactureQuantity] = useState('')
  const [facturationRate, setFactureRate] = useState('')
  const [comment, setComment] = useState('')
  const [dateDebut, setDateDebut] = useState(null)
  const [startHour, setStartHour] = useState(null)
  const [endHour, setEndHour] = useState(null)
  const [duration, setDuration] = useState('')
  const [timer, setTimer] = useState(null)
  const [selectedCandidates, setSelectedCandidates] = useState([])
  const [selectedCandidate, setSelectedCandidate] = useState(null)
  const [clientAddressesArray, setClientAdressesArray] = useState([])
  const [isWellStructuredDate, setIsWellStructuredDate] = useState(false)
  const [qualificationArray, setQualificationArray] = useState([])

  const selectDateDebut = (e) => {
    setDateDebut(e)
  }

  const selectStartDate = (e) => {
    setFacture({ ...facture, startDate: e })
  }

  const selectEndDate = (e) => {
    setFacture({ ...facture, endDate: e })
  }

  const selectComment = (e) => {
    setFacture({ ...facture, comment: e })
  }

  const generateFacture = () => {
    axiosInstance
      .post('bill', facture)
      .then((res) => {
        if (facture.type === 'Vacation')
          message.success('Contrat de vacation et facture créés !')
        else message.success('Facture créé!')
        setIsCreateFacture(false)
      })
      .catch((e) => console.log(e))
  }

  const addFacturation = () => {
    if (!facturationDescription.length) return
    if (!facturationQuantity.length) return
    if (!facturationRate.length) return

    const facturations = facture.facturation
      ? [
          ...facture.facturation,
          {
            description: facturationDescription,
            quantity: facturationQuantity,
            rate: facturationRate,
          },
        ]
      : [
          {
            description: facturationDescription,
            quantity: facturationQuantity,
            rate: facturationRate,
          },
        ]
    setFactureDescription('')
    setFactureQuantity('')
    setFactureRate('')
    setFacture({ ...facture, facturation: facturations })
  }

  const selectStep = (step, direction) => {
    if (facture.type !== 'Vacation' && step === '6' && direction === 'f')
      setStep('8')
    else if (facture.type !== 'Vacation' && step === '7' && direction === 'b')
      setStep('5')
    else setStep(step)
  }

  const selectContact = (e) => {
    setFacture({ ...facture, contact: e })
  }

  useEffect(() => {
    const userId = localStorage.getItem('userId')
    setFacture({ ...facture, user: userId })
    const clientAdresses = [
      ` ${client.address} ${client.postCode} ${client.town} `.toUpperCase(),
      `Adresse de livraison : ${client.addressLivraison} ${client.postCodeLivraison} ${client.townLivraison} `.toUpperCase(),
      `Adresse de facturation : ${client.addressFacturation} ${client.postCodeFacturation} ${client.townFacturation} `.toUpperCase(),
    ]
    const clientAddressesArray = clientAdresses.map((address, idx) => {
      return {
        value: address,
        label: address,
        key: idx,
      }
    })
    setClientAdressesArray(clientAddressesArray)
  }, [])

  const setAdressFromSiege = () => {
    const newFacture = { ...facture }
    newFacture.clientName = client.companyName
    newFacture.address = client.address
    newFacture.postCode = client.postCode
    newFacture.town = client.town
    setFacture(newFacture)
  }

  const setAdressFromLivraison = () => {
    const newFacture = { ...facture }
    newFacture.clientName = client.companyName
    newFacture.address = client.addressLivraison
    newFacture.postCode = client.postCodeLivraison
    newFacture.town = client.townLivraison
    setFacture(newFacture)
  }

  const setAdressFromFacturation = () => {
    const newFacture = { ...facture }
    newFacture.clientName = client.companyName
    newFacture.address = client.addressFacturation
    newFacture.postCode = client.postCodeFacturation
    newFacture.town = client.townFacturation
    setFacture(newFacture)
  }

  const selectClientName = (e) => {
    const newFacture = { ...facture }
    newFacture.clientName = e
    setFacture(newFacture)
  }

  const selectService = (e) => {
    const newFacture = { ...facture }
    newFacture.service = e
    setFacture(newFacture)
  }

  const selectAddress = (e) => {
    const newFacture = { ...facture }
    newFacture.address = e
    setFacture(newFacture)
  }

  const selectPostCode = (e) => {
    const newFacture = { ...facture }
    newFacture.postCode = e
    setFacture(newFacture)
  }

  const selectTown = (e) => {
    const newFacture = { ...facture }
    newFacture.town = e
    setFacture(newFacture)
  }

  const deleteDate = (idx) => {
    const dates = facture.dates
    dates.splice(idx, 1)
    setFacture({ ...facture, dates })
  }

  const selectHeureDebut = (newValue) => {
    setStartHour(newValue)
  }

  const selectHeureFin = (newValue) => {
    setEndHour(newValue)
  }

  const selectJustificatif = (e) => {
    setFacture({ ...facture, justificatif: e })
  }

  const deleteFacturationItem = (idx) => {
    const facturations = facture.facturation.filter(
      (fact, index) => idx !== index,
    )
    setFacture({ ...facture, facturation: facturations })
  }

  const addDateToFacture = () => {
    const dates = facture.dates
    dates.push({ dateDebut })
    setDateDebut(null)
    setFacture({ ...facture, dates })
  }

  const validateDate = (idx) => {
    const date = facture.dates[idx]
    const newDates = [...facture.dates]
    if (!startHour || !endHour || !duration.length) {
      message.error('Veuillez ajouter les horaires !')
      return
    }
    const newDate = { ...date, startHour, endHour, duration }
    newDates[idx] = newDate
    setDuration('')
    setFacture({ ...facture, dates: newDates })
    isWellStructured(newDates)
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0')
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const formatJsHour = (date) => {
    const newDate = new Date(date)
    const hours = String(newDate.getHours()).padStart(2, '0')
    const minutes = String(newDate.getMinutes()).padStart(2, '0')

    return `${hours}:${minutes}`
  }

  const isWellStructured = (dates) => {
    const bool = dates.every((obj) => {
      return (
        obj.hasOwnProperty('dateDebut') &&
        obj.hasOwnProperty('startHour') &&
        obj.hasOwnProperty('endHour') &&
        obj.hasOwnProperty('duration')
      )
    })
    setIsWellStructuredDate(bool)
  }

  const formattedDate = (date) => {
    return (
      date.hasOwnProperty('dateDebut') &&
      date.hasOwnProperty('startHour') &&
      date.hasOwnProperty('endHour') &&
      date.hasOwnProperty('duration')
    )
  }

  const search = (word) => {
    if (word.length === 0) {
      setSelectedCandidates([])
    }
    if (word.length < 3) {
      if (timer) clearTimeout(timer)
    }
    if (word.length >= 3) {
      if (timer) clearTimeout(timer)

      const timer2 = setTimeout(() => {
        axiosInstance
          .post('tempworker-candidate/find-by-text', { query: word })
          .then((res) => {
            setSelectedCandidates(res.data)
            console.log(res.data)
          })
          .catch((e) => console.log(e))
      }, 700)
      setTimer(timer2)
    }
  }

  const selectCandidate = (candidate) => {
    setSelectedCandidate(candidate)
    const qualifications = candidate.qualifications.map(
      (qualification, idx) => {
        return {
          value: qualification,
          label: qualification,
          key: qualification,
        }
      },
    )
    setQualificationArray(qualifications)
    setFacture({ ...facture, candidate: candidate._id })
  }

  const selectQualification = (e) => {
    console.log(e)
    setFacture({ ...facture, qualification: e })
  }

  const selectTypePrestation = (e) => {
    setFacture({ ...facture, type: e })
  }

  const downloadFile = () => {
    const fileUrl = `${process.env.PUBLIC_URL}/model-vierge.xlsx`
    saveAs(fileUrl, 'modele-vierge.xlsx') // The second argument is the name of the downloaded file
  }

  return (
    <Modal
      width={1000}
      open={isCreateFacture}
      footer={null}
      onCancel={() => setIsCreateFacture(false)}
    >
      <div>
        {step === '1' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <div className="flex mb-32 mt-10 space-x-2 items-center justify-center">
                <p>
                  <button
                    onClick={downloadFile}
                    className="rounded-md p-2 text-white w-60 bg-green-500"
                  >
                    Télécharger modèle vierge
                  </button>
                </p>
                <p>
                  <button className="rounded-md p-2 text-white w-60 bg-purple-500">
                    Importer fichier complété
                  </button>
                </p>
              </div>
              <p className="font-semibold mb-4  text-center text-3xl">
                Candidat
              </p>

              <Input
                className="outline-none mb-4 text-gray-600 font-light rounded-md placeholder-gray-400"
                placeholder="Candidat"
                onChange={(e) => search(e.target.value)}
              />
              {selectedCandidates.length > 0 && (
                <div className="border flex flex-col space-y-2 border-gray-300 rounded-md mt-4 p-2">
                  {selectedCandidates.map((candidate) => {
                    return (
                      <p
                        onClick={() => selectCandidate(candidate)}
                        className={`border-b flex items-center space-x-1  border-b-gray-300 pb-2 ${
                          selectedCandidate?._id === candidate._id
                            ? 'bg-green-400'
                            : ''
                        }`}
                      >
                        <span>{candidate.firstname} -</span>
                        <span>{candidate.lastname}-</span>
                        <span>{candidate.email} -</span>
                        <span>{candidate.phone}</span>
                      </p>
                    )
                  })}
                </div>
              )}
            </div>

            <div className="flex items-center justify-center ">
              <button
                disabled={!facture.client}
                onClick={() => selectStep('2', 'f')}
                className={`${
                  !facture.client
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 mt-4`}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '2' && (
          <div className="flex flex-col h-full justify-between items-center">
            <p className="my-6 text-2xl font-semibold">
              Selectionner type de prestation
            </p>
            <Select
              className="w-96 mb-6"
              showSearch
              onChange={(e) => selectTypePrestation(e)}
              value={facture.type ? facture.type : null}
              optionFilterProp="children"
              placeholder="Selectionner type de prestation"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                { value: 'Vacation', label: 'Vacation', key: 'Vacation' },
                {
                  value: 'Placement CDI',
                  label: 'Placement CDI',
                  key: 'Placement CDI',
                },
                {
                  value: 'Placement CDD',
                  label: 'Placement CDD',
                  key: 'Placement CDD',
                },
              ]}
            />
            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('1', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('3', 'f')}
                className={`${
                  !facture.type
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!facture.type}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '3' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">
                Qualification
              </p>

              <Select
                className="w-full py-6 my-2 ring-0"
                showSearch
                onChange={(e) => selectQualification(e)}
                value={
                  facture.qualification ? `${facture.qualification}` : null
                }
                optionFilterProp="children"
                placeholder="Qualification"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={qualificationArray}
              />
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('2', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('4', 'f')}
                className={`${
                  !facture.qualification
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!facture.qualification}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '4' && (
          <div className="flex flex-col h-full justify-between">
            {facture.type === 'Vacation' && (
              <div>
                <div>
                  <p className="font-semibold text-center text-3xl">
                    Dates de la vacation
                  </p>
                  <div className="flex items-center my-6 space-x-2 justify-center">
                    <DatePicker
                      onChange={(e) => selectDateDebut(e.$d)}
                      value={dateDebut ? dayjs(dateDebut) : null}
                      className="w-1/3 h-10"
                      format="DD/MM/YYYY"
                      placeholder="Date"
                    />

                    <button
                      onClick={() => addDateToFacture()}
                      className="bg-mountain-500 text-white rounded-md p-2"
                    >
                      Ajouter la date
                    </button>
                  </div>

                  <div className="my-4 flex flex-col space-y-1 items-center">
                    {facture.dates.map((date, idx) => {
                      return (
                        <div className="flex items-center space-x-2">
                          <p className="bg-gray-100 p-4 rounded-md font-semibold">
                            {formatJsDate(date.dateDebut)}
                          </p>
                          {date.startHour && (
                            <p className="bg-gray-100 p-4 rounded-md font-semibold">
                              {formatJsHour(date.startHour)}
                            </p>
                          )}
                          {!date.startHour && (
                            <DesktopTimePicker
                              ampm={false}
                              className="w-40"
                              onChange={(newValue) => setStartHour(newValue.$d)}
                              views={['hours', 'minutes']}
                              value={
                                date.startHour ? dayjs(date.startHour) : null
                              }
                            />
                          )}

                          {date.endHour && (
                            <p className="bg-gray-100 p-4 rounded-md font-semibold">
                              {formatJsHour(date.endHour)}
                            </p>
                          )}
                          {!date.endHour && (
                            <DesktopTimePicker
                              ampm={false}
                              className="w-40"
                              onChange={(newValue) => setEndHour(newValue.$d)}
                              value={date.endHour ? dayjs(date.endHour) : null}
                            />
                          )}

                          {date.duration && (
                            <p className="bg-gray-100 p-4 rounded-md font-semibold">
                              {date.duration}h
                            </p>
                          )}
                          {!date.duration && (
                            <Input
                              placeholder="Durée"
                              className="w-40 p-4 rounded-md border border-gray-400"
                              onChange={(e) => setDuration(e.target.value)}
                            />
                          )}
                          {!formattedDate(facture.dates[idx]) && (
                            <button
                              onClick={() => validateDate(idx)}
                              className="bg-green-600 w-40 p-4 rounded-md text-white"
                            >
                              Valider la date
                            </button>
                          )}

                          <DeleteForeverOutlined
                            onClick={() => deleteDate(idx)}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className="flex items-center justify-center space-x-2">
                  <button
                    onClick={() => selectStep('3', 'b')}
                    className="bg-black rounded-md p-4 text-white"
                  >
                    Retour
                  </button>
                  <button
                    onClick={() => selectStep('5', 'f')}
                    className={`${
                      !isWellStructuredDate
                        ? 'bg-gray-200 text-gray-600'
                        : 'bg-mountain-500 text-white'
                    } rounded-md p-4 `}
                    disabled={!isWellStructuredDate}
                  >
                    Suivant
                  </button>
                </div>
              </div>
            )}

            {facture.type === 'Placement CDI' && (
              <div>
                <p className="font-semibold text-center text-3xl">
                  Date de début
                </p>
                <div className="flex items-center my-6 space-x-2 justify-center">
                  <DatePicker
                    onChange={(e) => selectStartDate(e.$d)}
                    value={facture.startDate ? dayjs(facture.startDate) : null}
                    className="w-1/3 h-10"
                    format="DD/MM/YYYY"
                    placeholder="Date de debut"
                  />
                </div>

                <div className="flex items-center justify-center space-x-2">
                  <button
                    onClick={() => selectStep('3', 'b')}
                    className="bg-black rounded-md p-4 text-white"
                  >
                    Retour
                  </button>
                  <button
                    onClick={() => selectStep('5', 'f')}
                    className={`${
                      !facture.startDate
                        ? 'bg-gray-200 text-gray-600'
                        : 'bg-mountain-500 text-white'
                    } rounded-md p-4 `}
                    disabled={!facture.startDate}
                  >
                    Suivant
                  </button>
                </div>
              </div>
            )}

            {facture.type === 'Placement CDD' && (
              <div>
                <p className="font-semibold text-center text-3xl">
                  Dates de la mission
                </p>
                <div className="flex items-center my-6 space-x-2 justify-center">
                  <DatePicker
                    onChange={(e) => selectStartDate(e.$d)}
                    value={facture.startDate ? dayjs(facture.startDate) : null}
                    className="w-1/3 h-10"
                    format="DD/MM/YYYY"
                    placeholder="Date de debut"
                  />

                  <DatePicker
                    onChange={(e) => selectEndDate(e.$d)}
                    value={facture.endDate ? dayjs(facture.endDate) : null}
                    className="w-1/3 h-10"
                    format="DD/MM/YYYY"
                    placeholder="Date de debut"
                  />
                </div>

                <div className="flex items-center justify-center space-x-2">
                  <button
                    onClick={() => selectStep('3', 'b')}
                    className="bg-black rounded-md p-4 text-white"
                  >
                    Retour
                  </button>
                  <button
                    onClick={() => selectStep('5', 'f')}
                    className={`${
                      !facture.startDate
                        ? 'bg-gray-200 text-gray-600'
                        : 'bg-mountain-500 text-white'
                    } rounded-md p-4 `}
                    disabled={!facture.startDate}
                  >
                    Suivant
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        {step === '5' && (
          <div className="flex flex-col h-full justify-between items-center">
            <p className="my-6 text-2xl font-semibold">Ajouter facturation</p>

            <div className=" flex items-center space-x-2">
              <input
                value={facturationDescription}
                onChange={(e) => setFactureDescription(e.target.value)}
                className="border outline-none placeholder:text-gray-300 border-gray-300 p-2 rounded-md"
                placeholder="description"
              />
              <input
                value={facturationQuantity}
                onChange={(e) => setFactureQuantity(e.target.value)}
                className="border outline-none placeholder:text-gray-300 border-gray-300 p-2 rounded-md"
                placeholder="quantité"
              />
              <input
                value={facturationRate}
                onChange={(e) => setFactureRate(e.target.value)}
                className="border outline-none placeholder:text-gray-300 border-gray-300 p-2 rounded-md"
                placeholder="taux/HT"
              />
              <button
                onClick={addFacturation}
                className="bg-green-500 text-white p-2 rounded-md"
              >
                Ajouter facturation
              </button>
            </div>

            <div>
              <div className="flex items-center text-xl font-semibold bg-gray-200 p-2 justify-between w-[700px] my-4">
                <p className="text-center w-1/4">Description</p>
                <p className="text-center w-1/4">Quantité</p>
                <p className="text-center w-1/4">Taux/HT</p>
                <p className="text-center w-1/4"></p>
              </div>

              <div>
                {facture.facturation &&
                  facture.facturation.map((fact, idx) => {
                    return (
                      <div
                        key={idx}
                        className="flex items-center justify-between w-[700px] space-x-3 my-4"
                      >
                        <p className="text-center w-1/4">{fact.description}</p>
                        <p className="text-center w-1/4">{fact.quantity}</p>
                        <p className="text-center w-1/4">{fact.rate}</p>
                        <p
                          className="w-1/4 text-end"
                          onClick={() => deleteFacturationItem(idx)}
                        >
                          <DeleteForeverOutlined />
                        </p>
                      </div>
                    )
                  })}
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('4', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('6', 'f')}
                className={`${
                  !facture.facturation
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!facture.facturation}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '6' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">
                Lieu de la mission
              </p>
              <div className="flex space-x-2 items-center justify-around mt-6">
                <p
                  onClick={() => setAdressFromSiege()}
                  className="text-xs hover:cursor-pointer text-blue-500
            "
                >
                  Recopier l'adresse du siège
                </p>
                <p
                  onClick={() => setAdressFromLivraison()}
                  className="text-xs hover:cursor-pointer text-blue-500
            "
                >
                  Recopier l'adresse de livraison
                </p>
                <p
                  onClick={() => setAdressFromFacturation()}
                  className="text-xs hover:cursor-pointer text-blue-500"
                >
                  Recopier l'adresse de facturation
                </p>
              </div>
              <div className="flex flex-col items-center my-6 space-y-3">
                <Input
                  onChange={(e) => selectClientName(e.target.value)}
                  value={facture.clientName ? `${facture.clientName}` : null}
                  className="rounded-md border border-gray-300 mb-2"
                  placeholder="Nom du client final"
                />
                <Input
                  onChange={(e) => selectService(e.target.value)}
                  value={facture.service ? `${facture.service}` : null}
                  className="rounded-md border border-gray-300 mb-2"
                  placeholder="Service"
                />
                <Input
                  onChange={(e) => selectAddress(e.target.value)}
                  value={facture.address ? `${facture.address}` : null}
                  className="rounded-md border border-gray-300 mb-2"
                  placeholder="Adresse"
                />
                <Input
                  onChange={(e) => selectPostCode(e.target.value)}
                  value={facture.postCode ? `${facture.postCode}` : null}
                  className="rounded-md border border-gray-300 mb-2"
                  placeholder="Code postal"
                />
                <Input
                  onChange={(e) => selectTown(e.target.value)}
                  value={facture.town ? `${facture.town}` : null}
                  className="rounded-md border border-gray-300 mb-2"
                  placeholder="Ville"
                />
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('5', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('7', 'f')}
                className={`${
                  !facture.clientName ||
                  !facture.address ||
                  !facture.postCode ||
                  !facture.town
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={
                  !facture.clientName ||
                  !facture.address ||
                  !facture.postCode ||
                  !facture.town
                }
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '7' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">
                Decrivez les taches
              </p>

              <div>
                <Input
                  onChange={(e) => selectJustificatif(e.target.value)}
                  value={
                    facture.justificatif ? `${facture.justificatif}` : null
                  }
                  className="rounded-md border border-gray-300 mb-2 mt-6"
                  placeholder="Decrivez les taches"
                />
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('6', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('8', 'f')}
                className={`${
                  !facture.justificatif
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!facture.justificatif}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '8' && (
          <div className="flex flex-col h-full space-y-10 justify-between items-center">
            <p className="text-xl font-semibold">
              {facture.type === 'Vacation'
                ? 'Commentaire pour le service paie'
                : 'Modalités de facturation'}
            </p>
            <input
              placeholder={
                facture.type === 'Vacation'
                  ? 'Commentaire'
                  : " exemple : 50% le jour de l'embauche"
              }
              onChange={(e) => selectComment(e.target.value)}
              className="w-96 outline-none p-2 border border-gray-200 rounded-md"
            />

            <div className="flex flex-col items-center justify-center space-y-4">
              <p>Souhaitez vous dematerialiser le contrat ?</p>
              <div className="flex items-center space-x-2 ">
                <p className="flex items-center space-x-1">
                  <Checkbox />
                  <span>OUI</span>
                </p>
                <p className="flex items-center space-x-1">
                  <Checkbox
                  /* checked={contract.PenibiliteNC === 'N'}
                    onChange={() => selectPenibilite('N')} */
                  />
                  <span>NON</span>
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('7', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => generateFacture()}
                className="bg-mountain-500 text-white rounded-md p-4"
              >
                {facture.type === 'Vacation'
                  ? 'Créer contrat de vacation et la facture'
                  : 'Créer la facture'}
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default CreateFacture

import { useEffect, useState } from 'react'
import ClientContract from '../Client/ClientContract'
import CandidatList from '../Candidat/CandidatList/CandidatList'
import CandidatPay from '../Candidat/CandidatPay'
import ClientBill from '../Client/ClientBill'
import { Quotation } from '../Client/Quotation'
import ListClient from '../Client/ListClient'
import CandidatContract from '../Candidat/CandidatContratSection'
import RH from '../RH/RH'
import Commandes from '../Commandes/Commandes'
import Menu2 from '../Menu/Menu2'
import EspacePersonnel from '../EspacePersonnel/EspacePersonnel'
import DocumentPage from '../Documents/Documents'
import Facture from '../Facture/Facture'
import Dashboard from '../Dashboard/Dashboard'
import WorkCertificat from '../workCertificat/WorkCertificat'
import AttestationFiscale from '../AttestationFiscale/AttestationFiscale'
import ArretMaladie from '../ArretMaladie/ArretMaladie'
import AvailableCandidat from '../AvailableCandidat/AvailableCandidat'
import ControleReference from '../ControleReference/ControleReference'
import Planning from '../Planning/Planning'
import Acompte from '../Acompte/Acompte'
import UserCreate from '../UserCreate/UserCreate'
import Task from '../Task/Task'
import ContractClientPdf from '../Client/ContractClientPdf'
import CandidatPoleEmploi from '../Candidat/CandidatPoleEmploi'
import Menu3 from '../Menu/Menu3'
import WaitingDocument from '../WaitingDocument/WaitingDocument'
import Prolongation from '../Prolongation/Prolongation'
import SaisieArret from '../SaisieArret/SaisieArret'
import Atradius from '../Atradius/Atradius'
import DemandeContrat from '../DemandeContrat/DemandeContrat'
import Annonce from '../Annonce/Annonce'
import FreelanceDashboard from '../FreelanceDashboard/FreelanceDashboard'
const Agency = () => {
  const [username, setUsername] = useState('')
  const [section, setSection] = useState('dashboard')
  const [loadedComponent, setLoadedComponent] = useState(false)
  const [isDashboard, setIsDashboard] = useState(true)
  const [selected, setSelected] = useState('dashboard')
  const [state, setState] = useState('')

  const toggle = (section, state) => {
    setSection(section)
    setState(state)
    console.log(state)
    localStorage.setItem('section', section)
  }

  const toggleDashboard = (i) => {
    setIsDashboard(false)
    setTimeout(() => {
      setIsDashboard(true)
    }, 500)
    //setIsDashboard(true);
  }

  useEffect(() => {
    const username = localStorage.getItem('name')
    const section = localStorage.getItem('section')
    if (section) setSection(section)
    setUsername(username)
    setLoadedComponent(true)
  }, [])

  return (
    <div className="bg-gray-50 min-h-screen">
      {loadedComponent && (
        <div className="flex w-full pb-28 font-sf-pro">
          <Menu2
            toggle={toggle}
            toggleDashboard={toggleDashboard}
            selected={selected}
            setSelected={setSelected}
          />
          {/*  <Menu3 toggle={toggle} /> */}

          <div className="pt-20  grow ml-[300px] bg-[#F7F9FC] ">
            {section === 'searchCandidat' && (
              <div className="mx-4">
                <CandidatList />
              </div>
            )}

            {section === 'WaitingDocument' && (
              <div className="mx-4">
                <WaitingDocument />
              </div>
            )}

            {section === 'DemandeContrat' && (
              <div className="mx-4">
                <DemandeContrat />
              </div>
            )}

            {section === 'prolongations' && (
              <div className="mx-4">
                <Prolongation />
              </div>
            )}

            {section === 'atradius' && (
              <div className="mx-4">
                <Atradius />
              </div>
            )}

            {section === 'saisie' && (
              <div className="mx-4">
                <SaisieArret />
              </div>
            )}

            {section === 'dashboard' && isDashboard && (
              <div className="px-6">
                <Dashboard
                  username={username}
                  toggle={toggle}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            )}

            {section === 'candidatContract' && (
              <div className="px-6">
                <CandidatContract />
              </div>
            )}

            {section === 'clientContract' && (
              <div className="px-6">
                <ClientContract />
              </div>
            )}

            {section === 'pay' && (
              <div className="px-6">
                <CandidatPay />
              </div>
            )}

            {section === 'certificatTravail' && (
              <div className="px-6">
                <WorkCertificat />
              </div>
            )}

            {section === 'fiscal' && (
              <div className="px-6">
                <AttestationFiscale />
              </div>
            )}

            {section === 'maladie' && (
              <div className="px-6">
                <ArretMaladie />
              </div>
            )}

            {section === 'availableCandidat' && (
              <div className="px-6">
                <AvailableCandidat />
              </div>
            )}

            {section === 'controleReference' && (
              <div className="px-6">
                <ControleReference />
              </div>
            )}

            {section === 'facture' && (
              <div className="px-6">
                <ClientBill state={state} />
              </div>
            )}

            {section === 'facture-manuelle' && (
              <div className="px-6">
                <Facture />
              </div>
            )}

            {section === 'devis' && (
              <div className="px-6">
                <Quotation />
              </div>
            )}

{section === 'freelance' && (
              <div className="px-6">
                <FreelanceDashboard
                 username={username}
                 toggle={toggle}
                 selected={selected}
                 setSelected={setSelected} />
              </div>
            )}

            {section === 'planning' && (
              <div className="px-6 bg-[#F7F9FC] ">
                <Planning />
              </div>
            )}

            {section === 'annonces' && (
              <div className="px-6 bg-[#F7F9FC] ">
                <Annonce />
              </div>
            )}

            {section === 'acompte' && (
              <div className="px-6">
                <Acompte />
              </div>
            )}

            {section === 'searchClient' && (
              <div className="px-6">
                <ListClient />
              </div>
            )}

            {section === 'rh' && (
              <div className="px-6">
                <RH />
              </div>
            )}

            {section === 'order' && (
              <div className="px-6">
                <Commandes state={state} />
              </div>
            )}

            {section === 'utilisateurs' && (
              <div className="px-6">
                <UserCreate />
              </div>
            )}

            {section === 'taches' && (
              <div className="px-6">
                <Task />
              </div>
            )}

            {section === 'documents' && (
              <div className="px-6">
                <DocumentPage />
              </div>
            )}

            {section === 'perso' && (
              <div className="px-6">
                <EspacePersonnel />
              </div>
            )}

            {section === 'ctt' && (
              <div className="px-6">
                <ContractClientPdf />
              </div>
            )}

            {section === 'attestation' && (
              <div className="px-6">
                <CandidatPoleEmploi />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Agency

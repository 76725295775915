import { Routes, Route } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import HomePage from './pages/HomePage'
import { Helmet } from 'react-helmet'
import ProfilPage from './pages/ProfilPage'
import InactivityLogout from './components/utils/InactivityLogout'
import Activate from './pages/Activate'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Formulaire from './pages/Formulaire'
import ClientDetailPage from './components/Client/ClientDetailModal/ClientDetailPage'
export default function App() {
  return (
    <div>
      <InactivityLogout />
      <Helmet>
        <title>J'M INTERIM</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/profil" element={<ProfilPage />} />
        <Route path="/activate/:token" element={<Activate />} />
        <Route path="/mot-de-passe-oublie" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/fiche-renseignement" element={<Formulaire />} />
        <Route path="/client" element={<ClientDetailPage />} />
      </Routes>
    </div>
  )
}

import { Popover, Button, Modal } from 'antd'
import { useState, useRef } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

const ExpiredDocument = ({ documents }) => {
  const [isExpired, setIsExpired] = useState(false)
  const [update, setUpdate] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const inputFileRef = useRef(null)

  const handleClick = () => {
    inputFileRef.current.click()
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  return (
    <div>
      {update && (
        <Modal
          open={update}
          footer={null}
          onCancel={() => setUpdate(false)}
          width={600}
        >
          <div className="flex items-center justify-center flex-col">
            <div className="flex flex-col items-center justify-center space-y-4">
              <div
                onClick={handleClick}
                className="border w-96 border-[#00000040]  text-[#00000040] rounded-md flex flex-col items-center justify-center mt-6 py-6"
              >
                <CloudUploadIcon />
                <p className="mt-2">Ajouter document</p>
                <p className="text-black">{selectedFile?.name}</p>
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  className="hidden"
                  ref={inputFileRef}
                />
              </div>

              <button className="bg-purple-500 rounded-md p-2">
                Mettre à jour
              </button>
            </div>
          </div>
        </Modal>
      )}
      <div className="mt-10 mb-6 flex py-2 border border-gray-300 px-2 bg-[#F7F9FC] space-x-2 rounded-md items-center justify-between">
        <div
          onClick={() => setIsExpired(false)}
          className={`${
            !isExpired ? 'bg-white border border-gray-200' : ''
          } py-4  w-1/2 hover:cursor-pointer rounded-xl`}
        >
          <p className="text-center font-bold">Dans les 30 jours</p>
        </div>

        <div
          onClick={() => setIsExpired(true)}
          className={`${
            isExpired ? 'bg-white border border-gray-200' : ''
          } py-4 w-1/2 hover:cursor-pointer  rounded-xl`}
        >
          <p className="text-center font-bold">Perimés</p>
        </div>
      </div>

      {/*  <div>
            Recuperez tout les documents dont la date est expiré ou vont expire dans 30 jours et dont le 
            detenteur a eu au contrat au maximum trois mois avant.

            trie par la date de peremption de la plus recente a la plus ancienne 
        </div> */}

      <div className="flex items-center mt-6">
        <p className="w-1/5 text-center uppercase  font-semibold">Nom</p>
        <p className="w-1/5 text-center uppercase  font-semibold">
          Type de document
        </p>
        <p className="w-1/5 text-center uppercase  font-semibold">Dates</p>
        <p className="w-1/5 text-center uppercase  font-semibold">
          Commentaire
        </p>
        <p className="w-1/5 text-center uppercase  font-semibold">Action</p>
      </div>

      {documents.map((document, idx) => {
        return (
          <div key={idx} className="flex items-center mt-6">
            <p className="w-1/5 text-center ">
              {document.candidate.firstname} {document.candidate.lastname}
            </p>
            <p className="w-1/5 text-center ">{document.type}</p>
            <p className="w-1/5 text-center ">
              {formatJsDate(document.expirationDate)}
            </p>
            <p className="w-1/5 text-center ">{document.commentaire}</p>
            <p className="w-1/5 text-center ">
              <Popover
                placement="bottom"
                content={
                  <div className="flex flex-col  text-base space-y-3">
                    <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                      <span>Voir le document</span>
                    </p>
                    <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                      <span>Relancer</span>
                    </p>
                    <p
                      onClick={() => setUpdate(true)}
                      className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                    >
                      <span>Mettre à jour</span>
                    </p>
                  </div>
                }
                trigger="click"
              >
                <Button>...</Button>
              </Popover>
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default ExpiredDocument

const recours = {
  10: 'Formation',
  11: "Remplacement d'un salarié en cas d'absence ou de suspension de son contrat de travail",
  12: `Remplacement d'un salarié en cas de passage provisoire à temps partiel, conclu par avenant à son contrat de travail ou par échange écrit entre le salarié et l'employeur`,
  14: `Remplacement d'un salarié en cas de l'entrée effective du salarié recruté par un contrat à durée indéterminée appelé à le remplacer`,
  21: `Conformément aux dispositions légales faciliter l'embauche des personnes sans emploi ayant des difficultés sociales professionnelles particulières`,
  22: `L'ETT et l'E.U. s'engagent, pour une durée, dans les conditions légales à assurer un complément de formation professionnelle au salarié`,
  23: `Remplac d'un associé non salarié d'une Ste civile professionnelle d'une Ste civile de moyens ou d'une Ste d'exercice libéral`,
  24: `Remplac d'un chef d'ent. artisanale indus. commerciale, d'une profession libérale, de son conjoint participant réellement à l'activité à titre professionnel et habituel`,
  25: `Remplac. d'un chef d'exploit. agricole ou d'une ent. mentionnée aux 1° à 4° de l'art. L.722-1 du code rural, participant réellement à l'activité de l'ent., de l'exploit. agricole`,
  26: `Remplac d'un aide familial, associé d'exploit. ou leur conjoint mentionné à l'art. L.722-10 du code rural, participant réellement à l'activité de l'ent., de l'exploit. agricole`,
  31: `Remplacement d'un salarié en cas de départ définitif précédant la suppression de son poste de travail`,
  42: `Accroissement temporaire d'activité lié à une variation cyclique d'activité`,
  52: `Accroissement temporaire d'activité lié à une tâche occasionnelle et non durable`,
  62: `Accroissement temporaire d'activité lié à une commande exceptionnelle à l'exportation`,
  72: `Accroissement temporaire d'activité lié à des travaux dont l'exécution immédiate est nécessitée par des mesures de sécurité`,
  83: `Emploi temporaire par nature défini comme emploi à caractère saisonnier`,
  93: `Emploi temporaire par nature défini comme emploi d'usage constant`,
  HANDICP: `Bénéficiaire de l'obligation d'emploi de l'article L. 5212-13 CT`,
}

export default recours

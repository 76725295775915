import { Input, Modal, Steps, message } from 'antd'
import { useEffect, useState } from 'react'
import axiosInstance from '../../../axios/axiosInstance'
import CreateContractCandidate from '../CreateContract/CreateContractCandidate'
import CreateContractMotif from '../CreateContract/CreateContractMotif'
import CreateContractDate from '../CreateContract/CreateContractDate'
import CreateContractLieu from '../CreateContract/CreateContractLieu'
import CreateContractPoste from '../CreateContract/CreateContractPoste'
import CreateContractTask from '../CreateContract/CreateContractTask'
import CreateContractConsigne from '../CreateContract/CreateContractConsigne'
import CreateContractPenibilite from '../CreateContract/CreateContractPenibilite'
import CreateContractContact from '../CreateContract/CreateContractContact'
import CreateContractReference from '../CreateContract/CreateContractReference'
import CreateContractPeriodeEssai from '../CreateContract/CreateContractPeriodeEssai'
import CreateContractDateDuplicate from '../CreateContract/CreateContractDateDuplicate'
import CreateContractPrime from '../CreateContract/CreateContractPrime'
import CreateContractPrimeDuplicate from '../CreateContract/CreateContractPrimeDuplicate'

const DuplicateContract = ({
  client,
  selectedContract,
  primes,
  globalPrimes,
  qualifications,
  chantiers,
  isDuplicateContract,
  setIsDuplicateContract,
}) => {
  const [current, setCurrent] = useState(0)
  const [contract, setContract] = useState({})
  const [isCreating, setIsCreating] = useState(false)
  const [interlocuteur,setInterlocuteur] = useState("");

  const next = () => {
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }

  const consignes = [
    '0 : Aucune',
    "1 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire.",
    "2 : La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
    "3 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
  ]

  const usedMotif = [
    "42 : Accroissement temporaire d'activité lié à une variation cyclique d'activité",
    "52 : Accroissement temporaire d'activité lié à une tâche occasionnelle et non durable",
    "11 : Remplacement d'un salarié en cas d'absence ou de suspension de son contrat de travail",
    '83 : Emploi temporaire par nature défini comme emploi à caractère saisonnier',
    "93 : Emploi temporaire par nature défini comme emploi d'usage constant",
  ]

  const otherMotif = [
    '10 : Formation',
    "72 : Accroissement temporaire d'activité lié à des travaux dont  l'exécution immédiate est nécessitée par des mesures de sécurité",
    "12 : Remplacement d'un salarié en cas de passage provisoire à temps partiel, conclu par avenant à son contrat de travail ou par échange écrit entre le salarié et l'employeur",
    "62 : Accroissement temporaire d'activité lié à une commande exceptionnelle à l'exportation",
    "21 : Conformément aux dispositions légales faciliter l'embauche des personnes sans emploi ayant des difficultés sociales professionnelles particulières",
    "14 : Remplacement d'un salarié en cas de l'entrée effective du salarié recruté par un contrat à durée indéterminée appelé à le remplacer",
    "23 : Remplacement d'un associé non salarié d'une société civile professionnelle d'une société civile de moyens ou d'une société d'exercice libéral",
    "25 : Remplacement d'un chef d'exploitation agricole ou d'une entreprise mentionnée aux 1° à 4° de l'art. L.722-1 du code rural, participant réellement à l'activité de l'entreprise, de l'exploitation agricole",
    "24 : Remplacement d'un chef d'entreprise artisanale industriel, commerciale, d'une profession libérale, de son conjoint participant réellement à l'activité à titre professionnel et habituel",
    "31 : Remplacement d'un salarié en cas de départ définitif  précédant la suppression de son poste de travail",
    "22 : L'ETT et l'E.U. s'engagent, pour une durée, dans les  conditions légales à assurer un complément de formation professionnelle au salarié",
    "HANDICP : Bénéficiaire de l'obligation d'emploi de l'article L. 5212-13 CT",
    "26 : Remplacement d'un aide familial, associé d'exploit. ou leur conjoint mentionné  à l'art. L.722-10 du code rural, participant réellement à l'activité de l'entreprise, de l'exploitation agricole",
  ]

  const usedMotifArray = usedMotif.map((motif, idx) => {
    return { value: motif, label: motif, key: idx }
  })
  const otherMotifArray = otherMotif.map((motif, idx) => {
    return { value: motif, label: motif, key: idx }
  })

  const createDateFromString = (dateString) => {
    let year = parseInt(dateString.substring(0, 4))
    let month = parseInt(dateString.substring(4, 6)) - 1 // Month is 0-based in JavaScript Date
    let day = parseInt(dateString.substring(6, 8))

    let date = new Date(year, month, day)
    console.log(date)
    return date
  }

  const createHourFromString = (timeString) => {
    let hour = parseInt(timeString.substring(0, 2))
    let minute = parseInt(timeString.substring(2, 4))
    let date = new Date()
    date.setHours(hour)
    date.setMinutes(minute)
    date.setSeconds(0)
    return date
  }

  useEffect(() => {
    console.log(client)
    console.log(selectedContract)
    setInterlocuteur(selectedContract.Interlocuteur);
    for (let motif of usedMotif) {
      if (motif.includes(selectedContract.IdRecours)) {
        setContract({
          clientPld: client.pld,
          justificatif: selectedContract.TxtJustificatif,
          startDate: createDateFromString(selectedContract.DateDebut),
          endDate: createDateFromString(selectedContract.DateFin),
          startHour: createHourFromString(selectedContract.HoraireAMDebut),
          endHour: createHourFromString(selectedContract.HorairePMFin),
          nbHeureHebdo: selectedContract.NbHeureHebdo,
          clientName: selectedContract.AdresseRue,
          address: selectedContract.AdresseSuite,
          postCode: selectedContract.AdresseCodePostal,
          town: selectedContract.AdresseVille,
          isEditTauxHoraire: false,
          clientQualification: qualifications.find(
            (qual) => qual.IdQualification === selectedContract.IdQualification,
          ),
          task: selectedContract.TxtCaracteristiquePoste,
          PeriodeNonTrav: selectedContract.PeriodeNonTrav,
          Horaires: selectedContract.Horaires,
          Reference1: selectedContract.Reference1,
          TabPrimes:selectedContract.TabPrimes,
          Reference2: selectedContract.Reference2,
          TxtConsigneQualification:selectedContract.TxtConsigneQualification,
          NbJourEssai: selectedContract.NbJoursEssai,
          Interlocuteur:selectedContract.Interlocuteur,
          PenibiliteNC: selectedContract.PenibiliteNC,
          EstChezClient: selectedContract.EstChezClient,
          EstBTP: selectedContract.EstBTP,
          EstSansTaxeTransport: selectedContract.EstSansTaxeTransport,
          consigne: consignes.find((cons) =>
            cons.includes(selectedContract.TxtConsignePoste),
          ),
          motif,
        })
      }
    }
    for (let motif of otherMotif) {
      if (motif.includes(selectedContract.TxtRecours)) {
        setContract({
          clientPld: client.pld,
          justificatif: selectedContract.TxtJustificatif,
          TxtConsigneQualification:selectedContract.TxtConsigneQualification,
          startDate: createDateFromString(selectedContract.DateDebut),
          endDate: createDateFromString(selectedContract.DateFin),
          startHour: createHourFromString(selectedContract.HoraireAMDebut),
          endHour: createHourFromString(selectedContract.HorairePMFin),
          nbHeureHebdo: selectedContract.NbHeureHebdo,
          clientName: selectedContract.AdresseRue,
          address: selectedContract.AdresseSuite,
          Interlocuteur:selectedContract.Interlocuteur,
          postCode: selectedContract.AdresseCodePostal,
          TabPrimes:selectedContract.TabPrimes,
          town: selectedContract.AdresseVille,
          isEditTauxHoraire: false,
          clientQualification: qualifications.find(
            (qual) => qual.IdQualification === selectedContract.IdQualification,
          ),
          consigne: selectedContract.TxtCaracteristiquePoste,
          PeriodeNonTrav: selectedContract.PeriodeNonTrav,
          Horaires: selectedContract.Horaires,
          Reference1: selectedContract.Reference1,
          Reference2: selectedContract.Reference2,
          NbJourEssai: selectedContract.NbJoursEssai,
          PenibiliteNC: selectedContract.PenibiliteNC,
          EstEnChantier: selectedContract.EstEnChantier,
          EstSansTaxeTransport: selectedContract.EstSansTaxeTransport,
          EstBtp: selectedContract.EstBtp,
          motif,
        })
      }
    }
  }, [])

  const formatJsHour = (date) => {
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0') // Months are 0-based, so we add 1

    return `${hours}${minutes}`
  }

  const formatJsDateTempo = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${year}${month}${day}`
  }

  const validateContract = async () => {
    try {
      setIsCreating(true)

      contract.startHourString = formatJsHour(contract.startHour)
      contract.endHourString = formatJsHour(contract.endHour)
      contract.startDateString = formatJsDateTempo(contract.startDate)
      contract.endDateString = formatJsDateTempo(contract.endDate)

      const res = await axiosInstance.post('pld-contract', contract)

      const { error } = res.data
      if (!error) {
        message.success('Contrat créé !')
        setIsDuplicateContract(false)
        return
      }
      message.error(error.message)
    } catch (e) {
      console.error(e)
    } finally {
      setIsCreating(false)
    }
  }

  const steps = [
    {
      title: 'Contact',
      content: (
        <CreateContractContact
          client={client}
          contract={contract}
          interlocuteur={interlocuteur}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Candidat',
      content: (
        <CreateContractCandidate
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Motif',
      content: (
        <CreateContractMotif contract={contract} setContract={setContract} />
      ),
    },
    {
      title: 'Dates de la mission',
      content: (
        <CreateContractDateDuplicate
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Lieu de la misson',
      content: (
        <CreateContractLieu
          client={client}
          chantiers={chantiers}
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Poste',
      content: (
        <CreateContractPoste
          qualifications={qualifications}
          primes={primes}
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Prime',
      content: (
        <CreateContractPrimeDuplicate
          primes={primes}
          globalPrimes={globalPrimes}
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Consignes particulières',
      content: (
        <CreateContractConsigne contract={contract} setContract={setContract} />
      ),
    },
    {
      title: 'Tâches / missions',
      content: (
        <CreateContractTask contract={contract} setContract={setContract} />
      ),
    },
    {
      title: 'Reference / Horaires particuliers',
      content: (
        <CreateContractReference
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: "Période d'essai",
      content: (
        <CreateContractPeriodeEssai
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Pénibilités',
      content: (
        <CreateContractPenibilite
          contract={contract}
          setContract={setContract}
        />
      ),
    },
  ]

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }))

  return (
    <Modal
      footer={null}
      width={1000}
      open={isDuplicateContract}
      className="h-[700px]"
      onCancel={() => setIsDuplicateContract(false)}
    >
      <div className="flex items-start mt-10 space-x-4">
        <Steps
          direction="vertical"
          className="w-2/6"
          current={current}
          items={items}
        />
        <div className="w-4/6 h-[700px] flex flex-col justify-between">
          <div>{steps[current].content}</div>
          <div className="flex items-center space-x-2 justify-end ">
            {current > 0 && (
              <button
                className="bg-white text-black p-2 rounded-md border border-gray-300"
                onClick={() => prev()}
              >
                Precedent
              </button>
            )}

            {current === steps.length - 1 && (
              <button
                className="bg-[#6022ea] p-2 rounded-md text-white"
                onClick={() => validateContract()}
              >
                {!isCreating && <span>Valider le contrat</span>}
                {isCreating && <span>...Creation</span>}
              </button>
            )}
            {current < steps.length - 1 && (
              <button
                className="bg-[#6022ea] p-2 rounded-md text-white"
                onClick={() => next()}
                type="primary"
              >
                Suivant
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DuplicateContract

import { useEffect, useState } from 'react'
import axios from '../../axios/axiosInstance'
import {
  Button,
  Modal,
  DatePicker,
  Input,
  Popover,
  Select,
  message,
} from 'antd'
import { Document, Page } from 'react-pdf'
import { documentsList } from '../Candidat/data/documentsList'
import { habilitationsList } from '../Candidat/data/habilitationsList'
import { useNavigate } from 'react-router-dom'
import { Puff } from 'react-loader-spinner'
import ExpiredDocument from './ExpiredDocument'

const WaitingDocument = () => {
  const [documents, setDocuments] = useState([])
  const [documentType, setDocumentType] = useState('')
  const [documentData, setDocumentData] = useState('')
  const [showDocument, setShowDocument] = useState(false)
  const [isValidateModal, setIsValidateModal] = useState(false)
  const [type, setType] = useState('')
  const [numPages, setNumPages] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [deliveryDate, setDeliveryDate] = useState(null)
  const [expirationDate, setExpirationDate] = useState(null)
  const [accidentDate, setAccidentDate] = useState(null)
  const [cardNumber, setCardNumber] = useState(null)
  const [agreementAskDate, setAgreementAskDate] = useState(null)
  const [agreementAmount, setAgreementAmount] = useState(null)
  const [deliveryCountry, setDeliveryCountry] = useState(null)
  const [inspectionDate, setInspectionDate] = useState(null)
  const [inspectedNumber, setInspectedNumber] = useState(null)
  const [subType, setSubType] = useState(null)
  const [payRollDate, setPayrollDate] = useState(null)
  const [vaccinType, setVaccinType] = useState(null)
  const [level, setLevel] = useState(null)
  const [hourlyRate, setHourlyRate] = useState(null)
  const [habilitations, setHabilitations] = useState('')
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isExpired, setIsExpired] = useState(true)
  const [expireIn30, setExpireIn30] = useState([])
  const [expiredDocuments, setExpiredDocuments] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    loadDocuments()
    loadExpired()
    /* axios
      .post('reload-contract/lll', { date: new Date() })
      .then((res) => console.log(res))
      .catch((e) => console.log(e)) */
  }, [])

  const habilitationsData = habilitationsList.map((habilitation) => {
    return {
      label: habilitation,
      value: habilitation,
    }
  })

  const loadExpired = () => {
    axios
      .get('document/expired')
      .then((res) => {
        console.log(res)
        console.log('okokok')
        setExpiredDocuments(res.data)
      })
      .catch((e) => console.log(e))
  }

  const updateDocument = (documentId) => {
    setLoading(true)
    const formData = { type }

    const fields = {
      endDate,
      startDate,
      accidentDate,
      deliveryDate,
      expirationDate,
      cardNumber,
      agreementAskDate,
      agreementAmount,
      deliveryCountry,
      inspectionDate,
      inspectedNumber,
      subType,
      payRollDate,
      vaccinType,
      level,
      hourlyRate,
    }

    Object.entries(fields).forEach(([fieldName, fieldValue]) => {
      if (fieldValue) {
        formData[fieldName] = fieldValue
      }
    })

    axios
      .patch(`document/${documentId}`, formData)
      .then((res) => {
        console.log(res.data)
        setShowDocument(false)
        setType('')
        const docIndex = documents.findIndex((doc) => doc._id === documentId)
        const newDocuments = [...documents]
        newDocuments.splice(docIndex, 1)
        setDocuments(newDocuments)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deleteDocument = (documentId) => {
    axios
      .delete(`document/${documentId}`)
      .then((res) => {
        setShowDocument(false)
        setType('')
        const docIndex = documents.findIndex((doc) => doc._id === documentId)
        const newDocuments = [...documents]
        newDocuments.splice(docIndex, 1)
        setDocuments(newDocuments)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1)
    setNumPages(newArray)
  }

  const getDocument = (documentId) => {
    setLoading(true)
    axios
      .get(`/document/${documentId}`)
      .then((res) => {
        setDocumentType(res.data.name.split('.')[1])
        setDocumentData(res.data.data)
        setSelectedDocument(documentId)
        setShowDocument(true)
      })
      .catch((e) => message.error("Une erreur s'est produite"))
      .finally(() => {
        setLoading(false)
      })
  }

  /*   const getDocument = (documentId) => {
        axios.get(`document/${documentId}`)
        .then((res)=>{
            const link = document.createElement('a')
            let documentType = res.data.name.split('.')[1]
        if (documentType === 'pdf') {
          link.href = `data:application/pdf;base64,${res.data.data}`
          link.download = `${res.data.name}`
        } else {
          link.href = `data:image/png;base64,${res.data.data}`
          link.download = `${res.data.name}`
        }

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        })
        .catch((e)=> {
            console.log(e);
        })
    }
 */
  const loadDocuments = () => {
    axios
      .get('document/waiting')
      .then((res) => {
        setDocuments(res.data)
        console.log(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const documentArray = documentsList.map((document) => {
    return {
      value: document,
      label: document,
    }
  })

  return (
    <div>
      <div className="mt-10 mb-6 flex py-2 border border-gray-300 px-2 bg-[#F7F9FC] space-x-2 rounded-md items-center justify-between">
        <div
          onClick={() => setIsExpired(true)}
          className={`${
            isExpired ? 'bg-white border border-gray-200' : ''
          } py-4  w-1/2 hover:cursor-pointer rounded-xl`}
        >
          <p className="text-center font-bold">Documents perimés</p>
        </div>

        <div
          onClick={() => setIsExpired(false)}
          className={`${
            !isExpired ? 'bg-white border border-gray-200' : ''
          } py-4 w-1/2 hover:cursor-pointer  rounded-xl`}
        >
          <p className="text-center font-bold">Validation documents</p>
        </div>
      </div>

      {loading && (
        <div className="w-full h-full absolute top-0 z-30 right-0 bg-gray-400 bg-opacity-40 flex items-center justify-center">
          <Puff
            height="80"
            width="80"
            radius={1}
            color="#4fa94d"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loading}
          />
        </div>
      )}
      {showDocument && (
        <Modal
          bodyStyle={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          footer={[
            <Button key="back" onClick={() => setShowDocument(false)}>
              fermer
            </Button>,
          ]}
          width={1000}
          title="Document"
          open={showDocument}
          onCancel={() => setShowDocument(false)}
        >
          <div>
            {loading && (
              <div className="w-full h-full absolute top-0 z-30 right-0 bg-gray-400 bg-opacity-40 flex items-center justify-center">
                <Puff
                  height="80"
                  width="80"
                  radius={1}
                  color="#4fa94d"
                  ariaLabel="puff-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loading}
                />
              </div>
            )}
            {documentType === 'pdf' && (
              <Document
                file={`data:application/pdf;base64,${documentData}`}
                onLoadSuccess={handleDocumentLoadSuccess}
              >
                {numPages.map((x, idx) => {
                  return <Page className="w-64" pageNumber={idx + 1} />
                })}
              </Document>
            )}

            {documentType !== 'pdf' && (
              <img
                className="w-64 mx-auto"
                src={`data:image/png;base64,${documentData}`}
              />
            )}

            <div>
              <div className="my-6 flex items-center justify-center">
                <Select
                  className="w-96"
                  showSearch
                  onChange={(e) => setType(e)}
                  optionFilterProp="children"
                  placeholder="Selectionner type de document"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={documentArray}
                />
              </div>

              {type === "Carte d'identité" && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    size="large"
                    onChange={(date, dateString) => setDeliveryDate(date.$d)}
                    placeholder="Date de delivrance"
                  />
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    size="large"
                    onChange={(date, dateString) => setExpirationDate(date.$d)}
                    placeholder="Date d'expiration"
                  />
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    placeholder="Numero de la carte"
                  />
                </div>
              )}

              {type === 'Accident de travail' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    size="large"
                    onChange={(date, dateString) => setAccidentDate(date.$d)}
                    placeholder="Date de l'accident"
                  />
                </div>
              )}

              {type === 'Accident de trajet' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    size="large"
                    onChange={(date, dateString) => setAccidentDate(date.$d)}
                    placeholder="Date de l'accident"
                  />
                </div>
              )}

              {type === 'Arret maladie' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    size="large"
                    onChange={(date, dateString) => setStartDate(date.$d)}
                    placeholder="Date de début"
                  />
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    size="large"
                    onChange={(date, dateString) => setEndDate(date.$d)}
                    placeholder="Date de fin"
                  />
                </div>
              )}

              {type === 'Atradius' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) =>
                      setAgreementAskDate(date.$d)
                    }
                    placeholder="Date de la demande"
                  />
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setAgreementAmount(e.target.value)}
                    placeholder="Montant de l'accord"
                  />
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setEndDate(date.$d)}
                    placeholder="Date de fin de l'accord"
                  />
                </div>
              )}

              {type === 'Autorisation provisoire de séjour' && (
                <div className="mt-4 flex flex-col">
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setDeliveryDate(date.$d)}
                    placeholder="Date de delivrance"
                  />
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setExpirationDate(date.$d)}
                    placeholder="Date d'expiration"
                  />
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setCardNumber(e.target.value)}
                    placeholder="Numero de la carte"
                  />
                </div>
              )}

              {type === 'Habilitation' && (
                <div className="mt-4 flex flex-col">
                  <Select
                    className="w-96"
                    showSearch
                    onChange={(e) => setHabilitations(e)}
                    optionFilterProp="children"
                    placeholder="Selectionner type d'habilitation"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={habilitationsData}
                  />
                </div>
              )}

              {type === 'Carte de séjour' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => {
                      setDeliveryDate(date.$d)
                    }}
                    placeholder="Date de delivrance"
                  />
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setExpirationDate(date.$d)}
                    placeholder="Date d'expiration"
                  />
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setCardNumber(e.target.value)}
                    placeholder="Numero de la carte"
                  />
                </div>
              )}

              {type === 'Carte de résident' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setDeliveryDate(date.$d)}
                    placeholder="Date de delivrance"
                  />
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setExpirationDate(date.$d)}
                    placeholder="Date d'expiration"
                  />
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setCardNumber(e.target.value)}
                    placeholder="Numero de la carte"
                  />
                </div>
              )}

              {type === 'CACES' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setDeliveryDate(date.$d)}
                    placeholder="Date de delivrance"
                  />
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setExpirationDate(date.$d)}
                    placeholder="Date d'expiration"
                  />
                </div>
              )}

              {type === 'Carte européenne' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setDeliveryDate(date.$d)}
                    placeholder="Date de delivrance"
                  />
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2 "
                    onChange={(date, dateString) => setExpirationDate(date.$d)}
                    placeholder="Date d'expiration"
                  />
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setCardNumber(e.target.value)}
                    placeholder="Numero de la carte"
                  />
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setDeliveryCountry(e.target.value)}
                    placeholder="Pays de délivrance"
                  />
                </div>
              )}

              {type === 'Contrôle préfecture' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setInspectionDate(date.$d)}
                    placeholder="Date du controle"
                  />
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setInspectedNumber(e.target.value)}
                    placeholder="Numero de la piece controlée"
                  />
                </div>
              )}

              {type === 'Document client' && (
                <div className="mt-4 flex flex-col">
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setSubType(e.target.value)}
                    placeholder="Type de document"
                  />
                </div>
              )}

              {type === 'Fiche de paie' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setPayrollDate(date.$d)}
                    placeholder="MM/YYYY"
                  />
                </div>
              )}
              {type === 'Vaccination' && (
                <div className="mt-4 flex flex-col">
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setVaccinType(e.target.value)}
                    placeholder="Type de vaccin"
                  />
                </div>
              )}

              {type === 'Travailleur handicapé ( RQTH )' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setStartDate(date.$d)}
                    placeholder="Date de début"
                  />
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setEndDate(date.$d)}
                    placeholder="Date de fin"
                  />
                </div>
              )}

              {type === 'Autre' && (
                <div className="mt-4 flex flex-col">
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setSubType(e.target.value)}
                    placeholder="Type de document"
                  />
                </div>
              )}

              {type === 'Fiche de paie (controle taux et niveaux)' && (
                <div className="mt-4 flex flex-col">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(date, dateString) => setPayrollDate(date.$d)}
                    placeholder="Date de la fiche de paie"
                  />
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setLevel(e.target.value)}
                    placeholder="Niveau"
                  />
                  <Input
                    className="rounded-md border border-gray-300 mb-2"
                    onChange={(e) => setHourlyRate(e.target.value)}
                    placeholder="Taux horaire"
                  />
                </div>
              )}
            </div>

            <div className="flex items-center space-x-2">
              <p
                onClick={() => updateDocument(selectedDocument)}
                className="w-80 p-2 text-center rounded-md text-white mt-6 bg-green-500 border border-green-500"
              >
                Valider
              </p>
              <p
                onClick={() => deleteDocument(selectedDocument)}
                className="w-80 p-2 text-center rounded-md text-white mt-6 bg-red-500 border border-red-500"
              >
                Refuser
              </p>
            </div>
          </div>
        </Modal>
      )}

      {isValidateModal && (
        <Modal
          width={1000}
          open={isValidateModal}
          footer={null}
          onCancel={() => setIsValidateModal(false)}
        >
          <div className="h-[600px]"></div>
        </Modal>
      )}

      {!isExpired && (
        <div>
          <p className="mt-6 font-semibold text-xl">Documents à verifier</p>

          <div>
            <div className="flex grow  items-center py-4 border-b border-b-gray-300 justify-between px-8">
              <p className="w-1/6 text-center">CANDIDAT</p>
              <p className="w-1/6 text-center">FICHIER</p>
              <p className="w-1/6 text-center">C</p>
              <p className="w-1/6 text-center">D</p>
              <p className="w-1/6 text-center">L</p>
              <p className="w-1/6 text-center">ACTION</p>
            </div>

            <div className="flex flex-col font-medium">
              {documents.map((document, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex items-center font-semibold bg-white border-b border-b-gray-200  py-6 hover:cursor-pointer"
                  >
                    <p className="w-1/6 text-center">
                      {document.candidate?.firstname}{' '}
                      {document.candidate?.lastname}
                    </p>
                    <p className="w-1/6 text-center">{document.name}</p>
                    <p className="w-1/6 text-center"></p>
                    <p className="w-1/6 text-center"></p>
                    <p className="w-1/6 text-center"></p>
                    <p className="w-1/6 text-center">
                      <Popover
                        placement="bottom"
                        content={
                          <div className="flex flex-col capitalize text-base space-y-3">
                            <p
                              onClick={() => getDocument(document._id)}
                              className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                            >
                              <span>Visualiser</span>
                            </p>
                          </div>
                        }
                      >
                        <Button>...</Button>
                      </Popover>
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}

      {isExpired && <ExpiredDocument documents={expiredDocuments} />}
    </div>
  )
}

export default WaitingDocument

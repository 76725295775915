import { Modal, message, Select } from 'antd'
import axios from '../../../axios/axiosInstance'
import { useEffect, useState } from 'react'

const EnvoiFreelance = ({
  candidate,
  isEnvoiFreelance,
  setIsEnvoiFreelance,
}) => {
  const [freelances, setFreelances] = useState([])
  const [selectedFreelance, setSelectedFreelance] = useState(null)

  const sendToFreelance = () => {
    axios
      .put(
        `tempworker-candidate/freelance/send-to-freelance/${candidate._id}/${selectedFreelance}`,
      )
      .then((res) => setIsEnvoiFreelance(false))
      .catch((e) => console.log(e))
  }

  const loadFreelances = () => {
    axios
      .get('user/freelance')
      .then((res) => {
        setFreelances(res.data)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    loadFreelances()
  }, [])

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const formatDate = (date) => {
    const year = date.slice(0, 4)
    const month = date.slice(4, 6)
    const day = date.slice(6, 8)

    // Format the date as DD/MM/YYYY
    const formattedDate = `${day}/${month}/${year}`

    return formattedDate
  }

  return (
    <Modal
      open={isEnvoiFreelance}
      footer={null}
      onCancel={() => setIsEnvoiFreelance(false)}
    >
      <div className="mt-10 flex flex-col items-center justify-center space-y-4">
        <p className="font-semibold text-lg">Selectionnez le freelance</p>
        <Select
          className={`w-4/5 mx-auto  font-light rounded-md border`}
          optionFilterProp="children"
          onChange={(e) => {
            setSelectedFreelance(e)
          }}
          value={selectedFreelance}
          placeholder="Freelance"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={freelances.map((freelance) => {
            return {
              value: freelance._id,
              label: freelance.firstname + ' ' + freelance.lastname,
              key: freelance._id,
            }
          })}
        />

        <button
          onClick={sendToFreelance}
          className="p-2 rounded-md bg-purple-400"
        >
          Envoyer le candidat{' '}
        </button>
      </div>
    </Modal>
  )
}

export default EnvoiFreelance

import { Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useEffect, useState } from 'react'
import axios from '../../../axios/axiosInstance'

const CreateContractConsigne = ({ contract, setContract }) => {
  const [consignes, setConsignes] = useState([])

  useEffect(() => {
    axios
      .get('pld-contract/consigne')
      .then((res) => {
        const consigneData = res.data
        const loadedConsignes = consigneData.map((cons) => {
          return {
            value: `${cons.IdReferentiel} : ${cons.Designation}`,
            label: `${cons.IdReferentiel} : ${cons.Designation}`,
            key: cons.IdReferentiel,
          }
        })
        setConsignes(loadedConsignes)
      })
      .catch((e) => console.log(e))
  }, [])

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="font-semibold text-center text-3xl">
          Consignes particulières
        </p>

        <div className="flex items-center my-6">
          <Select
            className="w-full py-6 my-2"
            showSearch
            optionFilterProp="children"
            onChange={(e) => setContract({ ...contract, consigne: e })}
            value={contract.consigne ? contract.consigne : null}
            placeholder="Consignes"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={consignes}
          />
        </div>

        {/*   <div>
          <p className='text-lg mb-4'>Consignes Facturation</p>
          <TextArea onChange={(e) => setContract({ ...contract, TexteConsigneFacturation: e.target.value })}className='border border-gray-300 rounded-md h-60' placeholder='Consigne Facturation' />
        </div> */}
      </div>
    </div>
  )
}

export default CreateContractConsigne

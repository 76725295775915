import axios from '../../axios/axiosInstance'
import { useEffect, useState, useRef } from 'react'
import avatar from '../../assets/img/avatar.jpg'
import { Document, Page } from 'react-pdf'

const Annonce = () => {
  const [annonces, setAnnonces] = useState([])
  const [showCandidats, setShowCandidats] = useState('')
  const [candidates, setCandidates] = useState([])
  const [archive, setArchive] = useState(false)
  const [cvData, setCvData] = useState('')
  const [isModalCV, setIsModalCV] = useState(false)
  const [numPages, setNumPages] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const fileInputRef = useRef(null)

  const handleButtonClick = (e) => {
    e.preventDefault()
    console.log(fileInputRef)
    fileInputRef.current.click() // Programmatically trigger the file input
  }

  useEffect(() => {
    loadAnnonce()
  }, [])

  const loadCandidats = (annonceId) => {
    axios
      .get(`annonces/candidats/${annonceId}`)
      .then((res) => {
        console.log(res)
        if (res.data.length) {
          setCandidates(res.data)
          setShowCandidats(annonceId)
        }
      })
      .catch((e) => console.log(e))
  }

  const handleDownloadPdf = () => {
    // Generate a temporary anchor element and set the PDF file as its href
    const link = document.createElement('a')
    link.href = `data:application/pdf;base64,${cvData}`
    link.download = `${selectedUser.firstname}-${selectedUser.lastname}.pdf`

    // Simulate a click event to trigger the download
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1)
    setNumPages(newArray)
  }

  const activate = (annonceId, isActive, idx) => {
    axios
      .post(`annonces/activate/${annonceId}`, { status: isActive })
      .then((res) => {
        console.log(res.data)
        const newAnnonces = [...annonces]
        newAnnonces[idx] = res.data
        setAnnonces(newAnnonces)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadAnnonce = () => {
    axios
      .get('annonces')
      .then((res) => {
        setAnnonces(res.data)
        console.log(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const showCv = (candidate) => {
    const candidateId = candidate._id
    console.log(candidate._id)
    axios
      .get(`cv/${candidateId}`)
      .then((res) => {
        console.log(res)
        setCvData(res.data.data)
        setSelectedUser(candidate)
        setIsModalCV(true)
      })
      .catch((e) => console.log(e))
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  return (
    <>
      {isModalCV && (
        <div className="flex items-stretch absolute top-0 min-h-screen  left-0 right-0 z-30 ">
          <div className="grow flex flex-col  items-center justify-center py-6 bg-black bg-opacity-70 min-h-screen">
            <div className="flex items-center my-3 justify-center space-x-4">
              <p
                className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
                onClick={handleDownloadPdf}
              >
                Telechargez le cv
              </p>
              <p
                className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
                onClick={() => setIsModalCV(false)}
              >
                Fermer
              </p>
            </div>
            <Document
              file={`data:application/pdf;base64,${cvData}`}
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              {numPages.map((x, idx) => {
                return <Page scale={5 / 3} key={idx} pageNumber={idx + 1} />
              })}
            </Document>
          </div>
        </div>
      )}

      <div className="mt-10 mb-6 flex py-2 border border-gray-300 px-2 bg-[#F7F9FC] space-x-2 rounded-md items-center justify-between">
        <div
          onClick={() => setArchive(false)}
          className={`${
            !archive ? 'bg-white border border-gray-200' : ''
          } py-4  w-1/2 hover:cursor-pointer rounded-xl`}
        >
          <p className="text-center font-bold">Annonces actives</p>
        </div>

        <div
          onClick={() => setArchive(true)}
          className={`${
            archive ? 'bg-white border border-gray-200' : ''
          } py-4 w-1/2 hover:cursor-pointer  rounded-xl`}
        >
          <p className="text-center font-bold">Annonces archivées</p>
        </div>
      </div>

      {!archive && (
        <div>
          <div className="flex text-4xl font-semibold space-x-4 mb-8 items-center mx-10">
            <p>{annonces.length}</p>
            <p className="text-2xl">
              annonces en cours de diffusion sur l'application
            </p>
          </div>

          <div className="flex flex-col space-y-2 ">
            {annonces.map((ann, idx) => {
              return (
                <div
                  key={idx}
                  className="bg-white rounded-xl shadow-lg mx-10 p-3"
                >
                  <div className="flex items-center space-x-2">
                    <p className="text-3xl font-semibold mb-6">{ann.title}</p>
                    {ann.town !== 'Not Found' && (
                      <p className="text-xl italic mb-6">{ann.town}</p>
                    )}
                  </div>
                  <p className="flex items-center space-x-2 pb-4 border-b border-b-gray-300">
                    {ann.isActive && (
                      <span
                        onClick={() => activate(ann._id, false, idx)}
                        className="bg-green-500 hover:cursor-pointer text-white rounded-md px-2 py-1"
                      >
                        Diffusé sur l'application
                      </span>
                    )}
                    {!ann.isActive && (
                      <span
                        onClick={() => activate(ann._id, true, idx)}
                        className="bg-red-500 hover:cursor-pointer text-white rounded-md px-2 py-1"
                      >
                        {' '}
                        Non Diffusé sur l'application
                      </span>
                    )}
                    <span className="bg-black hover:cursor-pointer text-white rounded-md px-2 py-1">
                      {' '}
                      Archiver
                    </span>
                  </p>
                  <p
                    onClick={() => loadCandidats(ann._id)}
                    className="px-3 py-1 text-gray-500 hover:cursor-pointer rounded-lg mt-6 w-fit bg-blue-200"
                  >
                    {ann.candidats?.length || 0} candidature(s)
                  </p>
                  <div className="flex my-8 items-center space-x-1">
                    <p className="border text-gray-500 border-gray-200 pb-12 pt-2 rounded-lg w-[11%] text-center">
                      Présélection
                    </p>
                    <p className="border text-gray-500 border-gray-200 pb-12 pt-2 rounded-lg w-[11%] text-center">
                      Nouveau
                    </p>
                    <p className="border text-gray-500 border-gray-200 pb-12 pt-2 rounded-lg w-[11%] text-center">
                      En attente
                    </p>
                    <p className="border text-gray-500 border-gray-200 pb-12 pt-2 rounded-lg w-[11%] text-center">
                      Selectionné
                    </p>
                    <p className="border text-gray-500 border-gray-200 pb-12 pt-2 rounded-lg w-[11%] text-center">
                      Entretien tel
                    </p>
                    <p className="border text-gray-500 border-gray-200 pb-12 pt-2 rounded-lg w-[11%] text-center">
                      RDV Physique
                    </p>
                    <p className="border text-gray-500 border-gray-200 pb-12 pt-2 rounded-lg w-[11%] text-center">
                      Proposé au client
                    </p>
                    <p className="border text-gray-500 border-gray-200 pb-12 pt-2 rounded-lg w-[11%] text-center">
                      Recruté
                    </p>
                    <p className="border text-gray-500 border-gray-200 pb-12 pt-2 rounded-lg w-[11%] text-center">
                      Refusé
                    </p>
                  </div>
                  {showCandidats === ann._id && (
                    <div className="mt-8 mx-4">
                      {candidates.map((candidate, idx) => {
                        return (
                          <div
                            className="border border-gray-300 rounded-md"
                            key={idx}
                          >
                            <div className="flex items-center space-x-2 border-b p-3 border-b-gray-300 pb-2">
                              <p>
                                {' '}
                                <img
                                  src={candidate.candidate.image || avatar}
                                  className="w-8 h-8 rounded-full"
                                />
                              </p>
                              <p className="capitalize text-xl flex items-center space-x-6">
                                <span>
                                  {candidate.candidate.firstname}{' '}
                                  {candidate.candidate.lastname}
                                </span>
                                <span className="text-xs">
                                  Postulé le {formatJsDate(candidate.date)}
                                </span>
                              </p>
                            </div>
                            <p
                              onClick={() => showCv(candidate.candidate)}
                              className="border mx-3 hover:cursor-pointer my-4 w-16 text-center border-purple-400 rounded-full  px-2 py-1 text-purple-400 "
                            >
                              CV
                            </p>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default Annonce

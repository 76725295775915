import { Checkbox, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useEffect, useState } from 'react'

const CreateContractReference = ({ contract, setContract }) => {
  const [variable, setVariable] = useState(false)
  const [dates, setDates] = useState([])

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const getDatesBetween = (startDate, endDate) => {
    const dates = []
    let currentDate = new Date(startDate)

    while (currentDate <= new Date(endDate)) {
      dates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1) // Ajoute un jour
    }

    return dates
  }

  useEffect(() => {
    let dates = getDatesBetween(contract.startDate, contract.endDate)
    console.log(dates)
    setDates(dates)
  }, [])

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <p className="font-semibold text-center text-3xl">
          Reference / horaires particuliers
        </p>

        <div className="flex flex-col mt-4 space-y-2">
          <p>Reference de la commande 1</p>
          <Input
            onChange={(e) =>
              setContract({ ...contract, Reference1: e.target.value })
            }
            value={contract.Reference1 ? contract.Reference1 : null}
            placeholder="Reference"
          />

          <p>Reference de la commande 2</p>
          <Input
            onChange={(e) =>
              setContract({ ...contract, Reference2: e.target.value })
            }
            value={contract.Reference2 ? contract.Reference2 : null}
            placeholder="Reference"
          />

          <p>Horaires particuliers</p>
          <Input
            onChange={(e) =>
              setContract({ ...contract, Horaires: e.target.value })
            }
            value={contract.Horaires ? contract.Horaires : null}
            placeholder="Horaires particuliers"
          />

          <p>Périodes non travaillées</p>
          <Input
            onChange={(e) =>
              setContract({ ...contract, PeriodeNonTrav: e.target.value })
            }
            value={contract.PeriodeNonTrav ? contract.PeriodeNonTrav : null}
            placeholder="Périodes non travaillées"
          />
        </div>
      </div>
      <div className="mt-4">
        <p className="mb-4 text-lg">Texte Facturation</p>
        <TextArea
          onChange={(e) =>
            setContract({ ...contract, TxtConsigneFacturation: e.target.value })
          }
          placeholder="Texte facturation"
          className="rounded-md border border-gray-300"
        />
      </div>

      <div className="flex items-center my-2 space-x-4">
        <p className="font-semibold">Horaires variables ?</p>
        <Checkbox onChange={(e) => setVariable(e.target.checked)} />
      </div>

      {variable && (
        <div className="overflow-scroll h-56">
          {dates.map((date, idx) => {
            return <p key={idx}>{formatJsDate(date)}</p>
          })}
        </div>
      )}
    </div>
  )
}

export default CreateContractReference

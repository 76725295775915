import { AddCircleOutline, Quickreply } from '@mui/icons-material'
import { SearchBar } from '../utils/SearchBar'
import DownloadingIcon from '@mui/icons-material/Downloading'
import SearchIcon from '@mui/icons-material/Search'
import CheckIcon from '@mui/icons-material/Check'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import cgpasnieres from '../../assets/img/cgpasnieres.png'
import cgpbrive from '../../assets/img/cgpbrive.png'
import cgptoulouse from '../../assets/img/cgptoulouse.png'
import propositionJminterim from '../../assets/img/proposition-jminterim.jpg'
import propositionCapeneo from '../../assets/img/proposition-capaneo.jpg'
import { Button, Input, Modal, Select, TreeSelect, message } from 'antd'
import { useEffect, useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import qualifications from '../../qualifications/qualifications'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import jsPDF from 'jspdf'
import myImage from '../../assets/img/logopdf.png'
import logotoulouse from '../../assets/img/logotoulouse.png'
import logobrive from '../../assets/img/logobrive.png'
import gmail from '../../assets/img/gmail.png'
import phone from '../../assets/img/phone.png'
import { Document, Page } from 'react-pdf'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import html2canvas from 'html2canvas'
export const Quotation = () => {
  const [quotations, setQuotations] = useState([])
  const [quotation, setQuotation] = useState({})
  const [clients, setClients] = useState([])
  const [step, setStep] = useState('1')
  const [isDelegation, setIsDelegation] = useState(true)
  const [isGestion, setIsGestion] = useState(false)
  const [isPlacement, setIsPlacement] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedPoste, setSelectedPoste] = useState('')
  const [selectedTauxHoraire, setSelectedTauxHoraire] = useState('')
  const [selectedCoeff, setSelectedCoeff] = useState('')
  const [coefficientPrime, setcoefficientPrime] = useState('')
  const [tauxHorairePrime, setTauxHorairePrime] = useState('')
  const [minimumHours, setMinimumHours] = useState('')
  const [selectedComment, setSelectedComment] = useState('')
  const [clientAddressesArray, setClientAdressesArray] = useState([])
  const [clientContactsArray, setClientContactsArray] = useState([])
  const [visualizeQuotation, setVisualizeQuotation] = useState('')
  const [isVisualizeQuotation, setIsVisualizeQuotation] = useState(false)
  const [originalQuotations, setOriginalQuotations] = useState([])
  const [prime, setPrime] = useState('')
  const [primeDetail, setPrimeDetail] = useState('')
  const [primeComment, setPrimeComment] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)

  const products = ['Délégation', 'Gestion', 'Placement', 'Vacation']

  const search = (word) => {
    const newQuotations = filter(originalQuotations, word)
    setQuotations(newQuotations)
  }

  const filter = (array, word) => {
    const excludedFields = ['_id', 'createdAt']
    word = word.toLowerCase()

    const filterObject = (obj) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && !excludedFields.includes(key)) {
          const value = obj[key]

          if (typeof value === 'string' && value.toLowerCase().includes(word)) {
            return true
          }

          if (
            Array.isArray(value) &&
            value.join('').toLowerCase().includes(word)
          ) {
            return true
          }

          if (typeof value === 'object' && value !== null) {
            if (filterObject(value)) {
              return true
            }
          }
        }
      }
      return false
    }

    return array.filter((obj) => filterObject(obj))
  }

  useEffect(() => {
    loadQuotations()
  }, [])

  const loadQuotations = () => {
    axiosInstance
      .get('quotation')
      .then((res) => {
        console.log(res.data)
        const quotations = res.data.sort(
          (a, b) => b.quotationNumber - a.quotationNumber,
        )
        setQuotations(quotations)
        setOriginalQuotations(quotations)
      })
      .catch((e) => {
        message.error('erreur')
      })
  }

  function splitStringWithoutCuttingWords(inputString, chunkSize) {
    const words = inputString.split(' ')
    const resultChunks = []
    let currentChunk = ''

    for (const word of words) {
      if (currentChunk.length + word.length <= chunkSize) {
        currentChunk += (currentChunk ? ' ' : '') + word
      } else {
        resultChunks.push(currentChunk)
        currentChunk = word
      }
    }

    if (currentChunk) {
      resultChunks.push(currentChunk)
    }

    return resultChunks
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const formatJsDate2 = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${month}${year}`
  }

  const modifyProduct = (product, idx) => {
    setSelectedCoeff(product.coefficient)
    setSelectedTauxHoraire(product.taux)
    setSelectedPoste(product.poste)
    setSelectedProduct(product.product)
    setMinimumHours(product.minimumHours)
    setSelectedComment(product.comment)
    deleteProduct(idx)
  }

  const modifyQuotation = (quotation) => {
    setIsUpdate(true)
    setQuotation(quotation)
    const client = quotation.client

    const clientAdresses = [
      ` ${client.address} ${client.postCode} ${client.town} `.toUpperCase(),
      `Adresse de livraison : ${client.addressLivraison} ${client.postCodeLivraison} ${client.townLivraison} `.toUpperCase(),
      `Adresse de facturation : ${client.addressFacturation} ${client.postCodeFacturation} ${client.townFacturation} `.toUpperCase(),
    ]

    const clientContactsArray = quotation.client.contacts.map(
      (contact, idx) => {
        return {
          value: `${contact.firstname || ''} ${contact.lastname || ''} ${
            contact.email || ''
          } ${contact.phone || ''}`,
          label: `${contact.firstname || ''} ${contact.lastname || ''} ${
            contact.email || ''
          } ${contact.phone || ''}`,
          key: idx,
        }
      },
    )

    const clientAddressesArray = clientAdresses.map((address, idx) => {
      return {
        value: address,
        label: address,
        key: idx,
      }
    })
    setClientAdressesArray(clientAddressesArray)
    setClientContactsArray(clientContactsArray)

    setStep('5')
    setOpenModal(true)
  }

  const generatePDF = (quotation) => {
    console.log(quotation)

    const pdf = new jsPDF({
      unit: 'mm',
      format: [210, 297],
    })

    if (quotation.agency.name === "J'M INTERIM")
      pdf.addImage(propositionJminterim, 'PNG', 0, 0, 210, 297)
    else pdf.addImage(propositionCapeneo, 'PNG', 0, 0, 210, 297)

    pdf.addPage()

    const img = new Image()
    if (quotation.agency.name === 'CAPENEO') {
      img.src = logotoulouse
      pdf.addImage(img, 'PNG', 14, 10, 40, 24)
    } else if (quotation.agency.name === "J'M CAPENEO") {
      img.src = logobrive
      pdf.addImage(img, 'PNG', 24, 14, 32, 24)
    } else {
      img.src = myImage
      pdf.addImage(img, 'PNG', 14, 10, 40, 24)
    }

    const gmailImg = new Image()
    gmailImg.src = gmail

    const phoneImg = new Image()
    phoneImg.src = phone

    pdf.setFontSize(14)
    pdf.setFont('Helvetica', 'Bold')
    pdf.text(quotation.agency.name, 24, 45)
    pdf.setFontSize(9)
    pdf.setFont('Helvetica', 'normal')
    pdf.text(quotation.agency.address, 15, 50)
    pdf.text(quotation.agency.postCode + ' ' + quotation.agency.town, 15, 55)
    pdf.text('SIRET  : ' + quotation.agency.siret, 15, 60)
    pdf.text('TVA n° : ' + quotation.agency.tva, 15, 65)
    pdf.addImage(gmailImg, 'PNG', 15, 67, 3, 3)

    pdf.text(' : ' + quotation.agency.email, 18, 69.5)
    pdf.addImage(phoneImg, 'PNG', 15, 71.5, 3, 3)
    pdf.text(' : ' + quotation.agency.phone, 18, 74)
    const contactName =
      'Contact : ' +
      quotation.creator.firstname.charAt(0).toUpperCase() +
      quotation.creator.firstname.slice(1) +
      ' ' +
      quotation.creator.lastname.charAt(0).toUpperCase() +
      quotation.creator.lastname.slice(1)
    pdf.text(contactName, 15, 79.5)

    pdf.setFontSize(14)
    pdf.setFont('Helvetica', 'Bold')
    let quotationNumber =
      'Devis n° ' +
      formatJsDate2(quotation.createdAt) +
      '-' +
      quotation.quotationNumber?.toString().padStart(5, 0)
    if (quotation.isDraft) quotationNumber = 'Devis provisoire'
    pdf.text(quotationNumber, 110, 45)

    pdf.setFontSize(9)
    pdf.setFont('Helvetica', 'normal')

    pdf.text('Date : ', 110, 50)
    pdf.text(formatJsDate(quotation.createdAt), 120, 50)

    pdf.text("Date d'expiration :", 110, 55)
    pdf.text(formatJsDate(quotation.expiryDate), 137, 55)

    pdf.setFontSize(10)
    pdf.setFont('Helvetica', 'Bold')
    pdf.text('Adresse de facturation :', 15, 95)

    pdf.setFontSize(9)
    pdf.setFont('Helvetica', 'normal')
    var [partieAvant, codePostal, partieApres] = quotation.addressFacturation
      .trim()
      .match(/^(.*?)\b(\d{5})\b(.*)$/)
      .slice(1)
      .map((str) => str.trim())
    try {
      pdf.text(partieAvant, 15, 100)
      pdf.text(codePostal + ' ' + partieApres, 15, 105)
    } catch (e) {
      console.log(e)
    }

    pdf.setFontSize(12)
    pdf.setFont('Helvetica', 'Bold')

    const companyNameArray = splitStringWithoutCuttingWords(
      quotation.client.companyName.toUpperCase(),
      30,
    )
    let size = 80 - (companyNameArray.length - 1) * 5
    for (let name of companyNameArray) {
      pdf.text(name, 110, size)
      size += 5
    }

    pdf.setFontSize(9)
    pdf.setFont('Helvetica', 'normal')
    console.log(quotation.contact.split(' '))

    let contact = quotation.contact.split(' ')
    contact.pop()
    contact.pop()
    contact = contact.join(' ').trim()

    pdf.text(`${contact.toUpperCase()}`, 110.5, 85)
    pdf.text(partieAvant, 110.5, 90)
    pdf.text(codePostal + ' ' + partieApres, 110.5, 95)
    pdf.text('SIRET : ' + quotation.client.siret, 110.5, 100)
    pdf.text('TVA : ' + quotation.client.TVA, 110.5, 105)

    pdf.setFontSize(12)
    pdf.setFont('Helvetica', 'bold')
    pdf.setTextColor(9, 132, 227)
    pdf.setDrawColor(9, 132, 227)

    if (
      quotation.products[0].product === 'Placement' ||
      quotation.products[0].product === 'Vacation'
    ) {
      pdf.roundedRect(26, 115, 160, 30, 1, 1)
      pdf.text(quotation.products[0].product.toUpperCase(), 95, 120)
      pdf.line(95, 122, 122, 122)

      pdf.setFontSize(9)
      pdf.setFont('Helvetica', 'normal')
      pdf.setTextColor(0, 0, 0)

      pdf.text(
        'Vous nous mandatez pour recruter au sein de votre entreprise, un poste en CDD ou CDI.',
        46,
        130,
      )
      pdf.text(
        "Le contrat, la DPAE et la fiche de paie sont établis par vos soins. Vous êtes l'employeur.",
        46,
        135,
      )
      pdf.text('Vous recevrez une seule facture pour le placement', 57, 140)
    }

    if (
      quotation.products[0].product === 'Délégation' ||
      quotation.products[0].product === 'Gestion'
    ) {
      pdf.roundedRect(15, 110, 92, 35, 1, 1)
      pdf.roundedRect(110, 110, 92, 35, 1, 1)
      pdf.text('DELEGATION', 47, 115)
      pdf.line(47, 117, 75, 117)
      pdf.text('GESTION', 145, 115)
      pdf.line(145, 117, 164, 117)

      pdf.setFontSize(9)
      pdf.setFont('Helvetica', 'normal')
      pdf.setTextColor(0, 0, 0)

      pdf.text('Vous nous mandatez pour', 17, 125)
      pdf.setFont('Helvetica', 'bold')
      pdf.text('recruter', 55, 125)
      pdf.setFont('Helvetica', 'normal')
      pdf.text('   et', 65, 125)
      pdf.setFont('Helvetica', 'bold')
      pdf.text('  gérer', 70, 125)
      pdf.setFont('Helvetica', 'normal')
      pdf.text(" l'administratif", 80, 125)
      pdf.text('de ', 17, 130)
      pdf.setFont('Helvetica', 'bold')
      pdf.text('notre', 21, 130)
      pdf.setFont('Helvetica', 'normal')
      pdf.text('recrue. Le contrat, la DPAE et la fiche de paie sont', 30, 130)
      pdf.text(
        "établis par nous, qui sommes l'employeur. Vous avez à charge",
        17,
        135,
      )
      pdf.text(
        'la sécurité du salarié. Vous recevrez une facture mensuelle.',
        17,
        140,
      )

      pdf.text('Vous nous mandatez pour', 112, 125)
      pdf.setFont('Helvetica', 'bold')
      pdf.text('gérer', 150, 125)
      pdf.setFont('Helvetica', 'normal')
      pdf.text("simplement l'administratif", 159, 125)
      pdf.text('de', 112, 130)
      pdf.setFont('Helvetica', 'bold')
      pdf.text('votre', 116, 130)
      pdf.setFont('Helvetica', 'normal')
      pdf.text('recrue. Le contrat, la DPAE et la fiche de paie sont', 125, 130)
      pdf.text(
        "établis par nous, qui sommes l'employeur. Vous avez à charge",
        112,
        135,
      )
      pdf.text(
        'la sécurité du salarié. Vous recevrez une facture mensuelle.',
        112,
        140,
      )
    }

    pdf.setTextColor(0, 0, 0)

    pdf.setFillColor(9, 132, 227)
    pdf.setDrawColor(9, 132, 227)
    pdf.rect(15, 155, 180, 10, 'F')

    pdf.setTextColor(255, 255, 255)
    pdf.setFontSize(10)
    pdf.setFont('Helvetica', 'bold')

    if (
      quotation.products[0].product === 'Délégation' ||
      quotation.products[0].product === 'Gestion'
    ) {
      let height = 170

      pdf.text('Designation', 25, 161)
      pdf.text('Taux horaire', 60, 161)
      pdf.text('Coefficient', 95, 161)
      pdf.text('Prix HT/heure', 130, 159)
      pdf.text('travaillée', 135, 163)
      pdf.text('Observation', 165, 161)

      pdf.setFontSize(10)
      pdf.setFont('Helvetica', 'normal')
      pdf.setTextColor(0, 0, 0)
      pdf.setDrawColor(0, 0, 0)

      quotation.products.map((product, idx) => {
        if (product.type === 'Prime') {
          const prime = splitStringWithoutCuttingWords(
            'Prime : ' + product.product,
            24,
          )
          for (let i = 0; i < prime.length; i++) {
            pdf.text(prime[i], 20, height)
            height += 5
          }
          height -= 5
        } else if (product.type === 'Indemnité') {
          const indemnite = splitStringWithoutCuttingWords(
            'Indemnité : ' + product.product,
            24,
          )
          for (let i = 0; i < indemnite.length; i++) {
            pdf.text(indemnite[i], 20, height)
            height += 5
          }
          height -= 5
        } else if (product.type === 'Autre')
          pdf.text(product.product, 20, height)
        else {
          pdf.text(product.product + ' H/F', 20, height)
          const poste = splitStringWithoutCuttingWords(product.poste, 24)
          for (let i = 0; i < poste.length; i++) {
            pdf.text(poste[i], 20, height + 5)
            height += 5
          }
        }
        if (product.taux !== '')
          pdf.text(parseFloat(product.taux).toFixed(2) + '€', 65, height)
        if (product.coefficient !== '')
          pdf.text(product.coefficient, 105, height)
        if (product.taux !== '' && product.coefficient !== '')
          pdf.text(
            (
              parseFloat(product.taux) * parseFloat(product.coefficient)
            ).toFixed(2) + '€',
            140,
            height,
          )
        const comment = splitStringWithoutCuttingWords(product.comment, 24)
        for (let i = 0; i < comment.length; i++) {
          pdf.text(comment[i], 165, height)
          height += 5
        }
        height += 5
        pdf.setTextColor(128, 128, 128)
        pdf.setDrawColor(128, 128, 128)
        if (product.minimumHours) {
          pdf.text(
            "Nombre d'heures minimales avant embauche sans facturation : " +
              product.minimumHours +
              'h',
            50,
            height,
          )
          height += 3
        }

        if (idx === quotation.products.length - 1) {
          pdf.setDrawColor(0, 0, 0)
          pdf.setLineWidth(0.5)
        }
        pdf.line(15, height, 195, height)
        pdf.setTextColor(0, 0, 0)
        pdf.setDrawColor(0, 0, 0)
        height += 7
        if (height > 260) {
          pdf.addPage()
          height = 10
        }
      })

      height += 10
      if (height > 250) {
        pdf.addPage()
        height = 10
      }

      pdf.setFontSize(9)
      pdf.setFont('Helvetica', 'normal')

      pdf.text('Nom et qualité du signataire :', 15, height)
      pdf.text('Date : ' + formatJsDate(new Date()), 15, height + 5)

      pdf.text(quotation.client.tvaRate, 120, height)
      pdf.text(
        'Conditions de paiement : ' + quotation.client.facturation,
        120,
        height + 5,
      )
      pdf.text('Moyen de règlement : Virement/Chèque/CB', 120, height + 10)
    }

    if (
      quotation.products[0].product === 'Placement' ||
      quotation.products[0].product === 'Vacation'
    ) {
      let height = 170

      pdf.text('Designation', 25, 161)
      pdf.text('Quantité', 68, 161)
      pdf.text('PU (HT)', 110, 161)
      pdf.text('Montant (HT)', 160, 161)

      pdf.setFontSize(10)
      pdf.setFont('Helvetica', 'normal')
      pdf.setTextColor(0, 0, 0)
      pdf.setDrawColor(0, 0, 0)

      quotation.products.map((product, idx) => {
        pdf.text(product.product + ' H/F', 20, height)
        pdf.text(product.poste, 20, height + 5)
        height += 5
        console.log(product.taux)
        console.log(product.coefficient)
        if (product.taux !== '')
          pdf.text(parseFloat(product.taux).toFixed(2) + '€', 110, height)
        if (product.coefficient !== '')
          pdf.text(product.coefficient, 75, height)
        if (product.taux !== '' && product.coefficient !== '')
          pdf.text(
            (
              parseFloat(product.taux) * parseFloat(product.coefficient)
            ).toFixed(2) + '€',
            175,
            height,
          )
        height += 5
        pdf.setTextColor(128, 128, 128)
        pdf.setDrawColor(128, 128, 128)

        if (idx === quotation.products.length - 1) {
          pdf.setDrawColor(0, 0, 0)
          pdf.setLineWidth(0.5)
        }
        pdf.line(15, height, 195, height)
        pdf.setTextColor(0, 0, 0)
        pdf.setDrawColor(0, 0, 0)
        height += 7
        if (height > 260) {
          pdf.addPage()
          height = 10
        }
      })

      if (height > 250) {
        pdf.addPage()
        height = 10
      }

      pdf.setFillColor(135, 177, 255)
      pdf.setDrawColor(9, 132, 227)
      height += 6
      pdf.setTextColor(255, 255, 255)
      pdf.setFont('Helvetica', 'Bold')

      pdf.setTextColor(0, 0, 0)
      pdf.setFont('Helvetica', 'normal')

      pdf.text('Total HT', 110, height)
      pdf.text(
        quotation.products
          .reduce(
            (total, item) => total + parseFloat(item.taux) * item.coefficient,
            0,
          )
          .toFixed(2) + ' €',
        174,
        height,
      )

      height += 10

      pdf.text(
        (
          quotation.products.reduce(
            (total, item) => total + parseFloat(item.taux) * item.coefficient,
            0,
          ) * 0.2
        ).toFixed(2) + ' €',
        174,
        height,
      )
      height += 10
      pdf.setFillColor(9, 132, 227)
      pdf.rect(105, height - 7, 90, 10, 'F')
      pdf.setTextColor(255, 255, 255)
      pdf.text('Total TTC', 110, height)
      pdf.text(
        (
          parseFloat(
            quotation.products.reduce(
              (total, item) => total + parseFloat(item.taux) * item.coefficient,
              0,
            ) * 0.2,
          ) +
          parseFloat(
            quotation.products.reduce(
              (total, item) => total + parseFloat(item.taux) * item.coefficient,
              0,
            ),
          )
        ).toFixed(2) + ' €',
        174,
        height,
      )

      pdf.setTextColor(0, 0, 0)

      height += 10

      if (height >= 270) pdf.addPage()

      pdf.setFontSize(9)
      pdf.setFont('Helvetica', 'normal')

      /* let hauteur=35
      for(let x of comment2){
        pdf.text(x,18,height)
        height+=5
      }
      pdf.text(
        'Conditions de paiement : '+quotation.products[0].comment,
        18,
        height,
      )
      pdf.text('Moyens de règlement : Virement/Chèque/CB', 18, height+5)
      pdf.text(
        'Coordonnées bancaires Asnières : FR76 1005 7190 0300 0208 2410146 - CMCIFRPP',
        18,
        height+10,
      )
      pdf.text(
        'Coordonnées bancaires Brive / Toulouse : FR76 1005 7190 0300 0207 4100 156 - CMCIFRPP',
        18,
        height+15,
      )
      pdf.text('Nom et qualité du signataire :', 18, height+20)
      pdf.text('Date : ' + formatJsDate(new Date()), 18,  height+25) */

      if (height > 280) {
        pdf.addPage()
        height = 10
      }

      const paymentCondition = splitStringWithoutCuttingWords(
        'Conditions de paiement : ' +
          quotation.products[0].comment.replace(/\n/g, ''),
        70,
      )

      pdf.text('Nom et qualité du signataire :', 15, height)
      pdf.text('Date : ' + formatJsDate(new Date()), 15, height + 5)

      pdf.text('Moyen de règlement : Virement/Chèque/CB', 90, height)
      for (let x of paymentCondition) {
        pdf.text(x, 90, height + 5)
        height += 4
        if (height >= 280) {
          pdf.addPage()
          height = 10
        }
      }
      if (quotation.products[0].product === 'Placement') {
        const replaceCondition = splitStringWithoutCuttingWords(
          'Conditions de remplacement : ' +
            quotation.products[0].comment2.replace(/\n/g, ''),
          70,
        )
        for (let x of replaceCondition) {
          pdf.text(x, 90, height + 10)
          height += 4
          if (height >= 280) {
            pdf.addPage()
            height = 10
          }
        }
      }
    }

    pdf.text(
      `J'm intérim - SAS au capital de 80 000 € - 4 Avenue Laurent Cély, Hall A, 92600 Asnières sur Seine, France`,
      25,
      280,
    )
    pdf.text(
      'SIRET : 91912551800010 - Code APE : 7820z - N° de TVA : FR52919125518',
      50,
      285,
    )

    pdf.addPage()
    if (quotation.agency.name === 'CAPENEO') {
      pdf.addImage(cgptoulouse, 'PNG', 10, 10, 198, 282)
    } else if (quotation.agency.name === "J'M CAPENEO") {
      pdf.addImage(cgpbrive, 'PNG', 10, 10, 198, 282)
    } else {
      pdf.addImage(cgpasnieres, 'PNG', 10, 10, 198, 282)
    }

    const pdfBlob = pdf.output('blob')
    const pdfUrl = URL.createObjectURL(pdfBlob)
    window.open(pdfUrl, '_blank')
  }

  const productArray = products.map((product) => {
    return {
      value: product,
      label: product,
    }
  })

  const selectProduct = (product) => {
    if (product === 'Délégation') {
      setIsDelegation(true)
      setIsGestion(false)
      setIsPlacement(false)
    }

    if (product === 'Gestion') {
      setIsDelegation(false)
      setIsGestion(true)
      setIsPlacement(false)
    }

    if (product === 'Placement') {
      setIsDelegation(false)
      setIsGestion(false)
      setIsPlacement(true)
    }

    setSelectedProduct(product)
  }

  const qualificationsArray = qualifications.map((qualification) => {
    return {
      value: qualification,
      label: qualification,
    }
  })

  const addToBasket = () => {
    const item = {
      product: selectedProduct,
      poste: selectedPoste,
      comment: selectedComment,
      coefficient: selectedCoeff,
      taux: selectedTauxHoraire,
      prime: {
        coefficientPrime,
        tauxHorairePrime,
        primeComment,
        prime,
      },
    }
    setSelectedCoeff('')
    setSelectedTauxHoraire('')
    setMinimumHours('')
    setSelectedComment('')
    setSelectedProduct(null)
    setSelectedPoste(null)
    let newQuotation
    if (quotation.products) {
      console.log(quotation.products)
      const newProducts = [...quotation.products, item]
      newQuotation = { ...quotation }
      newQuotation.products = newProducts
    } else {
      const newProducts = [item]
      newQuotation = { ...quotation }
      newQuotation.products = newProducts
    }
    newQuotation.minimumHours = minimumHours
    setQuotation(newQuotation)
  }

  const clientArray = clients.map((client) => {
    return {
      value: client._id,
      label: `${client.companyName} - ${client.address} ${client.postCode} ${client.town}`,
    }
  })

  useEffect(() => {
    axiosInstance
      .get('client')
      .then((res) => setClients(res.data))
      .catch((e) => console.log(e))
  }, [])

  const selectClient = (e) => {
    const client = clients.find((client) => client._id === e)
    const newQuotation = quotation
    newQuotation.client = client
    setQuotation(newQuotation)
    console.log(quotation)
    const clientAdresses = [
      ` ${client.address} ${client.postCode} ${client.town} `.toUpperCase(),
      `Adresse de livraison : ${client.addressLivraison} ${client.postCodeLivraison} ${client.townLivraison} `.toUpperCase(),
      `Adresse de facturation : ${client.addressFacturation} ${client.postCodeFacturation} ${client.townFacturation} `.toUpperCase(),
    ]

    const clientContactsArray = client.contacts.map((contact, idx) => {
      return {
        value: `${contact.contactFirstname} ${contact.contactLastname} ${contact.contactEMail} ${contact.contactPhone}`,
        label: `${contact.contactFirstname} ${contact.contactLastname} ${contact.contactEMail} ${contact.contactPhone}`,
        key: idx,
      }
    })

    const clientAddressesArray = clientAdresses.map((address, idx) => {
      return {
        value: address,
        label: address,
        key: idx,
      }
    })
    setClientAdressesArray(clientAddressesArray)
    setClientContactsArray(clientContactsArray)
  }

  const closeModal = () => {
    setOpenModal(false)
    setQuotation({})
    setStep('1')

    setSelectedProduct(null)
    setSelectedCoeff('')
    setSelectedComment('')
    setSelectedTauxHoraire('')
    setMinimumHours('')
    setSelectedPoste(null)
  }

  const selectStep = (step) => {
    if (step === '2') {
      if (!quotation.client) {
        message.error('Selectionnez un client')
      } else {
        setStep('2')
      }
    }
    if (step === '3') {
      if (!quotation.addressFacturation) {
        message.error('Selectionnez une adresse de facturation')
      } else {
        setStep('3')
      }
    }
    if (step === '4') {
      if (!quotation.addressLivraison) {
        message.error('Selectionnez le lieu de la mission')
      } else {
        setStep('4')
      }
    }
    if (step === '5') {
      if (!quotation.contact) {
        message.error('Selectionnez le contact')
      } else {
        setStep('5')
      }
    }
  }

  const [openModal, setOpenModal] = useState(false)

  const selectAddressFacturation = (e) => {
    const newQuotation = { ...quotation }
    newQuotation.addressFacturation = e
    setQuotation(newQuotation)
    console.log(quotation)
  }

  const selectContact = (e) => {
    const newQuotation = { ...quotation }
    newQuotation.contact = e
    setQuotation(newQuotation)
    console.log(quotation)
  }

  const selectAddressLivraison = (e) => {
    const newQuotation = { ...quotation }
    newQuotation.addressLivraison = e
    setQuotation(newQuotation)
    console.log(quotation)
  }

  const deleteProduct = (productId) => {
    let newProducts = quotation.products.filter(
      (_, index) => index !== productId,
    )
    let newQuotation = { ...quotation }
    newQuotation.products = newProducts
    setQuotation(newQuotation)
  }

  const saveDraft = () => {
    if (!quotation.products.length) {
      message.error('Ajouter au moins un produit')
      return
    }

    console.log(isUpdate)
    if (isUpdate) {
      axiosInstance
        .put(`quotation/${quotation._id}/update`, quotation)
        .then((res) => {
          console.log(res.data)
          closeModal()
        })
        .catch((e) => console.log(e))
    } else {
      axiosInstance
        .post('quotation/save', quotation)
        .then((res) => {
          console.log(res.data)
          closeModal()
        })
        .catch((e) => console.log(e))
    }
  }

  const generateQuotation = () => {
    {
      const content = document.getElementById('cisu')

      // Use html2canvas to capture the content as a canvas
      html2canvas(content).then((canvas) => {
        const pdf = new jsPDF()

        // Convert the canvas to a data URL (PNG format)
        const imageDataURL = canvas.toDataURL('image/png')

        // Create an image element and set its source to the data URL
        const img = new Image()
        img.src = imageDataURL
        img.width = 784
        img.height = 1183

        const downloadLink = document.createElement('a')

        // Set the href attribute to the data URL
        downloadLink.href = imageDataURL

        // Set the download attribute with the desired file name
        downloadLink.download = 'your_image.png' // You can change the file name here

        // Trigger a click event on the anchor element to initiate the download
        downloadLink.click()

        /*   // Calculate the height and width for the PDF page (assuming A4 size)
          const pdfWidth = 210;
          const pdfHeight = 297;

          img.width = 780;
          img.height = 780;
  
          // Add the captured canvas to the PDF
          pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
  
          // Save or display the PDF
          pdf.save("generated.pdf"); */
      })
    }
  }

  return (
    <div>
      {/* <Modal width={1000} open={openModal} onCancel={closeModal} footer={null}>
        <div>
          {step === '1' && (
            <div>
              <p className="font-semibold text-center text-3xl">
                Selectionner le client
              </p>

              <Select
                className="w-full py-6 my-2"
                showSearch
                onChange={(e) => selectClient(e)}
                optionFilterProp="children"
                placeholder="Client"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={clientArray}
                value={`${quotation.client?.companyName} - ${quotation.client?.address} ${quotation.client?.postCode} ${quotation.client?.town}`}
              />

              <div className="flex items-center justify-center ">
                <button
                  onClick={() => selectStep('2')}
                  className="bg-mountain-500 rounded-md p-4 text-white"
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '2' && (
            <div>
              <p className="font-semibold text-center text-3xl">
                Selectionner l'adresse de facturation
              </p>

              <div className="flex flex-col space-y-2 mt-10">
                <Select
                  className="w-full py-6 my-2"
                  showSearch
                  onChange={(e) => selectAddressFacturation(e)}
                  optionFilterProp="children"
                  placeholder="Adresse de facturation"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={clientAddressesArray}
                />
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => setStep('1')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('3')}
                  className="bg-mountain-500 rounded-md p-4 text-white"
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '3' && (
            <div>
              <p className="font-semibold text-center text-3xl">
                Selectionner le lieu de la mission
              </p>

              <div className="flex flex-col space-y-2 mt-10">
                <Select
                  className="w-full py-6 my-2"
                  showSearch
                  onChange={(e) => selectAddressLivraison(e)}
                  optionFilterProp="children"
                  placeholder="Adresse de livraison"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={clientAddressesArray}
                />
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => setStep('2')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('4')}
                  className="bg-mountain-500 rounded-md p-4 text-white"
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '4' && (
            <div>
              <p className="font-semibold text-center text-3xl">
                Selectionner le contact
              </p>

              <Select
                className="w-full py-6 my-2"
                showSearch
                onChange={(e) => selectContact(e)}
                optionFilterProp="children"
                placeholder="Contact"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={clientContactsArray}
              />
              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => setStep('3')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('5')}
                  className="bg-mountain-500 rounded-md p-4 text-white"
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '5' && (
            <div>
              <p className="font-semibold text-center text-3xl">
                Selectionner le service
              </p>

              <Select
                className="w-full h-10 mt-2"
                showSearch
                onChange={(e) => selectProduct(e)}
                optionFilterProp="children"
                placeholder="Service"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={productArray}
                value={selectedProduct}
              ></Select>

              {isDelegation && (
                <div>
                  <Select
                    className="w-full  h-10 my-2"
                    showSearch
                    onChange={(e) => setSelectedPoste(e)}
                    optionFilterProp="children"
                    placeholder="Poste"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={qualificationsArray}
                    value={selectedPoste}
                  ></Select>

                  <p className="text-2xl font-bold mb-4">HEURES</p>
                  <div className="flex flex-col items-start justify-center">
                    <div className="flex mb-2 items-center space-x-2 w-full">
                      <Input
                        onChange={(e) => setSelectedTauxHoraire(e.target.value)}
                        placeholder="Taux horaire"
                        className="rounded-md border h-10 w-1/3 border-gray-300"
                        value={selectedTauxHoraire}
                      />
                      <Input
                        onChange={(e) => setSelectedCoeff(e.target.value)}
                        placeholder="Coefficient"
                        className=" rounded-md border h-10 w-1/3 border-gray-300"
                        value={selectedCoeff}
                      />

                      {selectedCoeff && selectedTauxHoraire && (
                        <p className="w-1/3 border border-gray-300 bg-gray-200 flex items-center justify-center h-10 rounded-md">
                          {(
                            parseFloat(selectedCoeff.replace(',', '.')) *
                            parseFloat(selectedTauxHoraire.replace(',', '.'))
                          ).toFixed(2)}
                          &euro; HT/HEURE
                        </p>
                      )}

                      {(!selectedCoeff.length ||
                        !selectedTauxHoraire.length) && (
                        <p className="w-1/3 border border-gray-300 bg-gray-200 flex items-center justify-center h-10 rounded-md">
                          0 &euro; HT/HEURE
                        </p>
                      )}
                    </div>

                    <div className="mb-2 flex  space-x-2 mr-4 w-full items-start">
                      <Input
                        type="numeric"
                        placeholder="Nombre d'heures minimum pour une embauche sans facturation"
                        onChange={(e) => setMinimumHours(e.target.value)}
                        className="rounded-md border h-10 w-full border-gray-300"
                        value={minimumHours}
                      />
                    </div>
                    <div className="mb-10 flex  space-x-2 mr-4 w-full items-start">
                      <Input
                        placeholder="Commentaire"
                        onChange={(e) => setSelectedComment(e.target.value)}
                        className="rounded-md border h-10 w-full border-gray-300"
                        value={selectedComment}
                      />
                    </div>
                  </div>

                  <p className="text-2xl font-bold mb-2">PRIMES/INDEMNITES</p>
                  <p className="text-sm mb-2 italic">
                    Les indemnités ne sont pas assujetties aux charges sociales
                    alors que les primes le sont.
                  </p>

                  <div className="flex space-x-2 mb-2">
                    <Select
                      className="w-1/2  h-8"
                      showSearch
                      optionFilterProp="children"
                      onChange={(e) => setPrime(e)}
                      placeholder="Prime"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        { value: 'Prime', label: 'Prime', key: 0 },
                        { value: 'Indemnité', label: 'Indemnité', key: 1 },
                      ]}
                    ></Select>
                    <Input
                      onChange={(e) => setPrimeDetail(e.target.value)}
                      className=" rounded-md border h-8 w-1/2 border-gray-300"
                      placeholder="Nom de la prime ou de l'indemnité"
                      value={primeDetail}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-center">
                    <div className="flex mb-2 items-center space-x-2 w-full">
                      <Input
                        onChange={(e) => setTauxHorairePrime(e.target.value)}
                        placeholder="Taux horaire ou montant"
                        className="rounded-md border h-10 w-1/3 border-gray-300"
                        value={tauxHorairePrime}
                      />
                      <Input
                        placeholder="Coefficient"
                        className=" rounded-md border h-10 w-1/3 border-gray-300"
                        onChange={(e) => setcoefficientPrime(e.target.value)}
                        value={coefficientPrime}
                      />

                      {coefficientPrime && tauxHorairePrime && (
                        <p className="w-1/3 border border-gray-300 bg-gray-200 flex items-center justify-center h-10 rounded-md">
                          {(
                            parseFloat(coefficientPrime.replace(',', '.')) *
                            parseFloat(tauxHorairePrime.replace(',', '.'))
                          ).toFixed(2)}
                          &euro; HT/HEURE
                        </p>
                      )}

                      {(!coefficientPrime.length ||
                        !tauxHorairePrime.length) && (
                        <p className="w-1/3 border border-gray-300 bg-gray-200 flex items-center justify-center h-10 rounded-md">
                          0 &euro; HT/HEURE
                        </p>
                      )}
                    </div>

                    <div className="mb-4 flex  space-x-2 mr-4 w-full items-start">
                      <Input
                        placeholder="Commentaire"
                        onChange={(e) => setPrimeComment(e.target.value)}
                        className="rounded-md border h-10 w-full border-gray-300"
                        value={primeComment}
                      />
                    </div>
                    <p
                      onClick={addToBasket}
                      className="bg-mountain-100 rounded-md w-fit mx-auto px-6 my-4 text-center h-10 flex items-center font-semibold justify-center  uppercase text-mountain-500 "
                    >
                      <ShoppingCartCheckoutIcon />
                    </p>
                  </div>
                </div>
              )}

              {isGestion && (
                <div>
                  <Select
                    className="w-full py-6 my-2"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Poste"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={qualificationsArray}
                  ></Select>

                  <Input placeholder="taux horaire" className="mb-2" />
                  <Input placeholder="coefficient" className="mb-2" />
                  <Input placeholder="Commentaire" className="mb-2" />
                </div>
              )}

              {isPlacement && (
                <div>
                  <Select
                    className="w-full py-6 my-2"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Poste"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={qualificationsArray}
                  ></Select>

                  <Input placeholder="Nombre" className="mb-2" />
                  <Input placeholder="forfait" className="mb-2" />
                  <Input placeholder="Commentaire" className="mb-2" />
                </div>
              )}

            
              <div className="p-1 flex items-center justify-between bg-blue-50 text-blue-400 mb-4">
                <p className="w-1/6 font-semibold text-center">SERVICE</p>
                <p className="w-1/6 font-semibold text-cent">POSTE H/F</p>
                <p className="w-1/6 font-semibold text-center ">TAUX HORAIRE</p>
                <p className="w-1/6 font-semibold text-center ">COEFFICIENT</p>
                <p className="w-1/6 font-semibold text-center ">TOTAL</p>{' '}
                <p className="w-1/6 font-semibold text-center">COMMENTAIRE</p>
              </div>

              {quotation.products &&
                quotation.products.map((product, idx) => {
                  return (
                    <div className="flex items-center" key={idx}>
                      <p className="w-1/6 text-center">{product.product}</p>
                      <p className="w-1/6 text-center">{product.poste}</p>
                      <p className="w-1/6 text-center">{product.taux} </p>
                      <p className="w-1/6 text-center">{product.coefficient}</p>
                      <p className="w-1/6 text-center">
                        {(
                          parseFloat(product.taux) *
                          parseFloat(product.coefficient)
                        ).toFixed(2)}
                      </p>
                      <p className="w-1/6 text-center flex items-center justify-between px-1">
                        <span>{product.comment || ''}</span>
                        <span onClick={() => deleteProduct(idx)}>
                          <DeleteForeverIcon />
                        </span>
                        <span onClick={() => modifyProduct(product, idx)}>
                          <ModeEditIcon />
                        </span>{' '}
                      </p>
                    </div>
                  )
                })}

              <div className="flex items-center justify-center space-x-6 mt-10">
                <button
                  onClick={() => setStep('4')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={saveDraft}
                  className="bg-mountain-100 rounded-md p-4 text-mountain-500"
                >
                  Enregistrer au brouillon
                </button>
                <button
                  onClick={visualize}
                  className="bg-mountain-100 rounded-md p-4 text-mountain-500"
                >
                  Visualiser
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal> */}

      {isVisualizeQuotation && (
        <Modal
          className="w-[784px]  flex justify-center"
          open={isVisualizeQuotation}
          onCancel={() => setIsVisualizeQuotation(false)}
          footer={null}
        >
          <div id="cisu">
            <div className="flex items-center mt-10">
              <div className="flex flex-col justify-center w-1/3">
                <p className="flex items-center justify-center mb-6">
                  <img src={myImage} className="w-20" />
                </p>
                <p className="text-center font-bold text-lg">J'm intérim</p>
                <p className="text-center text-xs">
                  4 Avenue Laurent Cély, Hall A
                </p>
                <p className="text-center text-xs">
                  92600 Asnières sur Seine, France
                </p>
                <p className="text-center text-xs">contact@jm-interim.com</p>
                <p className="text-center text-xs">01 80 88 21 35</p>
                <p className="text-center text-xs">SIRET : 91912551500010</p>
                <p className="text-center text-xs">N° de TVA : FR52919125518</p>
              </div>
              <div className="w-2/3 ">
                <div className="flex items-center justify-start flex-col">
                  <div className="flex flex-col items-start">
                    <p className="text-xl font-bold">Devis 0001</p>
                    <p className="text-xs">Date : </p>
                    <p className="text-xs">Date d'expiration : </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-start my-10 ">
              <div className="w-1/2">
                <p className="font-semibold text-lg">
                  Adresse de facturation :
                </p>
                <p className="text-xs">
                  {quotation.client.addressFacturation},{' '}
                  {quotation.client.postCodeFacturation}{' '}
                  {quotation.client.townFacturation}{' '}
                </p>
              </div>

              <div className="w-1/2">
                <p className="font-semibold uppercase text-lg">
                  {quotation.client.companyName}
                </p>
                <p className="text-xs">
                  {quotation.client.addressLivraison},{' '}
                  {quotation.client.postCodeLivraison}{' '}
                  {quotation.client.townLivraison}{' '}
                </p>
                <p className="text-xs">
                  Numero de TVA : {quotation.client.TVA}
                </p>
              </div>
            </div>

            <div className="flex items-stretch space-x-4 text-xs mx-10">
              <div className="border border-gray-500 rounded-lg flex flex-col p-4 ">
                <p className="text-blue-500 underline text-center mb-4 font-semibold">
                  DELEGATION
                </p>
                <p className="text-xs text-center mb-4">
                  Vous nous mandatez pour recruter et gérer l'administratif de
                  notre recrue. (Le contrat, la DPAE et la fiche de paie sont
                  établis par nous, qui sommes l'employeur. Vous avez à charge
                  la sécurité du salarié.) Vous recevrez une facture mensuelle.
                </p>
              </div>

              <div className="border border-gray-500 rounded-lg flex flex-col p-4">
                <p className="text-blue-500 underline text-center mb-4 font-semibold">
                  GESTION
                </p>
                <p className="text-xs text-center mb-4">
                  Vous nous mandatez pour gérer simplement l'administratif de
                  votre recrue. (Le contrat, la DPAE et la fiche de paie sont
                  établis par nous, qui sommes l'employeur. Vous avez à charge
                  la sécurité du salarié.) Vous recevrez une facture mensuelle.
                </p>
              </div>
            </div>

            <div className="bg-blue-500 flex items-center  mx-6   p-2 font-semibold ">
              <p className=" text-white text-center w-1/4">Désignation</p>
              <p className=" text-white text-center w-1/4">taux horaire</p>
              <p className=" text-white text-center w-1/4">Coefficient</p>
              <p className=" text-white text-center w-1/4">
                Prix HT/heure travaillée
              </p>
            </div>

            {quotation.products.map((product, idx) => {
              return (
                <div
                  key={idx}
                  className="flex flex-col items-start w-full border-b border-b-gray-500 py-3 text-xs"
                >
                  <div className="flex items-center justify-between w-full">
                    {product.type === 'Prime' && (
                      <p className="w-1/4 text-center">
                        Prime : {product.product}
                      </p>
                    )}
                    {product.type === 'service' && (
                      <p className="w-1/4 text-center">
                        Délégation : {product.poste}
                      </p>
                    )}
                    {product.type !== 'Indemnité' && (
                      <p className="w-1/4 text-center">
                        Indemnité : {product.product}
                      </p>
                    )}
                    <p className="w-1/4 text-center">
                      {parseFloat(product.taux).toFixed(2)} &euro;
                    </p>
                    <p className="w-1/4 text-center">{product.coefficient}</p>
                    <p className="w-1/4 text-center">
                      {parseFloat(product.taux * product.coefficient).toFixed(
                        2,
                      )}{' '}
                      &euro;
                    </p>
                  </div>

                  <p className="w-1/4 text-center">{product.poste}</p>
                </div>
              )
            })}

            <div className=" mt-4 text-xs mb-10">
              <div className="flex items-center ">
                <p className="w-2/3">
                  Nombre d'heures minimum avant embauche sans facturation :{' '}
                  {quotation.minimumHours}h
                </p>

                <p className="w-1/3">Nom et Prénom du signataire :</p>
              </div>

              <div className="flex items-center">
                <p className="w-2/3">TVA en vigueur : 20%</p>

                <p className="w-1/3">Qualité</p>
              </div>

              <div className="flex items-center">
                <p>Conditions de paiement : 30j fin de mois</p>

                <p className="w-1/3"></p>
              </div>

              <div className="flex items-center">
                <p className="w-2/3">Moyens de règlement : Virement bancaire</p>

                <p className="w-1/3">Date :</p>
              </div>

              <div className="flex items-center">
                <p className="w-2/3"></p>

                <p className="w-1/3">Signature du client :</p>
              </div>
            </div>
            <hr className="my-6"></hr>
            <p className="text-center text-xs">
              J'm intérim - SAS au capital de 80 000 € - 4 Avenue Laurent Cély,
              Hall A, 92600 Asnières sur Seine, France
            </p>

            <p className="text-center text-xs">
              SIRET : 91912551800010 - Code APE : 7820z - N° de TVA :
              FR52919125518
            </p>
          </div>

          <p className="flex justify-center mt-6">
            <button
              onClick={generateQuotation}
              className="rounded-md text-white bg-mountain-500 px-2 py-1"
            >
              Generer le devis
            </button>
          </p>
        </Modal>
      )}

      <div className="flex items-center justify-end mx-10">
        <SearchBar search={search} />
      </div>
      <p className="px-20 mb-10 text-2xl">Devis</p>

      <div className="flex items-center justify-between  mb-6 mx-20 text-xs">
        <p className="w-[14%] text-center">NUMERO DE DEVIS</p>
        <p className="w-[14%] text-center">SERVICE</p>
        <p className="w-[14%] text-center">CLIENT</p>
        <p className="w-[14%] text-center">POSTE</p>
        <p className="w-[14%] text-center">COEFFICIENT</p>
        <p className="w-[14%] text-center">TAUX HORAIRE</p>
        <p className="w-[14%] text-center">ACTION</p>
      </div>
      <div className="flex flex-col space-y-2">
        {quotations.map((quotation, idx) => {
          return (
            <div
              key={idx}
              className=" py-4 flex items-center drop-shadow-2xl text-sm justify-between mx-20 bg-white border border-gray-200 rounded-md p-4 hover:scale-105 hover:cursor-pointer transition-all duration-300"
            >
              <div className="w-[14%]">
                {quotation.isDraft && (
                  <p className="flex justify-center">
                    <span className="bg-gray-200 px-2 py-1 rounded-md text-center">
                      Brouillon
                    </span>
                  </p>
                )}
                {!quotation.isDraft && (
                  <p className="text-center">
                    {quotation.quotationNumber.toString().padStart(5, '0')}
                  </p>
                )}
              </div>

              <div className="w-[14%]">
                <p className="font-bold text-center">
                  {quotation.products[0]?.product}
                </p>
              </div>
              <div className="w-[14%]">
                <p className="font-bold text-center">
                  {quotation.client?.companyName}
                </p>
              </div>

              <div className="w-[14%]">
                {quotation.products.map((product) => {
                  return (
                    <>
                      <div>
                        {product.type === 'Prime' && (
                          <p className="font-bold text-center">
                            Prime : {product.product}{' '}
                          </p>
                        )}
                      </div>

                      <div>
                        {product.type !== 'Prime' && (
                          <p className="font-bold text-center">
                            {product.poste}{' '}
                          </p>
                        )}
                      </div>
                    </>
                  )
                })}
              </div>

              <div className="w-[14%]">
                {quotation.products.map((product) => {
                  return (
                    <p className="font-bold text-center">
                      {product.coefficient}
                    </p>
                  )
                })}
              </div>

              <div className="w-[14%]">
                {quotation.products.map((product) => {
                  return <p className="font-bold text-center">{product.taux}</p>
                })}
              </div>

              <div className="w-[14%] flex justify-center items-center ">
                <p className="flex items-center space-x-2">
                  <SearchIcon
                    onClick={() => generatePDF(quotation)}
                    className="hover:text-green-500"
                  />
                  <DownloadingIcon className="hover:text-green-500" />
                  <ModeEditIcon
                    onClick={() => modifyQuotation(quotation)}
                    className="hover:text-green-500"
                  />
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import RHItem from './RHItem'
import { useEffect, useState } from 'react'

const RHList = ({ currentWeekNumber, week, RHs, setShowRH }) => {
  const [daysArray, setDaysArray] = useState([])

  useEffect(() => {
    generateWeekdays(week.firstDayOfWeek)
  }, [])

  function formatDayDate(date) {
    const daysOfWeek = [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ]
    const dayOfWeek = daysOfWeek[date.getDay()] // Les jours commencent à 0 pour Dimanche
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Les mois sont 0-indexés

    return `${dayOfWeek} ${day}/${month}`
  }

  function generateWeekdays(startDate) {
    const start = new Date(startDate) // La date de début est un lundi
    let days = []

    // Ajouter chaque jour de la semaine (du lundi au dimanche)
    for (let i = 0; i < 7; i++) {
      const currentDay = new Date(start)
      currentDay.setDate(start.getDate() + i) // Ajouter les jours successifs
      days.push(formatDayDate(currentDay)) // Ajouter le jour formaté au tableau
    }

    setDaysArray(days)
    console.log(days)
  }

  console.log(week)
  return (
    <div>
      <div className="flex items-center  mx-32 ">
        <div className="w-5/12 flex items-center space-x-2">
          <ArrowBackIosIcon onClick={() => setShowRH(false)} />
          <p className="text-xl font-semibold">SEMAINE {currentWeekNumber}</p>
        </div>
      </div>

      <div className="mx-32 mt-10">
        {RHs.map((RH) => {
          return <RHItem item={RH} daysArray={daysArray} />
        })}
      </div>
    </div>
  )
}

export default RHList

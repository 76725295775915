import { Checkbox } from 'antd'
import { useEffect } from 'react'

const CreateContractPenibilite = ({ contract, setContract }) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <p className="font-semibold text-center text-3xl">
          Penibilités communiquées ?
        </p>

        <div className="flex items-center my-6 space-x-10 justify-center">
          <p className="flex items-center space-x-1">
            <Checkbox
              checked={contract.PenibiliteNC === 'N'}
              onChange={() => setContract({ ...contract, PenibiliteNC: 'N' })}
            />
            <span>OUI</span>
          </p>
          <p className="flex items-center space-x-1">
            <Checkbox
              checked={contract.PenibiliteNC === 'O'}
              onChange={() => setContract({ ...contract, PenibiliteNC: 'O' })}
            />
            <span>NON</span>
          </p>
        </div>

        <p className="font-semibold text-xl my-4">
          Souhaitez vous mettre le contrat en ligne pour le client?
        </p>
        <div className="flex items-center justify-center space-x-4">
          <div className="flex items-center space-x-2">
            <p>OUI</p>
            <Checkbox
              checked={contract.isDematClient === true}
              onChange={(e) =>
                setContract({
                  ...contract,
                  isDematClient: e.target.checked ? true : false,
                })
              }
            />
          </div>
          <div className="flex items-center space-x-2">
            <p>NON</p>
            <Checkbox
              checked={contract.isDematClient === false}
              onChange={(e) => {
                setContract({
                  ...contract,
                  isDematClient: e.target.checked ? false : true,
                })
              }}
            />
          </div>
        </div>

        <p className="font-semibold text-xl my-4">
          Souhaitez vous mettre le contrat en ligne pour l'interimaire?
        </p>
        <div className="flex items-center justify-center space-x-4">
          <div className="flex items-center space-x-2">
            <p>OUI</p>
            <Checkbox
              checked={contract.isDematUser === true}
              onChange={(e) =>
                setContract({
                  ...contract,
                  isDematUser: e.target.checked ? true : false,
                })
              }
            />
          </div>
          <div className="flex items-center space-x-2">
            <p>NON</p>
            <Checkbox
              checked={contract.isDematUser === false}
              onChange={(e) =>
                setContract({
                  ...contract,
                  isDematUser: e.target.checked ? false : true,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateContractPenibilite

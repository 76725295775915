import bonjour from '../../assets/img/bonjour.png'
import avatar2 from '../../assets/img/avatar.jpg'
import { useEffect, useState } from 'react'
import briefcase from '../../assets/img/briefcase-01.png'
import client2 from '../../assets/img/client2.png'
import candidat2 from '../../assets/img/candidat2.png'
import commandeImg from '../../assets/img/commande.png'
import annonce from '../../assets/img/annonce.png'
import facture from '../../assets/img/facture2.png'
import acompteImg from '../../assets/img/acompte.png'
import prolongationImg from '../../assets/img/prolongation.png'
import releveImg from '../../assets/img/releve.png'

import axios from '../../axios/axiosInstance'
import { Select } from 'antd'
const FreelanceDashboard = ({ username, toggle, selected, setSelected }) => {
  const [userImg, setUserImg] = useState(null)
  const [taskNumber, setTaskNumber] = useState(0)
  const [clientNumber, setClientNumber] = useState(0)
  const [candidateNumber, setCandidateNumber] = useState(0)
  const [commandeNumber, setCommandeNumber] = useState(0)
  const [annonceNumber, setAnnonceNumber] = useState(0)
  const [factureNumber, setFactureNumber] = useState(0)
  const [acompteNumber, setAcompteNumber] = useState(0)
  const [freelanceUsers,setFreelanceUsers] = useState([]);


  const loadFreelanceUsers =  () => {

    axios.get('user/freelance')
    .then((res)=> {
        console.log(res.data);
        const freelanceArray = res.data.map((fr,idx)=> {
            return {
                value:fr._id,
                label:fr.firstname.toUpperCase()+" "+ fr.lastname.toUpperCase(),label:fr.firstname.toUpperCase()+" "+ fr.lastname.toUpperCase(),
                key:idx
             }
        })
        setFreelanceUsers(freelanceArray);
    })
    .catch((e)=> {
        console.log(e);
    })

  };



  const onChangeFreelance =  (freelanceId) => {
    loadClientNumber(freelanceId);
    loadCandidateNumber(freelanceId);
  }

  const loadTasks = () => {
    const date = new Date()
    axios
      .get(`/task/find-by-date?date=${date}`)
      .then((res) => {
        const tasks = res.data.filter(
          (task) => task.status != 'annulée' && task.status != 'Effectuée',
        )
        setTaskNumber(tasks.length)
      })
      .catch((e) => console.log(e))
  }

  const loadAcompte = () => {
    axios
      .get('acompte/tovalidate-count')
      .then((res) => {
        setAcompteNumber(res.data)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
        }
      })
  }

  const loadAnnonce = () => {
    axios
      .get('annonces/count')
      .then((res) => {
        setAnnonceNumber(res.data)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
        }
      })
  }

  const loadBills = () => {
    axios
      .get('facture/unpaid-count')
      .then((res) => {
        setFactureNumber(res.data)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
        }
      })
  }

  const loadTasksNumber = (date) => {
    axios
      .get(`/task/freelance/find-by-date?date=${date}`)
      .then((res) => {
        setTaskNumber(res.data.length)
      })
      .catch((e) => console.log(e))
  }

  const loadCommandeEncours = () => {
    axios
      .get(`/order/en-cours`)
      .then((res) => {
        console.log(res.data)
        setCommandeNumber(res.data)
      })
      .catch((e) => console.log(e))
  }

  const loadCandidateNumber = (freelanceId) => {
    axios
      .get(`/tempworker-candidate/count-candidate/freelance/${freelanceId}`)
      .then((res) => {
        console.log(res.data)
        setCandidateNumber(res.data)
      })
      .catch((e) => console.log(e))
  }

  const loadClientNumber = (freelanceId) => {
    axios
      .get(`/client/count/freelance/${freelanceId}`)
      .then((res) => {
        setClientNumber(res.data)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {

    //loadClientNumber()
    loadFreelanceUsers();
    //loadCommandeEncours()
    //loadCandidateNumber()
    //loadAnnonce()
    //loadBills()
    //loadTasks()
    //loadAcompte()
  }, [])
  return (
    <div className="flex items-center  flex-col  justify-center mt-10">

        <p className='text-center text-2xl mt-8 uppercase font-semibold mb-4'>Selectionnez le freelance</p>
        <Select optionFilterProp="children"
          placeholder="Freelance"
          onChange={(e) => onChangeFreelance(e)}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          } 
          options={freelanceUsers}
          className='w-96 uppercase' />

      <div className="mx-8 mb-6 grow w-full mt-6 ">
        <div className="flex grow items-start space-x-4">
          <div
            onClick={() => {
              toggle('taches')
              setSelected('taches')
            }}
            className="w-1/3 hover:cursor-pointer  bg-white py-6 px-4 flex flex-col space-y-6 border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
              <img
                src={briefcase}
                className="w-10 rounded-md p-2 border  border-gray-300  bg-white"
              />
              <p className="text-2xl">Gestion des tâches</p>
            </div>
            <p className="text-2xl text-center font-semibold">{taskNumber}</p>
          </div>

          <div
            onClick={() => {
              toggle('order', 'enCours')
              setSelected('order')
            }}
            className="w-1/3 hover:cursor-pointer bg-white py-6 px-4 flex flex-col space-y-6  border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
              <img
                src={commandeImg}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Commandes</p>
            </div>
            <p className="text-2xl text-center font-semibold">
              {commandeNumber}
            </p>
          </div>

          <div
            onClick={() => {
              toggle('searchClient')
              setSelected('searchClient')
            }}
            className="w-1/3 hover:cursor-pointer bg-white py-6 px-4 flex flex-col space-y-6  border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
              <img
                src={client2}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Suivi Clients</p>
            </div>
            <p className="text-2xl text-center font-semibold">{clientNumber}</p>
          </div>
        </div>
      </div>

      <div className="mx-8 grow w-full ">
        <div className="flex grow items-start space-x-4">
          <div
            onClick={() => {
              toggle('searchCandidat')
              setSelected('searchCandidat')
            }}
            className="w-1/3 hover:cursor-pointer bg-white py-6 px-4  flex flex-col space-y-2 border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
              <img
                src={candidat2}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Nombre d'interimaires enregistrés</p>
            </div>
            <p className="text-2xl text-center font-semibold">
              {candidateNumber}
            </p>
          </div>

          <div
            onClick={() => {
              toggle('acompte')
              setSelected('acompte')
            }}
            className="w-1/3 hover:cursor-pointer  bg-white py-6 px-4 flex flex-col space-y-6 border border-gray-300 shadow-md h-40  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
              <img
                src={acompteImg}
                className="w-10 rounded-md p-2 border  border-gray-300  bg-white"
              />
              <p className="text-2xl">Acomptes de la semaine</p>
            </div>
            <p className="text-2xl text-center font-semibold">
              {acompteNumber}
            </p>
          </div>

         

          <div
            onClick={() => {
              toggle('facture', 'impayée')
              setSelected('facture')
            }}
            className="w-1/3 hover:cursor-pointer bg-white py-6 px-4 h-40 flex flex-col space-y-2  border border-gray-300 shadow-md  p-2 rounded-md"
          >
            <div className="flex items-center space-x-2">
              <img
                src={facture}
                className="w-10 rounded-md p-2 border border-gray-300  bg-white"
              />
              <p className="text-2xl">Factures impayées</p>
            </div>
            <p className="text-2xl text-center font-semibold">
              {factureNumber}
            </p>
          </div>
        </div>
      </div>

     
    </div>
  )
}

export default FreelanceDashboard;

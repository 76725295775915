import { Icon } from '@iconify/react'
import { useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import BlockIcon from '@mui/icons-material/Block'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import { CheckOutlined } from '@ant-design/icons'

const DispoTable = ({ user, setCandidates, setUser, candidates }) => {
  const [availability, setAvailability] = useState(user.availabilities)

  const [isMorning, setIsMorning] = useState(false)
  const [isAfternoon, setIsAfternoon] = useState(false)
  const [isEvening, setIsEvening] = useState(false)
  const [isNight, setIsNight] = useState(false)

  const [isMorningWeekEnd, setIsMorningWeekEnd] = useState(false)
  const [isAfternoonWeekEnd, setIsAfternoonWeekEnd] = useState(false)
  const [isEveningWeekEnd, setIsEveningWeekEnd] = useState(false)
  const [isNightWeekEnd, setIsNightWeekEnd] = useState(false)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi']
  const weekEnd = ['Samedi', 'Dimanche']

  const addAvailability = (av) => {
    const avIndex = availability.indexOf(av)

    console.log(av, avIndex)
    if (avIndex !== -1) {
      availability.splice(avIndex, 1)
      setAvailability(() => [...availability])
    } else setAvailability(() => [...availability, av])
  }

  const updateStatus = (status) => {
    axiosInstance
      .post(`tempworker-candidate/${user._id}/update-status`, {
        status,
      })
      .then((res) => {
        const newCandidate = res.data
        const candidateIndex = candidates.findIndex(
          (obj) => obj._id === newCandidate._id,
        )
        candidates[candidateIndex] = newCandidate
        candidates.sort((a, b) => a.lastname.localeCompare(b.lastname))
        setCandidates([...candidates])
        message.success('Modifications enregistrées!')
        setUser(newCandidate)
      })
      .catch((e) => console.log(e))
  }

  const addMornings = (dayList) => {
    const newAvailabilty = [...availability]
    console.log(dayList)
    if (dayList.length === 5) {
      if (!isMorning) {
        for (let day of dayList) {
          if (newAvailabilty.indexOf(day + ' : matin') === -1)
            newAvailabilty.push(day + ' : matin')
        }
      } else {
        for (let day of dayList) {
          const avIndex = newAvailabilty.indexOf(day + ' : matin')
          if (avIndex !== -1) newAvailabilty.splice(avIndex, 1)
        }
      }
      setAvailability(newAvailabilty)
      setIsMorning(!isMorning)
    } else {
      if (!isMorningWeekEnd) {
        for (let day of dayList) {
          if (newAvailabilty.indexOf(day + ' : matin') === -1)
            newAvailabilty.push(day + ' : matin')
        }
      } else {
        for (let day of dayList) {
          const avIndex = newAvailabilty.indexOf(day + ' : matin')
          if (avIndex !== -1) newAvailabilty.splice(avIndex, 1)
        }
      }
      setAvailability(newAvailabilty)
      setIsMorningWeekEnd(!isMorningWeekEnd)
    }
  }

  const addAfternoons = (dayList) => {
    const newAvailabilty = [...availability]
    if (dayList.length === 5) {
      if (!isAfternoon) {
        for (let day of dayList) {
          if (newAvailabilty.indexOf(day + ' : apres-midi') === -1)
            newAvailabilty.push(day + ' : apres-midi')
        }
      } else {
        for (let day of dayList) {
          const avIndex = newAvailabilty.indexOf(day + ' : apres-midi')
          if (avIndex !== -1) newAvailabilty.splice(avIndex, 1)
        }
      }
      setAvailability(newAvailabilty)
      setIsAfternoon(!isAfternoon)
    } else {
      if (!isAfternoonWeekEnd) {
        for (let day of dayList) {
          if (newAvailabilty.indexOf(day + ' : apres-midi') === -1)
            newAvailabilty.push(day + ' : apres-midi')
        }
      } else {
        for (let day of dayList) {
          const avIndex = newAvailabilty.indexOf(day + ' : apres-midi')
          if (avIndex !== -1) newAvailabilty.splice(avIndex, 1)
        }
      }
      setAvailability(newAvailabilty)
      setIsAfternoonWeekEnd(!isAfternoonWeekEnd)
    }
  }

  const addNights = (dayList) => {
    const newAvailabilty = [...availability]
    if (dayList.length === 5) {
      if (!isNight) {
        for (let day of dayList) {
          if (newAvailabilty.indexOf(day + ' : nuit') === -1)
            newAvailabilty.push(day + ' : nuit')
        }
      } else {
        for (let day of dayList) {
          const avIndex = newAvailabilty.indexOf(day + ' : nuit')
          if (avIndex !== -1) newAvailabilty.splice(avIndex, 1)
        }
      }
      setAvailability(newAvailabilty)
      setIsNight(!isNight)
    } else {
      if (!isNightWeekEnd) {
        for (let day of dayList) {
          if (newAvailabilty.indexOf(day + ' : nuit') === -1)
            newAvailabilty.push(day + ' : nuit')
        }
      } else {
        for (let day of dayList) {
          const avIndex = newAvailabilty.indexOf(day + ' : nuit')
          if (avIndex !== -1) newAvailabilty.splice(avIndex, 1)
        }
      }
      setAvailability(newAvailabilty)
      setIsNightWeekEnd(!isNightWeekEnd)
    }
  }

  const updateCandidate = () => {
    setIsLoading(true)
    axiosInstance
      .post(`tempworker-candidate/${user._id}/update`, {
        availabilities: availability,
      })
      .then((res) => {
        const newCandidate = res.data
        const candidateIndex = candidates.findIndex(
          (obj) => obj._id === newCandidate._id,
        )
        candidates[candidateIndex] = newCandidate
        candidates.sort((a, b) => a.lastname.localeCompare(b.lastname))
        setCandidates([...candidates])
        message.success('Modifications enregistrées!')
        setUser(newCandidate)
        setIsLoading(false)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  const addEvenings = (dayList) => {
    const newAvailabilty = [...availability]
    if (dayList.length === 5) {
      if (!isEvening) {
        for (let day of dayList) {
          if (newAvailabilty.indexOf(day + ' : soir') === -1)
            newAvailabilty.push(day + ' : soir')
        }
      } else {
        for (let day of dayList) {
          const avIndex = newAvailabilty.indexOf(day + ' : soir')
          if (avIndex !== -1) newAvailabilty.splice(avIndex, 1)
        }
      }
      setAvailability(newAvailabilty)
      setIsEvening(!isEvening)
    } else {
      if (!isEveningWeekEnd) {
        for (let day of dayList) {
          if (newAvailabilty.indexOf(day + ' : soir') === -1)
            newAvailabilty.push(day + ' : soir')
        }
      } else {
        for (let day of dayList) {
          const avIndex = newAvailabilty.indexOf(day + ' : soir')
          if (avIndex !== -1) newAvailabilty.splice(avIndex, 1)
        }
      }
      setAvailability(newAvailabilty)
      setIsEveningWeekEnd(!isEveningWeekEnd)
    }
  }

  return (
    <>
      <div className="w-full bg-green-50 flex flex-col items-center space-y-8 p-4 mb-8   rounded-lg shadow-lg">
        <div className="flex flex-col items-center space-y-4 w-full">
          <div className="flex items-center justify-between w-full xl:w-3/5">
            <p className="text-xl font-semibold text-mountain-500">Semaine</p>
            <div className="flex items-center space-x-3">
              <p
                onClick={() => addMornings(daysOfWeek)}
                className={
                  !isMorning
                    ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                    : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                }
              >
                Matin
              </p>
              <p
                onClick={() => addAfternoons(daysOfWeek)}
                className={
                  !isAfternoon
                    ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                    : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                }
              >
                Après-Midi
              </p>
              <p
                onClick={() => addEvenings(daysOfWeek)}
                className={
                  !isEvening
                    ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                    : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                }
              >
                Soir
              </p>
              <p
                onClick={() => addNights(daysOfWeek)}
                className={
                  !isNight
                    ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                    : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                }
              >
                Nuit
              </p>
            </div>
          </div>
          {daysOfWeek.map((day) => {
            return (
              <div className="flex items-center justify-between w-full xl:w-3/5">
                <p>{day}</p>
                <div className="flex items-center space-x-3">
                  <p
                    onClick={() => addAvailability(day + ' : matin')}
                    className={
                      availability.indexOf(day + ' : matin') === -1
                        ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                        : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                    }
                  >
                    Matin
                  </p>
                  <p
                    onClick={() => addAvailability(day + ' : apres-midi')}
                    className={
                      availability.indexOf(day + ' : apres-midi') === -1
                        ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                        : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                    }
                  >
                    Après-Midi
                  </p>
                  <p
                    onClick={() => addAvailability(day + ' : soir')}
                    className={
                      availability.indexOf(day + ' : soir') === -1
                        ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                        : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                    }
                  >
                    Soir
                  </p>
                  <p
                    onClick={() => addAvailability(day + ' : nuit')}
                    className={
                      availability.indexOf(day + ' : nuit') === -1
                        ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                        : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                    }
                  >
                    Nuit
                  </p>
                </div>
              </div>
            )
          })}
        </div>

        <div className="flex flex-col items-center space-y-4 w-full">
          <div className="flex items-center justify-between w-full xl:w-3/5">
            <p className="text-xl font-semibold text-mountain-500">Week-end</p>
            <div className="flex items-center space-x-3">
              <p
                onClick={() => addMornings(weekEnd)}
                className={
                  !isMorningWeekEnd
                    ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                    : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                }
              >
                Matin
              </p>
              <p
                onClick={() => addAfternoons(weekEnd)}
                className={
                  !isAfternoonWeekEnd
                    ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                    : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                }
              >
                Après-Midi
              </p>
              <p
                onClick={() => addEvenings(weekEnd)}
                className={
                  !isEveningWeekEnd
                    ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                    : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                }
              >
                Soir
              </p>
              <p
                onClick={() => addNights(weekEnd)}
                className={
                  !isNightWeekEnd
                    ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                    : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                }
              >
                Nuit
              </p>
            </div>
          </div>
          {weekEnd.map((day) => {
            return (
              <div className="flex items-center justify-between w-full xl:w-3/5">
                <p>{day}</p>
                <div className="flex items-center space-x-3">
                  <p
                    onClick={() => addAvailability(day + ' : matin')}
                    className={
                      availability.indexOf(day + ' : matin') === -1
                        ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                        : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                    }
                  >
                    Matin
                  </p>
                  <p
                    onClick={() => addAvailability(day + ' : apres-midi')}
                    className={
                      availability.indexOf(day + ' : apres-midi') === -1
                        ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                        : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                    }
                  >
                    Après-Midi
                  </p>
                  <p
                    onClick={() => addAvailability(day + ' : soir')}
                    className={
                      availability.indexOf(day + ' : soir') === -1
                        ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                        : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                    }
                  >
                    Soir
                  </p>
                  <p
                    onClick={() => addAvailability(day + ' : nuit')}
                    className={
                      availability.indexOf(day + ' : nuit') === -1
                        ? 'px-2 py-1 hover:cursor-pointer bg-gray-200 text-black rounded-md'
                        : 'px-2 py-1 hover:cursor-pointer bg-green-400 text-white rounded-md'
                    }
                  >
                    Nuit
                  </p>
                </div>
              </div>
            )
          })}
        </div>
        <div className=" col-span-2">
          <p
            className="text-right w-full flex items-center space-x-2 hover:cursor-pointer col-span-2"
            onClick={updateCandidate}
          >
            {isLoading ? (
              <span className="text-white bg-mountain-500 hover:bg-white hover:text-mountain-500 hover:border-mountain-500 border-2 border-transparent outline-0 font-medium rounded-lg text-base px-5 py-2.5 text-center inline-flex items-center mb-2 gap-2">
                <Icon
                  icon={'material-symbols:edit-square-outline'}
                  width={25}
                />
                ...chargement
              </span>
            ) : (
              <span className="text-white bg-mountain-500 hover:bg-white hover:text-mountain-500 hover:border-mountain-500 border-2 border-transparent outline-0 font-medium rounded-lg text-base px-5 py-2.5 text-center inline-flex items-center mb-2 gap-2">
                <Icon
                  icon={'material-symbols:edit-square-outline'}
                  width={25}
                />
                Enregistrer
              </span>
            )}

            <span className="text-white bg-purple-500 hover:bg-white  border-2 border-transparent outline-0 font-medium rounded-lg text-base px-5 py-2.5 text-center inline-flex items-center mb-2 gap-2">
              Demander les dispos
            </span>
          </p>
        </div>

        <div className="flex items-center justify-center space-x-2">
          <button
            onClick={() => updateStatus('Disponible')}
            className="text-green-500 p-4 rounded-md flex items-center space-x-1 my-6"
          >
            <CheckOutlined /> Disponible
          </button>
          <button
            onClick={() => updateStatus('Indisponible')}
            className="text-orange-300 p-4 rounded-md flex items-center space-x-1 my-6"
          >
            <EventBusyIcon /> Indisponible
          </button>
          <button
            onClick={() => updateStatus('Bloqué')}
            className="text-black p-4 rounded-md flex items-center space-x-1 my-6"
          >
            <BlockIcon /> Bloquer
          </button>
          <button
            onClick={() => updateStatus('Supprimé')}
            className="text-red-700 p-4 rounded-md space-x-1 flex items-center  my-6"
          >
            <DeleteForeverIcon />
            Supprimer
          </button>
        </div>
      </div>
    </>
  )
}

export default DispoTable
